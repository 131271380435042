import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import { kioskInfo } from "./kioskInfo";
import { router } from '../router/index.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        kioskInfo,
    },
    plugins: [
        createPersistedState({
            paths: ["kioskInfo"],
        }),
    ],
    state: {
        language: "",
        userType: null,
        emmaPatientInfo: null, // 응급실 문진확인증에서 동반보호자 문진시행 용
        USER: {
            PT: "PT", // 환자
            // RPT: 'RPT', // 재원환자
            NOK: "NOK", // 보호자
            // RNOK: "RNOK", // 상주보호자
            GST: "GST", // 방문자
            TMP: "TMP", // 방문자(기타방문자)
            EMP: "EMP", // 내부직원
            // PEMP: "PEMP", // 협력업체 직원
            EMMA: "EPT", // 응급환자
            ENOK: "ENOK", // 응급환자 보호자
            ICUGST: "ICUGST", // 중환자실 면회객
            WGST: "WGST", // 병동 면회객 (아직 미사용)
        },
        InqType: {
            Normal: "N", // 일반문항(기본)
            NormalImg: "I", // 일반문항(표/이미지)
            VisitHistory: "H", // 방문력
            Etc: "P", // 기타(종이문진 전용)
            MultiChoice: "M", // 객관식형
            ShortAnswer: "T", // 주관식형
            Editor: "E", // 안내문구(에디터)
            Housemate: "FS", // 동거가족 증상 (직원전용)
            HousemateRelation: "FD", // 동거가족 - 역학적 연관성 (직원전용)
            HousemateDetail: 'FT', // 동거가족 - 주관식 상세
            OverseasVisitHistory: 'OV', //해외방문력 (응급실용)
            HospitalVisitHistory: 'HV', //타원방문력 (응급실용)
        },
        inquiryYn: null,
        validInquiryCnt: 0,
    },
    getters: {
        getLanguage: (state) => {
            return state.language === "" ? "ko" : state.language;
        },
        isRTL: (state) => {
            return state.language === "ar";
        },
        getUserType: (state) => state.userType,
        getEmmaPatientInfo: (state) => state.emmaPatientInfo,
        getInquiryYn: (state) => state.inquiryYn,
        getValidInquiryCnt: (state) => state.validInquiryCnt,
    },
    mutations: {
        initUserType(state) {
            state.userType = null;
        },
        setUserType(state, payload) {
            state.userType = payload;
            state.kioskInfo.isEmmaKiosk =
                payload === state.USER.EMMA || payload === state.USER.ENOK;
        },
        setLanguage(state, payload) {
            const localeInfo = payload.locale === "ko" ? "" : payload.locale;
            document.documentElement.setAttribute("lang", localeInfo);
            state.language = payload.locale;
        },
        setEmmaPatientInfo(state, { refId, refBirth, refPhone }) {
            state.emmaPatientInfo = { refId, refBirth, refPhone };
        },
        setInquiryYn(state, payload) {
            state.inquiryYn = payload;
        },
        setValidInquiryCnt(state, payload) {
            state.validInquiryCnt = payload;
        }
    },
    actions:{
        async InquiryUseCheck ({commit, state}, payload) {
            let params = {inqType: state.userType == 'RPT'? 'ADMINER' : state.userType};

            if(state.userType === 'EPT' || state.userType === 'ENOK'){
                params = {inqType: 'ERPER'}
            } else if(state.userType == 'TMP'){
                params = {inqType: 'GST'};

            } else if(state.userType == 'PEMP') {
                params = {inqType: 'EMP'};

            } else {
                params = {inqType: state.userType}
            }

            if(state.userType == 'PT' && payload) {  
                params = {inqType: payload.inqType, ptNo: payload.ptno, visitDate: payload.vd};
            }
            

            let response = await axios.get("/api/v1/answerUseYn", {params: params});
            if(response.data.resultCd === 0){
                if(response.data.result.useYn == "Y"){
                    commit('setInquiryYn', true);
                } else if(response.data.result.useYn == "N"){
                    commit('setInquiryYn', false);
                }
            } else {
                await router.push({
                    name: "forbiden",
                    params: {
                        inqCheckErrorMsg: "유효하지_않은_접근입니다"
                    }
                });
            }

        },
        actionGoHome({commit, state}, payload) {
            // window.location.href = `${window.location.origin}/#/kiosk/${this.getKioskId}`;
            console.log(state.kioskInfo.kioskId);
            if (this.getKioskId === "preview") return;
            if (this.getIsEmmaKiosk) {
                router.push({
                    name: "emmahome",
                    params: {
                        kioskId: state.kioskInfo.kioskId,
                    },
                });
            } else {
                router.push({
                    name: "home",
                    params: {
                        kioskId: state.kioskInfo.kioskId,
                    },
                });
            }
        },
    },
});

export default store;