<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("문진확인증_작성") }}</h1>
        <h2
          v-html="
            isENOK
              ? $t('환자의_휴대전화번호_생년월일을_입력해주세요')
              : $t('휴대전화번호_생년월일을_입력해주세요')
          "
        ></h2>
      </div>
      <div id="kiosk-area" class="kiosk-area">
        <KioskInputPhoneBirth
          v-model="phoneBirth"
        />
        <!-- <Terms  v-if="!isENOK" class="terms-area" ref="terms" v-model="isTermChecked" /> -->
        <div class="btn-privacy" />
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goNext"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
    <SelectPatientPopup2
      v-if="selectStatus"
      :userList="userList"
      :byPid="false"
      @okBtn="selectPatientIF"
      @closePopup="closeErrorPop"
    />
    <SelectNewPatientPopup
      v-if="emmaSelectStatus"
      :emmaUserInfo="emmaUserInfo"
      @okBtn="goPhone"
      @closePopup="closeErrorPop"
    />
  </div>
</template>

<script>
import Header from "../../components/v2/Header";
import KioskInputPhoneBirth from "../../components/v2/insertId/KioskInputPhoneBirth.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";
import SelectPatientPopup2 from "../../components/v2/modal/SelectPatientPopup2.vue";
import SelectNewPatientPopup from "../../components/v2/modal/SelectNewPatientPopup.vue";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
// import moment from "moment";

export default {
  name: "EmmaNewPatient",
  components: {
    Header,
    KioskInputPhoneBirth,
    // Terms,
    SelectPatientPopup2,
    SelectNewPatientPopup,
  },
  data() {
    return {
      phoneBirth: {
        phoneNum: "",
        birthday: "",
      },
      isTermChecked: true,
      btnLock: false,
      selectStatus: false, // 본인 확인 팝업 노출 여부
      emmaSelectStatus: false, // 응급용 본인 확인 팝업 노출 여부
      userList: [], // 본인 확인 팝업 내 사용자 리스트
      emmaUserInfo: {
        phoneNum: "",
        birth: "",
        dateInfo: "",
      }, // 본인 확인 팝업 내 사용자 리스트
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getLanguage"]),
    isENOK() {
      return this.getUserType === this.USER.ENOK;
    },
    canNext: function () {
      // 생년월일로 인증
      return (
        // 핸드폰 번호 입력값 확인
        this.phoneBirth.phoneNum !== null &&
        this.phoneBirth.phoneNum !== "" &&
        this.phoneBirth.phoneNum.length > 9 &&
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
          this.phoneBirth.phoneNum
        ) &&
        // 생년월일 입력값 확인
        this.phoneBirth.birthday !== null &&
        this.phoneBirth.birthday !== "" &&
        this.phoneBirth.birthday.length === 6 &&
        // 약관 동의 여부 확인
        (this.isENOK || this.isTermChecked)
      );
    },
  },
  methods: {
    ...mapMutations(["setEmmaPatientInfo"]),
    ...mapActions(['InquiryUseCheck']),
    async goNext() {
      if (!this.canNext || this.btnLock) return;
      // const patientSearchResult = await this.apiIdentityPhonebirth();
      const patientSearchResult = false //test용으로 .. 
      if (this.getUserType === this.USER.EMMA) {
        if (patientSearchResult) {
          // case: EMMA, 구환
          this.showSelectPatientPopup(patientSearchResult);
        } else {
          // case: EMMA, 신환
          // 마지막페이지에서 동반보호자 문진 재작성을 위한 정보 저장
          this.setEmmaPatientInfo({
            refId: null,
            refBirth: this.phoneBirth.birthday,
            refPhone: this.phoneBirth.phoneNum,
          });
          // const { privacyCode, sensitiveCode } =
          //   this.$refs.terms.getTermsCode();
          // case: EMMA, 신환
          this.getInqType(
            {
              answerType: this.getUserType,
              identityPhone: this.phoneBirth.phoneNum,
              identityBirth: this.phoneBirth.birthday,
              newPtYn: "Y",
              privacyCode:null,
              sensitiveCode:null,
            },
            
          );
        }
      } else if (this.getUserType === this.USER.ENOK) {
        if (patientSearchResult) {
          // case: ENOK, 구환
          this.showSelectPatientPopup(patientSearchResult);
        } else {
          // case: ENOK, 신환
          this.showEmmaPatientPopup();
        }
      }
    },
    selectPatientIF(patientId) {
      if (this.getUserType === this.USER.EMMA) {
        this.goInquiry(patientId);
      } else if (this.getUserType === this.USER.ENOK) {
        this.goPhone({
          refId: patientId,
          refBirth: null,
          refPhone: null,
        });
      }
    },
    goPhone({ refId, refBirth, refPhone }) {
      this.$router.push({
        name: "emmaPhone",
        params: {
          refId,
          refBirth,
          refPhone,
        },
      });
    },
    async goInquiry(patientId) {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        // case: EMMA, 구환
        const response = await this.$axios.post("/api/v1/identity/emma", {
          answerType: this.USER.EMMA,
          identityNo: patientId,
          newPtYn: "N",
        });
        // if (!response.data.success) throw new Error("error");

        // 마지막페이지에서 동반보호자 문진 재작성을 위한 정보 저장
        this.setEmmaPatientInfo({
          refId: patientId,
          refBirth: null,
          refPhone: null,
        });

        // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
        switch (response.data.resultCd) {
          case 201:
          case 200:
            // 201: 기완료(증상있음)
            // 200: 기완료(증상없음)
            // 응급의 경우 항상 재작성이기 때문에 200/201이 오는 케이스 없어야 함
            throw new Error("응급 케이스는 기작성 Case 없음");
          case 0:
            this.$router.push({
              name: "infoAgree",
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: "N",
                identityBirth: null,
                identityPhone: null,
                refPhone: null,
                refBirth: null,
                refId: null,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 ResultCode 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async apiSearchEmmaByPhonebirth() {
      console.log();
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/emma/find-pt",
          {
            answerType: this.getUserType,
            newPtYn: "Y",
            refPhone: this.phoneBirth.phoneNum,
            refBirth: this.phoneBirth.birthday,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 0:
            // 0 : 조회 성공
            return response.data.result.map(
              ({ patientBirth, patientPhone, patientVisitTime }) => ({
                phoneNum: patientPhone,
                birth: patientBirth,
                dateInfo: patientVisitTime,
              })
            );
          case 300:
            // 300 : 조회된 환자 없음F
            return null;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
        return null;
      } finally {
        this.btnLock = false;
        this.$modal.loading(false);
      }
    },
    async apiIdentityPhonebirth() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/phone-birth",
          {
            phoneNum: this.phoneBirth.phoneNum,
            birth: this.phoneBirth.birthday,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 0:
          case 303:
            // 0 : 생년월일+전화번호로 조회된 환자 1명
            // 303 : 생년월일로 조회된 환자 여러명
            return response.data.result.map(({ patNum, ptName, birth }) => ({
              patNum,
              ptName,
              birth,
            }));
          case 300:
            // 300 : 생년월일+전화번호로 조회된 환자없음
            return null;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
        return null;
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async getInqType(param, privacyCode, sensitiveCode) {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/emma", param);
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 201:
          case 200:
            // 201: 기완료(증상있음)
            // 200: 기완료(증상없음)
            // 응급의 경우 항상 재작성이기 때문에 200/201이 오는 케이스 없어야 함
            throw new Error("응급 케이스는 기작성 Case 없음");
          case 0:
            this.$router.push({
              name: "infoAgree",
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: privacyCode,
                sensitiveCode: sensitiveCode,
                newPtYn: "Y",
                identityBirth: this.phoneBirth.birthday,
                identityPhone: this.phoneBirth.phoneNum,
                refPhone: null,
                refBirth: null,
                refId: null,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 ResultCode 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    showSelectPatientPopup(patientInfo) {
      this.userList = patientInfo;
      this.selectStatus = true;
    },
    async showEmmaPatientPopup() {
      const emmaPatientInfo = await this.apiSearchEmmaByPhonebirth();
      if (emmaPatientInfo && emmaPatientInfo.length > 0) {
        this.emmaUserInfo = emmaPatientInfo[0];
        this.emmaSelectStatus = true;
      } else {
        this.$modal.error(
          `${this.$t("환자_정보가_확인되지_않습니다")}<br/>
          <br/>
          ${this.$t(
            "환자_정보를_다시_확인해주세요_환자가_먼저_문진을_" +
              "완료한_이후에만_보호자가_문진을_진행할_수_있습니다"
          )}`
        );
      }
    },
    closeErrorPop() {
      this.selectStatus = false;
      this.emmaSelectStatus = false;
      this.userList = [];
      this.phoneBirth = {
        phoneNum: "",
        birthday: "",
      };
    },
  },
  async mounted(){
    await this.InquiryUseCheck();
    if(this.getLanguage === "ru"){
      let kioskArea = document.getElementById('kiosk-area');
      kioskArea.style.paddingTop = '10px';
    }
  }
};
</script>

<style scoped>
.kiosk h1:lang(en),
.kiosk h1:lang(ru),
.kiosk h1:lang(vi),
.kiosk h1:lang(ar),
.kiosk h1:lang(mn) {
  font-size: 60px;
}
.kiosk-area {
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 120px;
}
.terms-area {
  position: absolute;
  bottom: 30px;
}
</style>
<style>


</style>