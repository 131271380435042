<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("문진확인증_작성") }}</h1>
        <h2>{{ $t("본인의_휴대전화_번호를_입력해주세요") }}</h2>
      </div>
      <div class="kiosk-area">
        <KioskInputPhone v-model="phoneNum" />
        <!-- 수집 동의 -->
        <!-- <Terms class="terms-area" ref="terms" v-model="isTermChecked" /> -->
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goNext"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import Header from "../../components/v2/Header";
import KioskInputPhone from "../../components/v2/insertId/KioskInputPhone.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";

export default {
  name: "EmmaPhone",
  components: {
    Header,
    KioskInputPhone,
    // Terms,
  },
  props: {
    refId: String,
    refBirth: String,
    refPhone: String,
  },
  data() {
    return {
      phoneNum: "",
      isTermChecked: true,
      btnLock: false,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType"]),
    canNext: function () {
      return (
        // 핸드폰 번호 입력값 확인
        this.phoneNum !== null &&
        this.phoneNum !== "" &&
        this.phoneNum.length > 9 &&
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
          this.phoneNum
        ) &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async mounted() {
    if (
      this.refId === undefined &&
      this.refBirth === undefined &&
      this.refPhone === undefined &&
      this.$route.query.aid &&
      this.$route.query.kioskType
    ) {
      // direct 진입 시
      const response = await this.$request.api_v1_emmaAnswer({
        answerId: this.$route.query.aid,
      });
      if (response.data.resultCd === 0) {
        this.setKioskId("dummy");
        this.setUserType(this.USER.ENOK);
        this.setKioskType(this.$route.query.kioskType);
        this.$router.replace({
          name: "emmaPhone",
          params: {
            refId: response.data.result.identityNo,
            refBirth: response.data.result.identityBirth,
            refPhone: response.data.result.identityPhone,
          },
        });
      } else {
        this.$modal.error(this.$t("유효하지_않은_문진입니다"));
      }
    }
    await this.InquiryUseCheck();

  },
  methods: {
    ...mapMutations(["setUserType", "setKioskId", "setKioskType"]),
    ...mapActions(['InquiryUseCheck']),
    async goNext() {
      if (!this.canNext || this.btnLock) return;
      // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      this.getInqType(
        {
          answerType: this.getUserType,
          identityPhone: this.phoneNum,
          newPtYn: this.refId ? "N" : "Y",
          refId: this.refId,
          refBirth: this.refBirth,
          refPhone: this.refPhone,
          privacyCode:null,
          sensitiveCode:null,
        },
       
      );
    },
    async getInqType(param) {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/emma", param);
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 201:
          case 200:
            // 201: 기완료(증상있음)
            // 200: 기완료(증상없음)
            // 응급의 경우 항상 재작성이기 때문에 200/201이 오는 케이스 없어야 함
            throw new Error("응급 케이스는 기작성 Case 없음");
          case 0:
            this.$router.push({
              name: "infoAgree",// inqueryView
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: this.refId ? "N" : "Y",
                identityBirth: null,
                identityPhone: null,
                refPhone: this.refPhone,
                refBirth: this.refBirth,
                refId: this.refId,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 ResultCode 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
  },
};
</script>

<style scoped>
.kiosk-area {
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 120px;
}
.kiosk01 .kiosk-input input{
  font-size: 62px;
}
.disabled{
  pointer-events: none;
}
</style>