<template>
  <div class="wrap" @click="hideLangDropDown">
    <!-- 메인 -->
    <section class="kiosk main" style="display: block">
      <!-- kiosk header -->
      <div class="kiost-top">
        <!-- <div class="logo">{{ $t('home.logoText') }}</div> -->

        <div class="lang">
          <button class="langbtn" @click.stop="toggleLangDropDown">
            <i class="ico_lang"></i> {{ $t("LANGUAGE") }}
          </button>

          <div ref="langDropDown" class="lang_dropdown">
            <a href="javascript:void(0)" @click="changeLang('ko')">{{
              $t("LANGUAGE", "ko")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('en')">{{
              $t("LANGUAGE", "en")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ja')">{{
              $t("LANGUAGE", "ja")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('zh')">{{
              $t("LANGUAGE", "zh")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ru')">{{
              $t("LANGUAGE", "ru")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('vi')">{{
              $t("LANGUAGE", "vi")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ar')">{{
              $t("LANGUAGE", "ar")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('mn')">{{
              $t("LANGUAGE", "mn")
            }}</a>
          </div>
        </div>
        <div class="btn_staff" @click="goNext('employee')">
          {{ $t("직원전용") }}
        </div>
        <h1 v-html="$t('출입증발급')" />
        <h2 v-html="$t('출입증발급을위해선택하세요')" />
      </div>
      <!-- 버튼 영역 -->
      <div>
        <div class="btn_pati" @click="goNext('patient')">
          <h3>{{ $t("환_자") }}</h3>
          <!-- <p>{{ $t("외래진료입원검사등") }}</p> -->
          
        </div>
        <div class="btn_guide" @click="goNext('guardian')">
          <p>{{$t("환자_동반")}}</p>
          <h3>{{ $t("보호자") }}</h3>
          
        </div>
        <div class="btn_guest" @click="goNext('visiter')">
          <p>{{$t("회의,_행사,_교육_등")}}</p>
          <h3>{{ $t("방문객") }}</h3>
          
        </div>

        <div class="btn_guest2"  @click="goNext('icuGuest')">
          <p> {{$t("중환자실")}}</p>
          <h3>{{ $t("면회객") }}</h3>
        </div>
      </div>
      <div class="info_text" v-html="$t('키오스크하단에바코드를스캔')"></div>
    </section>
  </div>
</template>

<script>
// import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  data: () => {
    return {
      gstStatus: false,
      gstList: [],
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getVisitHomeTimestamp", "getKioskId"]),
  },
  mounted() {
    // update 체크
    // if (moment(this.getVisitHomeTimestamp).add(60, "minutes") < moment()) {
    //   this.$router.go(); // 새로고침
    // }
    this.initUserType();
    this.setEmmaKiosk(false);
    this.setKioskInfo(this.$route.params.kioskId); // 키오스크 아이디 세팅
    this.ledOff(this.$route.params.kioskId);
    this.$route.query.kioskType &&
      this.setKixoskType(this.$route.query.kioskType); // kioskType 정보가 query에 있는경우 셋팅

    // socket 리스너 등록
    this.$socket.connect(this.getKioskId);
    this.$socket.on("barcode", (barcodeNo) => this.barcodeAction(barcodeNo));

    // 홈화면 이동 시 Default 언어로 다시 셋팅
    this.changeLang("ko");
  },
  beforeDestroy() {
    this.$socket.off("barcode");
    this.$socket.disconnect();
  },
  methods: {
    ...mapMutations([
      "setUserType",
      "initUserType",
      "setLanguage",
      "setEmmaKiosk",
      "setKioskType",
    ]),
    ...mapActions(["setKioskInfo", "InquiryUseCheck"]),
    async barcodeAction(barcodeNo) {
      this.setUserType(this.USER.PT);
      await this.InquiryUseCheck();
      this.$router.push({
        name: "patient",
        params: {
          barcodeNo: barcodeNo,
        },
      });
    },
    changeLang(locale) {
      this.$i18n.locale = locale;
      this.setLanguage(this.$i18n);
      this.hideLangDropDown();
    },
    hideLangDropDown() {
      if (this.$refs.langDropDown)
        this.$refs.langDropDown.style.display = "none";
    },
    toggleLangDropDown() {
      const display = this.$refs.langDropDown.style.display;
      this.$refs.langDropDown.style.display =
        display === "block" ? "none" : "block";
    },
    async goNext(path) {
      switch (path) {
        case "patient":
          this.setUserType(this.USER.PT);
          break;
        case "guardian":
          this.setUserType(this.USER.NOK);
          break;
        case "employee":
          this.setUserType(this.USER.EMP);
          break;
        case "visiter": //일반 방문객, 집합행사 방문객 
          this.setUserType(this.USER.GST);
          break;

         // 면회객 
        case "icuGuest": // 중환자실 방문객 
          this.setUserType(this.USER.ICUGST);
          break;
        case "wardGuest": //병동 방문객
          this.setUserType(this.USER.WGST);
          break;

        default:
          break;
      }
      await this.InquiryUseCheck();
      this.$router.push({
        path: `/insertId/${path}`,
      });
    },
    async ledOff(kioskId) {
      await this.$axios.post("/api/ledOnOff", {
        red: 0,
        green: 0,
        blue: 0,
        kioskId,
      });
    },
  },
};
</script>
<style scoped >
 .wrap {
        background-color: #f6f6f6;
    }

    .kiosk {
        max-width: 870px;
        width: 100%;
        margin: auto;
        padding: 58px 0 55px;
        position: relative;
    }

    .kiost-top {
        padding-top: 200px;
    }

    .kiost-top h1 {
        font-family: 'NEXONLv1GothicB';
        font-size: 90px;
        color: #004c4e;
    }
    .kiost-top h2 {
        padding-top: 60px;
        font-size: 40px;
        letter-spacing: -1px;
          /* padding-right: 200px; */
    }


  .main .btn_pati, .main .btn_guide, .main .btn_guest, .main .btn_guest2
  {display: flex; padding:unset; justify-content: center; align-items: center; flex-direction: column;
      border-radius: 60px; box-shadow: 0 3px 60px 0 rgba(168, 168, 168, 0.66); }

  .main .btn_pati{position:absolute; top:600px; left:10px; width: 400px; height: 450px; margin:0; color:#fff; background: #006769; }
  .main .btn_pati:focus, #kiosk .btn_pati:hover{background: #fff; color:#006769;}
  .main .btn_guest2 h3, .main .btn_guest h3, .main .btn_guide h3,
  .main .btn_pati h3{margin-bottom:0px; text-align: center; font-size:90px; font-family: "NEXONLv1GothicB"; line-height: 1.2; margin-top:10px;}
  .main .btn_guest2 p, .main .btn_guest p, .main .btn_guide p,
  .main .btn_pati p{margin-bottom:20px; color:unset; font-size:40px; font-family: "NEXONLv1GothicR"; line-height: 1.2;}

  .main .btn_guide{position:absolute; top:600px; left:470px; width:400px; height: 450px; color:#fff; background: #006769;}
  .main .btn_guide:focus, #kiosk .btn_guide:hover{background: #006769; color:#fff;}

  .main .btn_guest{position:absolute; top:1125px; left:0; right: unset; width:400px; height: 450px; color:#006769; background: #ffffff;}
  .main .btn_guest:focus, #kiosk .btn_guest:hover{background: #006769; color:#fff;}
  .main .btn_guest2{position:absolute; top:1125px; left:470px; width:400px; height: 450px; color:#006769; background: #ffffff;}
 .main .info_text{position: absolute; bottom:100px; width:100%; font-size:40px; color:#545454; text-align: center; line-height: 1.4}
  .main .info_text{position: absolute; bottom:100px; width:100%; font-size:40px; color:#545454; text-align: center; line-height: 1.4}

 .main .btn_guest2 p, 
  .main .btn_guest p, 
  .main .btn_guide p 
  {position:absolute;
  margin-top:-180px;
  text-align: center;}

</style>