<template>
  <div class="kiosk-area">
    <div class="scroll" style="max-height: 1060px">
      <!-- 증상 -->
      <div class="kiosk-txt check_yes">
        <ul class="img_symptoms">
          <dl
            v-for="item in currInquiryList"
            :key="item.inquiryMappingId"
            :class="{ checked: isIndvdCheck && item.check }"
            @click="onClick($event, item)"
          >
            <dt>
              <div v-if="selectMode" class="checkbox_img"></div>
              37.5도
            </dt>
            <dd>
              <img
                v-bind:src="'data:image/jpeg;base64,' + item.inquiryPicture"
              />
            </dd>
          </dl>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NormalImg",
  props: {
    contents: Object,
    isViewing: Boolean,
  },
  data() {
    return {
      selectMode: false,
      page: 1,
      pageSize: 6, // 한페이지에 표시 가능한 개수
    };
  },
  computed: {
    currInquiryList() {
      return this.contents.inquiryList.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    isIndvdCheck() {
      return this.contents.indvdCheck !== false;
    },
    totalpage() {
      return Math.ceil(this.contents.inquiryList.length / this.pageSize);
    },
    canNext() {
      if (this.contents.needAnswer === true) {
        for (const item of this.contents.inquiryList) {
          if (item.check) return true;
        }
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.isViewing && this.updateBtnText(); // 첫번째 컨텐츠인 경우 mounted 와 동시에 버튼정보 emit
    this.isViewing && this.$emit("btnDisable", !this.canNext);
  },
  beforeDestroy() {
    // show : false 로 변경 시 연계된 질문 show 정보 초기화
    if (this.contents.targetCategoryId !== null) {
      this.$emit("setShow", {
        targetCategoryId: this.contents.targetCategoryId,
        isShow: false,
      });
    }
  },
  methods: {
    onClick(_e, content) {
      if (this.selectMode && this.isIndvdCheck) content.check = !content.check;
    },
    allCheck(check) {
      for (const item of this.contents.inquiryList) {
        item.check = check;
      }
    },
    // ================================= common =================================
    leftBtnAction() {
      if (this.isIndvdCheck && !this.selectMode) {
        // 개별항목을 응답으로 수집 O , 해당 있음 버튼 클릭 -> 선택모드 On
        this.selectMode = true;
      } else if (this.isIndvdCheck && this.selectMode) {
        // 개별항목을 응답으로 수집 O , 취소 버튼 클릭 -> 모두 false로 저장 후 다음문항
        this.allCheck(false);
        this.selectMode = false;
      } else if (!this.isIndvdCheck) {
        // 개별항목을 응답으로 수집 X , 해당 있음 버튼 클릭 -> 모두 true로 저장 후 다음문항
        this.allCheck(true);
        this.emitGoNext();
      }
    },
    rightBtnAction() {
      if (this.totalpage > this.page) {
        // 다음 버튼 선택
        this.page++;
      } else if (this.isIndvdCheck && !this.selectMode) {
        // 개별항목을 응답으로 수집 O , 해당 없음 버튼 클릭 -> 모두 false로 저장 후 다음문항
        this.allCheck(false);
        this.emitGoNext();
      } else if (this.isIndvdCheck && this.selectMode) {
        // 개별항목을 응답으로 수집 O , 제출 버튼 클릭 -> 현재 값 그대로 저장 후 다음문항
        this.emitGoNext();
      } else if (!this.isIndvdCheck) {
        // 개별항목을 응답으로 수집 X , 해당 없음 버튼 클릭 -> 모두 false로 저장 후 다음문항
        this.allCheck(false);
        this.emitGoNext();
      }
    },
    updateBtnText() {
      this.emitLeftBtnText();
      this.emitRightBtnText();
    },
    emitLeftBtnText() {
      this.selectMode
        ? this.$emit("leftBtnText", this.$t("취소"))
        : this.$emit("leftBtnText", this.$t("해당_있음"));
    },
    emitRightBtnText() {
      if (this.totalpage > this.page) {
        this.$emit("rightBtnText", `다음(${this.page}/${this.totalpage})`);
      } else {
        this.selectMode
          ? this.$emit("rightBtnText", this.$t("제출"))
          : this.$emit("rightBtnText", this.$t("해당_없음"));
      }
    },
    emitGoNext() {
      this.contents.answerContents = null;
      for (const item of this.contents.inquiryList) {
        if (item.check === true) {
          this.contents.answerContents = "Y";
          break;
        }
      }
      if (
        this.contents.answerContents === "Y" &&
        this.contents.targetCategoryId !== null
      ) {
        this.$emit("setShow", {
          targetCategoryId: this.contents.targetCategoryId,
          isShow: true,
        });
      }

      this.$emit("goNext");
    },
  },
  watch: {
    isViewing: function (newV) {
      if (newV === true) {
        this.$emit("oneButton", false);
        this.$emit("btnDisable", !this.canNext);
        this.updateBtnText();
      }
    },
    selectMode: function () {
      this.updateBtnText();
    },
    page: function () {
      this.updateBtnText();
    },
    canNext: function (newV) {
      this.$emit("btnDisable", !newV);
    },
  },
};
</script>

<style scoped>
.kiosk-area {
  margin: 90px 0;
  height: auto;
  position: relative;
  bottom: 0;
} /* 별도 style */
.kiosk-txt {
  padding: 0px;
}
.kiosk-txt .txt_c {
  text-align: center;
}
.kiosk-txt ul {
  padding-top: 0px;
}

.kiosk-txt ul li {
  padding-left: 25px;
  padding: 30px 100px 30px 65px;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: -1px;
  position: relative;
  border-bottom: 1px solid #cccccc;
}
.kiosk-txt ul li:last-child {
  border-bottom: none;
}
.kiosk-txt.checkbox_check ul li {
  padding: 0;
}

.kiosk-txt h4 {
  margin-top: 40px;
  font-size: 36px;
  padding: 30px 60px;
  background: #eaeaea;
  font-weight: bold;
}
.kiosk-txt .small {
  font-size: 28px !important;
  line-height: 1.2;
}

.kiosk-txt ul li p {
  padding-bottom: 50px;
  font-size: 30px;
}

.kiosk-txt .img_symptoms dl {
  float: left;
  padding: 35px 0px 35px 100px;
  width: 50%;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.kiosk-txt .img_symptoms dt {
  font-size: 36px;
  padding-bottom: 20px;
  text-align: left;
}
.kiosk-txt .img_symptoms img {
  width: 71%;
}

.check_no .checkbox_img {
  display: none;
}
.check_yes .checkbox_img {
  display: block;
}
.check_yes .checkbox_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}
.check_yes .checked {
  background: #eafffb;
}
.check_yes .checked .checkbox_img,
.check_yes .img_symptoms .checked .checkbox_img {
  background: url("../../../assets/img/ico_checked.png");
}
.check_yes .img_symptoms .checkbox_img {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}

.radio_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top:-20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radio.png");
}
.radiochk_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top:-20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radiochecked.png");
}

.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 50px;
}

.kiosk-txt table {
  width: 100%;
  font-size: 36px;
}
.kiosk-txt th {
  padding: 40px 0 0 40px;
  width: 22%;
  vertical-align: top;
}

.kiosk-txt .label_new {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #00a2ff;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}
.kiosk-txt .label_important {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #f68b1f;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}

.kiosk-txt .pic_date {
  position: absolute;
  right: 5%;
  top: 56%;
  padding-left: 40px;
  color: #00a2ff;
  font-size: 34px;
  background: url(../../../assets/img/ico_calander.png) no-repeat left;
}
.datapicker {
  display: block;
  width: 100%;
  height: 450px;
}
</style>