<template>
  <div>
    <!-- 키오스크 input -->
    <div class="kiosk-input">
      <label for="" class="hide"></label>
      <input
        type="tel"
        maxlength="11"
        placeholder="1234567"
        readonly
        :value="value"
      />
      <a href="javascript:void(0)" class="clear" @click="onClear"></a>
    </div>
    <KeyPad @keyBtn="keyBtn" @clearBtn="clearBtn" @removeBtn="removeBtn" />
  </div>
</template>

<script>
import KeyPad from "@/components/v2/common/KeyPad";

export default {
  name: "KioskInputEmployeeId",
  components: {
    KeyPad,
  },
  props: {
    value: String,
  },
  methods: {
    employeeIdValidation(str) {
      // 자리수 제한 (7)
      return str.slice(0, 7);
    },
    keyBtn(val) {
      this.$emit("input", this.employeeIdValidation(this.value + val));
    },
    clearBtn() {
      this.$emit("input", "");
    },
    removeBtn() {
      this.$emit("input", this.employeeIdValidation(this.value.slice(0, -1)));
    },
    onClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped>

</style>