<template>
  <div class="wrap" @click="hideLangDropDown">
    <!-- 메인 -->
    <section class="kiosk main" style="display: block">
      <!-- kiosk header -->
      <div class="kiost-top">
        <!-- <div class="logo">{{ $t('home.logoText') }}</div> -->

        <div class="lang">
          <button class="langbtn" @click.stop="toggleLangDropDown">
            <i class="ico_lang"></i> {{ $t("LANGUAGE") }}
          </button>

          <div ref="langDropDown" class="lang_dropdown">
            <a href="javascript:void(0)" @click="changeLang('ko')">{{
              $t("LANGUAGE", "ko")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('en')">{{
              $t("LANGUAGE", "en")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ja')">{{
              $t("LANGUAGE", "ja")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('zh')">{{
              $t("LANGUAGE", "zh")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ru')">{{
              $t("LANGUAGE", "ru")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('vi')">{{
              $t("LANGUAGE", "vi")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('ar')">{{
              $t("LANGUAGE", "ar")
            }}</a>
            <a href="javascript:void(0)" @click="changeLang('mn')">{{
              $t("LANGUAGE", "mn")
            }}</a>
          </div>
        </div>
        <h1>{{ $t("문진확인증_발급") }}</h1>
        <h2 v-html="$t('문진확인증_발급을_위해_선택하세요')" />
      </div>
      <!-- 버튼 영역 -->
      <div
        class="btn_pati"
        @click="goNext('patient')"
        :class="{ disabled: !canNext }"
      >
        <h3>{{ $t("환_자") }}</h3>
        <p>{{ $t("외래진료입원검사등") }}</p>
        <i class="img_ai">일러스트</i>
      </div>
      <div
        class="btn_guide"
        @click="goNext('guardian')"
        :class="{ disabled: !canNext }"
      >
        <h3>{{ $t("보호자") }}</h3>
        <i class="img_ai">보호자 일러스트</i>
      </div>
      <div class="notice">
        {{ $t("문진확인증만_발급됩니다") }}<br />
        {{
          $t("원내출입을_희망하시는_경우_안내데스크에_문의하여_주시기_바랍니다")
        }}
      </div>
    </section>
  </div>
</template>

<script>
// import moment from 'moment';
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  data: () => {
    return {
      gstStatus: false,
      gstList: [],
      printState: false,
      checkPrinterStatusInterval: null,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getVisitHomeTimestamp", "getKioskId", "isDummyKiosk"]),
    canNext() {
      return this.printState || this.isDummyKiosk;
    },
  },
  mounted() {
    // update 체크
    // if (moment(this.getVisitHomeTimestamp).add(60, "minutes") < moment()) {
    //   this.$router.go(); // 새로고침
    // }
    this.initUserType();
    this.setEmmaKiosk(true);
    this.setKioskInfo(this.$route.params.kioskId); // 키오스크 아이디 세팅
    this.ledOff(this.$route.params.kioskId);
    this.$route.query.kioskType &&
      this.setKioskType(this.$route.query.kioskType); // kioskType 정보가 query에 있는경우 셋팅

    // socket 리스너 등록
    this.$socket.connect(this.getKioskId);
    this.$socket.on("barcode", (barcodeNo) => this.barcodeAction(barcodeNo));

    // 홈화면 이동 시 Default 언어로 다시 셋팅
    this.changeLang("ko");

    // 종이출입증 상태 확인 (문진확인증 출력 키오스크는 프린터 불가 시 사용 불가)
    this.checkPrinterStatus();
    // 1분에 한번씩 프린터 상태 확인하도록 수정
    this.checkPrinterStatusInterval = setInterval(() => {
      this.checkPrinterStatus(false);
    }, 1 * 60 * 1000);
  },
  beforeDestroy() {
    this.$socket.off("barcode");
    this.$socket.disconnect();
    clearInterval(this.checkPrinterStatusInterval);
  },
  methods: {
    ...mapMutations([
      "setUserType",
      "initUserType",
      "setLanguage",
      "setEmmaKiosk",
      "setKioskType",
    ]),
    ...mapActions(["setKioskInfo"]),
    barcodeAction(barcodeNo) {
      this.setUserType(this.USER.EMMA);
      this.$router.push({
        name: "emmaPatient",
        params: { barcodeNo: barcodeNo },
      });
    },
    changeLang(locale) {
      this.$i18n.locale = locale;
      this.setLanguage(this.$i18n);
      this.hideLangDropDown();
    },
    hideLangDropDown() {
      if (this.$refs.langDropDown)
        this.$refs.langDropDown.style.display = "none";
    },
    toggleLangDropDown() {
      const display = this.$refs.langDropDown.style.display;
      this.$refs.langDropDown.style.display =
        display === "block" ? "none" : "block";
    },
    async goNext(path) {
      if (!this.isDummyKiosk && !this.printState) {
        await this.checkPrinterStatus();
        if (!this.printState) return;
      }
      switch (path) {
        case "patient":
          this.setUserType(this.USER.EMMA);
          this.$router.push({ name: "emmaPatientInter" });
          break;
        case "guardian":
          this.setUserType(this.USER.ENOK);
          this.$router.push({ name: "emmaNewPatient" });
          break;
        default:
          break;
      }
    },
    async ledOff(kioskId) {
      await this.$axios.post("/api/ledOnOff", {
        red: 0,
        green: 0,
        blue: 0,
        kioskId,
      });
    },
    async checkPrinterStatus(withPopup = true) {
      if (this.isDummyKiosk) return;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get("/api/printer-state", {
          params: { deviceId: this.getKioskId },
        });
        if (response.data && response.data.printState !== undefined) {
          // -1: 끊김, 0: 정상, 1: 용지걸림, 2: 용지부족, 3: 용지없음
          this.printState =
            response.data.printState === 0 || response.data.printState === 2;

          switch (response.data.printState) {
            case -1:
              withPopup && this.showPrintStatePopup(this.$t("연결끊김"));
              break;
            case 1:
              withPopup && this.showPrintStatePopup(this.$t("용지걸림"));
              break;
            // case 2:
            //   withPopup && this.showPrintStatePopup(this.$t("용지부족"));
            //   break;
            case 3:
              withPopup && this.showPrintStatePopup(this.$t("용지없음"));
              break;
            default:
              break;
          }
        } else {
          throw new Error("error");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    showPrintStatePopup(info) {
      this.$modal.error(
        `${this.$t("문진확인증_출력이_불가능합니다")}<br/>(${info})`
      );
    },
  },
};
</script>
<style scoped >
:lang(en) h1 {
  font-size: 70px;
}
:lang(ru) .main .kiost-top {
  padding-top: 200px;
}
:lang(ru) .kiosk h2 {
  width: 70%;
}
:lang(ar) .main .btn_pati .img_ai,
:lang(ar) .main .btn_guide .img_ai,
:lang(ar) .main .btn_guest .img_ai {
  right: auto;
  left: 0;
  transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
:lang(ar) .main .btn_guide {
  padding: 70px 97px 0px 203px;
}
:lang(mn) .main .btn_guide h3 {
  font-size: 60px;
}

.main .notice {
  bottom: 110px;
}
.main .btn_guide {
  position: absolute;
  width: 820px;
  top: 1260px;
  left: 10px;
  height: 227px;
  margin: 0;
  padding: 80px 153px 103px 100px;
  border-radius: 20px;
  box-shadow: 0 10px 40px 0 rgba(135, 135, 135, 0.25);
  color: #006769;
  background: #ffffff;
}

.main .btn_guide:focus {
  background: #006769;
  color: #fff;
}

.main .btn_guide h3 {
  margin-bottom: 20px;
  font-size: 70px;
  font-family: "NEXONLv1GothicB";
  line-height: 1.2;
}

.main .btn_guide .img_ai {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 260px;
  height: 278px;
  background: url(../assets/img/ai_guide.png) 100%;
  border-radius: 0 0 20px 0;
  overflow: hidden;
  text-indent: -1000px;
}
</style>