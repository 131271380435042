<template>
  <!-- 키오스크 번호 -->
  <div class="keypad">
    <ul>
      <li><a href="javascript:void(0)" @click="clickKey">1</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">2</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">3</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">4</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">5</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">6</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">7</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">8</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">9</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">0</a></li>
    </ul>
    <ul>
      <li><a href="javascript:void(0)" @click="clickKey">Q</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">W</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">E</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">R</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">T</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">Y</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">U</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">I</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">O</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">P</a></li>
    </ul>
    <ul>
      <li><a href="javascript:void(0)" @click="clickKey">A</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">S</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">D</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">F</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">G</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">H</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">J</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">K</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">L</a></li>
    </ul>
    <ul>
      <div></div>
      <li><a href="javascript:void(0)" @click="clickKey">Z</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">X</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">C</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">V</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">B</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">N</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">M</a></li>
      <div></div>
    </ul>
    <ul>
      <li class="btn_del_all">
        <a href="javascript:void(0)" @click="clearBtn">{{ $t("전체삭제") }}</a>
      </li>
      <li class="btn_del">
        <a href="javascript:void(0)" @click="removeBtn">{{ $t("지움") }}</a>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "KeyPad",
  methods: {
    clickKey(e) {
      this.$emit("keyBtn", e.target.innerText);
    },
    clearBtn: function () {
      this.$emit("clearBtn");
    },
    removeBtn: function () {
      this.$emit("removeBtn");
    },
    onKeyDown: function (e) {
      // esc Btn
      if (e.key === "Escape") {
        this.clearBtn();
        return;
      }
      // Backspace Btn
      if (e.key === "Backspace") {
        this.removeBtn();
        return;
      }
      const matched = e.key.match(/([A-Za-z0-9])/i);
      if (matched && matched.length > 1) {
        this.$emit("keyBtn", e.key.toUpperCase());
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown, false);
  },
};
</script>

<style  scoped>
.keypad {
  padding-bottom: 445px;
  width: 670px;
  margin: auto;
  padding: 100px 0 250px 0;
  overflow: hidden;
}

.keypad ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.keypad ul li {
  width: 56px;
  height: 56px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #f6f6f6;
  border: 1px solid gray
}

.keypad ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #666;
}

.keypad ul li.btn_del_all {
  width: auto;
  padding:0 15px;
}

.keypad ul li.btn_del_all a {
  font-size: 30px;
  font-weight: bold;
}

.keypad ul li.btn_del {
  width: auto;
  padding:0 15px;
}

.keypad ul li.btn_del a {
  font-size: 30px;
  font-weight: bold;
}
</style>