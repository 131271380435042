<template>
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="error">
        <img src="@/assets/img/ico_q.png" width="200px" />
        <div class="msg">
          {{ $t("진료_일정이_확인되지_않습니다") }}<br /><br />
          <p
            v-html="
              $t(
                '단_응급_산부인과_진료가_필요한_경우_안내데스크에서_방문_접수해주시기_바랍니다'
              )
            "
          />
        </div>
        <div class="info_msg">{{ $t("현장_직원에게_문의해_주세요") }}</div>
      </div>
      <!-- 버튼 -->
      <div class="btn-area type1" @click="closePopup">
        <a href="javascript:void(0)" class="btn-default">{{ $t("닫기") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsertErrorPopup",
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped>
.error .msg {
  padding: 30px 0;
  font-size: 42px;
  line-height: 1.4;
}
.error .info_msg {
  padding: 40px 0;
  font-size: 36px;
}
:lang(ja) .error .msg, :lang(ja) .error .info_msg  { 
  word-break: break-all;
}

/* ----- modal ----- */
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(246, 246, 246, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop .title {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #333333;
}
.modal-prop .title.active {
  margin-top: 20px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border-radius: 10px;
  color: #004c4e;
  background: #f6f6f6;
}
.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}
</style>