<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("출입_등록") }}</h1>
        <h2>{{ $t("본인의_휴대전화_번호를_입력해주세요") }}</h2>
      </div>
      <div class="kiosk-area">
        <KioskInputPhone v-model="phoneNum" class="long_numpad"/>
        <!-- 수집 동의 -->
        <!-- <Terms class="terms-area" ref="terms" v-model="isTermChecked" /> -->
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goNext"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>


    </section>
    <!-- <NokWarningPopup v-if="initNokPopup" @closePopup="closeNokPop" /> -->
    <InsertErrorPopup v-if="errPopView" @closePopup="closeErrorPop" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header";
import KioskInputPhone from "../../components/v2/insertId/KioskInputPhone.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";
import InsertErrorPopup from "../../components/v2/modal/InsertErrorPopup.vue";
import moment from "moment";
// import NokWarningPopup from "../../components/v2/modal/NokWarningPopup.vue";


export default {
  name: "Phone",
  components: {
    Header,
    KioskInputPhone,
    // Terms,
    InsertErrorPopup,
    // NokWarningPopup
  },
  props: {
    patientId: {
      type: String,
      default: null,
    },
    vistCnt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      phoneNum: "",
      isTermChecked: true,
      btnLock: false,
      errPopView: false, // 에러화면 노출 여부
      initNokPopup : true,
      date_in : null,
      refId:null,
      preVistCnt : null,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getKioskId", 'getInquiryYn' ]),
    canNext: function () {
      // 병원등록번호로 인증
      return (
        // 핸드폰 번호 입력값 확인
        this.phoneNum !== null &&
        this.phoneNum !== "" &&
        this.phoneNum.length > 9 &&
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
          this.phoneNum
        ) &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  beforeRouteEnter (to, from, next) {
    if(to.name == 'phone' && from.name=='infoAgree') {
      next(vm => {
        vm.refId = from.params.refId || to.params.refId
        vm.preVistCnt = from.params.vistCnt || to.params.vistCnt
      });
    } else {
      next()
    }
  },
  methods: {
    async goNext() {
      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/nok", {
          identityNo: this.phoneNum,
          answerType: this.getUserType,
          visitDate: moment().format("YYYYMMDD"),
          refId: this.patientId || this.refId,
          vistCnt: this.vistCnt || this.preVistCnt
        });
        // if (!response.data.success) throw new Error("error");
        // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();

        switch (response.data.resultCd) {
          case 201:
            // 증상있음 출입 금지.
            this.$router.push({
              name: "forbiden",
              params: {
                passCheck: response.data.result.passCheck,
                refId: this.patientId || this.refId,
              },
            });
            break;
          case 200:
            // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.push({
              name: "passWithPrint",
              params: {
                passCheck: response.data.result.passCheck,
                refId: this.patientId || this.refId,
              },
            });
            break;
          case 307:
            // 다른 보호자가 입장 중입니다.
            this.phoneNum = "";
            // this.date_in = new Date(response.data.result.pubPassTime);
            // if(this.getInquiryYn){
            //   this.$modal.error(this.$t('원내_출입_가능한_보호자는_환자당_1인으로_제한됩니다',{'0':`${this.date_in.getHours().toString().padStart(2, "0")}`, '1':`${this.date_in.getMinutes().toString().padStart(2, "0")}`}));
            // } else {
            //   this.$modal.error(this.$t('원내_출입_가능한_보호자는_환자당_1인으로_제한됩니다_no_inq',{'0':`${this.date_in.getHours().toString().padStart(2, "0")}`, '1':`${this.date_in.getMinutes().toString().padStart(2, "0")}`}));
            // }
              if(this.getUserType === 'ICUGST'){
                this.$modal.error(this.$t('허용_가능한_면회객_수를_초과하여_출입이_불가합니다'));
              }else{
                this.$modal.error(this.$t('허용_가능한_동반보호자_수를_초과하여_출입이_불가합니다'));

              }

            this.goHome();
            break;
          case 0:
            this.$router.push({
              name: "infoAgree", //inquiryView
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: "N",
                identityBirth: null,
                identityPhone: null,
                refPhone: null,
                refBirth: null,
                refId: this.$route.params.patientId || this.refId,
                vistCnt: response.data.result.vistCnt,
                visitReason: this.$route.params.visitReason || null,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    closeErrorPop() {
      this.errPopView = false;
      this.phoneNum = "";
    },
    closeNokPop() {
      this.initNokPopup = false;
     
    },
    goHome() {
      // window.location.href = `${window.location.origin}/#/kiosk/${this.getKioskId}`;
      if (this.getKioskId === "preview") return;
      if (this.getIsEmmaKiosk) {
        this.$router.push({
          name: "emmahome",
          params: {
            kioskId: this.getKioskId,
          },
        });
      } else {
        this.$router.push({
          name: "home",
          params: {
            kioskId: this.getKioskId,
          },
        });
      }
    },   
    
  },
};
</script>

<style scoped>
.kiosk-area {
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 120px;
}

</style>