<template>
  <div class="kiosk-area">
    <div class="scroll" style="max-height: 1060px">
      <!-- 증상 -->
      <div class="kiosk-txt check_yes">
        <table>
          <tr
            v-for="(item, index) in pagingPlaceList"
            :key="index"
            style="height: 150px"
          >
            <th
              v-if="isRowSpan(item.local1, index)"
              :rowspan="rowSpanSize(item.local1, index)"
              v-html="inserCustomBR(getEnLocal1(item))"
            />
            <td
              class="ckeck-target"
              :class="{ checked: isIndvdCheck && item.check }"
              @click="onClick(item)"
            >
              <span v-if="newBadgeChk(item)" class="label_new">
                {{ $t("신규") }}
              </span>
              <span v-if="item.grade === 1" class="label_important">
                {{ $t("중요") }}
              </span>
              {{ tdTextValue(item) }}
              <div
                v-if="selectMode && isIndvdCheck"
                class="checkbox_img"
                :class="{ with_date: item.check }"
              />
              <div
                v-if="isIndvdCheck && item.placeDate !== null"
                class="pic_date"
                @click.stop="onDateClick(item)"
              >
                {{ picDateFormat(item.placeDate) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "VisitHistory",
  props: {
    contents: Object,
    isViewing: Boolean,
  },
  data() {
    return {
      selectMode: false,
      page: 1,
      pageSize: 7, // 한페이지에 표시 가능한 개수
      resultData: [], // 제출할 데이터
      selectTarget: null, // 컨텐츠 클릭시 팝업 노출동안 임시 저장용 데이터
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getLanguage"]),
    isIndvdCheck() {
      return this.contents.indvdCheck === true;
    },
    totalpage() {
      return Math.ceil(this.contents.placeList.length / this.pageSize);
    },
    canNext() {
      if (this.selectMode) {
        for (const item of this.pagingPlaceList) {
          if (item.placeDate !== null) return true;
        }
        return false;
      } else {
        return true;
      }
    },
    pagingPlaceList() {
      return this.contents?.placeList.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    existCheckedContents() {
      for (const item of this.contents?.placeList) {
        if (item.placeDate !== null) return true;
      }
      return false;
    },
  },
  mounted() {
    this.isViewing && this.updateBtnText(); // 첫번째 컨텐츠인 경우 mounted 와 동시에 버튼정보 emit
    this.isViewing && this.$emit("btnDisable", !this.canNext);
  },
  methods: {
    getEnLocal1(item) {
      return this.getLanguage === "ko" || !item.localEn1
        ? item.local1
        : item.localEn1;
    },
    isRowSpan(text, index) {
      for (let i = 0; i < this.pagingPlaceList.length; i++) {
        if (text === this.pagingPlaceList[i].local1) {
          return i === index;
        }
      }
      return false;
    },
    rowSpanSize(text, index) {
      let cnt = 0;
      for (let i = index; i < this.pagingPlaceList.length; i++) {
        if (text === this.pagingPlaceList[i].local1) cnt++;
      }
      return cnt;
    },
    inserCustomBR(str) {
      return str.replace(/(특별시|광역시|자치시)/, "<br/>$1");
    },
    newBadgeChk(item) {
      const today = moment(moment().format('YYYYMMDD'));
      const updDate = moment(moment(item.updDate).format('YYYYMMDD'));
      const regDate = moment(moment(item.regDate).format('YYYYMMDD'));
      return (
        (item.updDate && today <= updDate.add(1, 'day')) ||
        (item.regDate && today <= regDate.add(1, 'day'))
      );
    },
    tdTextValue(item) {
      if (this.getLanguage === "ko") {
        return `${item.local2 === "-" ? "" : item.local2} ${
          item.local3 === "-" ? "" : item.local3
        } ${item.storeName} (${item.boundaryDate})`;
      } else {
        const loc2 =
          item.localEn2 && item.localEn2 !== "" && item.localEn2 !== "-"
            ? item.localEn2
            : `${item.local2 === "-" ? "" : item.local2}`;
        const loc3 =
          item.localEn3 && item.localEn3 !== "" && item.localEn3 !== "-"
            ? item.localEn3
            : `${item.local3 === "-" ? "" : item.local3}`;
        const storeNm =
          item.storeNameEn && item.storeNameEn !== ""
            ? item.storeNameEn
            : item.storeName;

        return `${loc2} ${loc3} ${storeNm} (${item.boundaryDate})`;
      }
    },
    picDateFormat(str) {
      return moment(str).format("M/D");
    },
    onClick(content) {
      if (!this.selectMode || !this.isIndvdCheck) return;
      if (content.check === true) {
        content.check = false;
        content.placeDate = null;
      } else {
        this.selectTarget = content;
        this.$emit("showDatePickerPopup", {
          datePickerPopupText: `${this.getEnLocal1(content)} ${this.tdTextValue(
            content
          )}`,
          boundaryDateSplit: content.boundaryDateSplit,
          placeDate: content.placeDate,
        });
      }
    },
    onDateClick(content) {
      if (!this.selectMode || !this.isIndvdCheck) return;
      this.selectTarget = content;
      this.$emit("showDatePickerPopup", {
        datePickerPopupText: `${this.getEnLocal1(content)} ${this.tdTextValue(
          content
        )}`,
        boundaryDateSplit: content.boundaryDateSplit,
        placeDate: content.placeDate,
      });
    },
    onSelectDate(selectDate) {
      if (!this.selectMode || !this.isIndvdCheck || !selectDate) return;
      this.selectTarget.check = true;
      this.selectTarget.placeDate = moment(selectDate).format("YYYY-MM-DD");
    },
    allCheck(check) {
      for (const item of this.pagingPlaceList) {
        item.check = check;
        if (check) {
          item.placeDate = moment().format("YYYY-MM-DD");
        } else {
          item.placeDate = null;
        }
      }
    },
    saveData() {
      for (const place of this.pagingPlaceList) {
        if (place.check) this.resultData.push(place);
      }
    },
    updateData(data) {
      this.$emit("updatePlaceData", data);
    },
    // ================================= common =================================
    leftBtnAction() {
      if (this.isIndvdCheck && !this.selectMode) {
        // 개별선택인 경우 해당 있음 버튼 선택 시 -> 선택모드로 변경
        this.selectMode = true;
      } else if (this.isIndvdCheck && this.selectMode) {
        // 개별선택인 경우 취소 버튼 선택 시 -> 모두 false로 수정 후 선택모드 off
        this.allCheck(false);
        this.selectMode = false;
      } else if (!this.isIndvdCheck) {
        // 개별선택이 아닌 경우 해당 있음 버튼 선택 시 -> 현재 placeList를 모두 저장한 후 제출
        this.allCheck(true);
        this.saveData();
        this.emitGoNext();
      }
    },
    rightBtnAction() {
      if (this.isIndvdCheck) {
        // 개별선택인 경우
        if (this.totalpage > this.page) {
          // 다음 버튼 선택
          this.saveData();
          this.selectMode = false;
          this.page++;
        } else {
          // 해당 없음 버튼 선택
          this.saveData();
          this.emitGoNext();
        }
      } else {
        // 개별선택이 아닌 경우
        if (this.totalpage > this.page) {
          // 다음 버튼 선택
          this.page++;
        } else {
          // 해당 없음 버튼 선택
          this.emitGoNext();
        }
      }
    },
    updateBtnText() {
      this.emitLeftBtnText();
      this.emitRightBtnText();
    },
    emitLeftBtnText() {
      if (this.isIndvdCheck) {
        // 개별선택인 경우
        this.selectMode
          ? this.$emit("leftBtnText", this.$t("취소"))
          : this.$emit("leftBtnText", this.$t("해당_있음"));
      } else {
        this.$emit("leftBtnText", this.$t("해당_있음"));
      }
    },
    emitRightBtnText() {
      if (this.isIndvdCheck) {
        // 직원인 경우
        if (this.totalpage > this.page) {
          this.$emit(
            "rightBtnText",
            `${this.$t("다음")}(${this.page}/${this.totalpage})`
          );
        } else {
          this.selectMode
            ? this.$emit("rightBtnText", this.$t("제출"))
            : this.$emit("rightBtnText", this.$t("해당_없음"));
        }
      } else {
        // 직원이 아닌 경우
        if (this.totalpage > this.page) {
          this.$emit(
            "rightBtnText",
            `${this.$t("다음")}(${this.page}/${this.totalpage})`
          );
        } else {
          this.$emit("rightBtnText", this.$t("해당_없음"));
        }
      }
    },
    emitGoNext() {
      this.updateData(this.contents.placeList);
      this.$emit("goNext");
    },
  },
  watch: {
    isViewing: function (newV) {
      if (newV === true) {
        this.page = 1;
        this.$emit("oneButton", false);
        this.$emit("btnDisable", !this.canNext);
        this.updateBtnText();
      }
    },
    selectMode: function () {
      this.updateBtnText();
    },
    page: function () {
      this.updateBtnText();
    },
    canNext: function (newV) {
      this.$emit("btnDisable", !newV);
    },
    existCheckedContents: function (newV) {
      if (this.contents.targetCategoryId !== null) {
        // 연관질문이 있을 경우
        this.$emit("setShow", {
          targetCategoryId: this.contents.targetCategoryId,
          isShow: newV,
        });
      }
    },
  },
};
</script>

<style scoped>
.kiosk-area {
  margin: 90px 0;
  height: auto;
  position: relative;
  bottom: 0;
} /* 별도 style */
.kiosk-txt {
  padding: 0px;
}
.kiosk-txt .txt_c {
  text-align: center;
}
.kiosk-txt ul {
  padding-top: 0px;
}

.kiosk-txt ul li {
  padding-left: 25px;
  padding: 30px 100px 30px 65px;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: -1px;
  position: relative;
  border-bottom: 1px solid #cccccc;
}
.kiosk-txt ul li:last-child {
  border-bottom: none;
}
.kiosk-txt.checkbox_check ul li {
  padding: 0;
}

.kiosk-txt h4 {
  margin-top: 40px;
  font-size: 36px;
  padding: 30px 60px;
  background: #eaeaea;
  font-weight: bold;
}
.kiosk-txt .small {
  font-size: 28px !important;
  line-height: 1.2;
}

.kiosk-txt ul li p {
  padding-bottom: 50px;
  font-size: 30px;
}

.kiosk-txt .img_symptoms dl {
  float: left;
  padding: 35px 0px 35px 100px;
  width: 50%;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.kiosk-txt .img_symptoms dt {
  font-size: 36px;
  padding-bottom: 20px;
  text-align: left;
}
.kiosk-txt .img_symptoms img {
  width: 71%;
}

.radio_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radio.png");
}
.radiochk_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radiochecked.png");
}

.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 50px;
}

.check_no .checkbox_img {
  display: none;
}
.check_yes .checkbox_img {
  display: block;
}
.check_yes .checkbox_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}
.check_yes .checked {
  background: #eafffb;
}
.check_yes .checked .checkbox_img,
.check_yes .img_symptoms .checked .checkbox_img {
  background: url("../../../assets/img/ico_checked.png");
}
.check_yes .img_symptoms .checkbox_img {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}

.check_yes .checkbox_img.with_date {
  margin-top: -60px;
}
</style>