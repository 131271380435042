const Modal = {
    install(Vue) {
        const ModalEventBus = new Vue();
        const modalEvents = {
            info(title, message) {
                ModalEventBus.$emit('info', {
                    title,
                    message
                });
            },
            infoWordBreak(title, message, isWordBreak) {
                ModalEventBus.$emit('infoWordBreak', {
                    title,
                    message,
                    isWordBreak
                });
            },
            error(message) {
                ModalEventBus.$emit('error', {
                    message
                });
            },
            hide() {
                ModalEventBus.$emit('hide');
            },
            loading(onOff) {
                if (onOff) ModalEventBus.$emit('loadingOn');
                else ModalEventBus.$emit('loadingOff');
            },
            on: ModalEventBus.$on.bind(ModalEventBus),
            off: ModalEventBus.$off.bind(ModalEventBus)
        };
        Vue.prototype.$modal = modalEvents;
    }
};

export default Modal;