<template>
  <div class="phone-container">
    <!-- 키오스크 input -->
    <div class="kiosk-input dotted-input">
      <label for="" class="hide"></label>
      <input
        ref="phoneInput"
        type="tel"
        maxlength="11"
        placeholder="010-1234-5678"
        v-model="phone_Num_View"
      />
      <a href="javascript:void(0)" class="clear" @click="onClear"></a>
    </div>
    <!-- 키오스크 번호 -->
    <NumPad
      class="kiosk-num guide"
      :phoneStatus="true"
      @keyBtn="keyBtn"
      @clearBtn="clearBtn"
      @removeBtn="removeBtn"
    />
  </div>
</template>

<script>
import NumPad from "@/components/v2/common/NumPad";

export default {
  name: "KioskInputPhone",
  components: {
    NumPad,
  },
  props: {
    value: String,
  },
  computed: {
    phone_Num_View: function () {
      let returnValue = this.value.slice(0, 3);
      if (this.value.length > 3) returnValue += "-";
      returnValue += this.value.slice(3, 7);
      if (this.value.length > 7) returnValue += "-";
      returnValue += this.value.slice(7, 11);
      return returnValue;
    },
  },
  methods: {
    phoneNumValidation(str) {
      // 자리수 제한 (11)
      this.$refs.phoneInput.focus();
      return str.slice(0, 11);
    },
    birthdayValidation(str) {
      // 자리수 제한 (8)
      return str.slice(0, 8);
    },
    keyBtn(val) {
      this.$emit("input", this.phoneNumValidation(this.value + val));
    },
    clearBtn() {
      this.$emit("input", "");
    },
    removeBtn() {
      this.$emit("input", this.phoneNumValidation(this.value.slice(0, -1)));
    },
    onClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped>
.num_box input[type="text"] {
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  background: #f6f6f6;
  text-align: center;
}

/* -------------------------------------------- */
.phone-container {
  background: var(--background-green);
  height: 940px;
  padding-top: 40px;
  border-radius: 20px;
}
.kiosk01 .dotted-input::before {
  left: 17.5%;
  width: 65%;
  border-radius: 0;
  background-color: #509d9c;
  background: linear-gradient(to right, #509d9c 7%, #00000000 7% 8.25%, #509d9c 8.25% 15.25%, #00000000 15.25% 16.5%, #509d9c 16.5% 24%, #00000000 24% 30%, 
                              #509d9c 30% 37%, #00000000 37% 38.5%, #509d9c 38.5% 45.5%, #00000000 45.5% 46.5%, #509d9c 46.5% 53.5%, #00000000 53.5% 54.5%, #509d9c 54.5% 61.5%, #00000000 61.5% 68.5%, 
                              #509d9c 68.5% 75.5%, #00000000 75.5% 76.5%, #509d9c 76.5% 83.5%, #00000000 83.5% 84.5%, #509d9c 84.5% 91.5%, #00000000 91.5% 92.5%, #509d9c 92.5% 99.5%, #00000000 99.5% );
}
.kiosk01 .dotted-input input {
  letter-spacing:2px;
  margin-top: 40px;
  font-family: "Roboto";
  padding-left: 17.5%;
  text-align: left;
  caret-color: #509d9c;
}
.kiosk01 .kiosk-input input{
    letter-spacing:7.5px;
  font-size: 52px;
}
.kiosk01 .dotted-input input::placeholder {
  letter-spacing:7.5px;
  font-size: 52px;
}
.kiosk01 .dotted-input .clear {
  margin: 40px 20px 0 0;
}

</style>