<template>
  <div id='input-container' class="input-container">
    <!-- 키오스크 input -->
    <div id='emma-kiosk-input'>
      <div class="kiosk-input phone-num">
        <label for="" class="hide"></label>
        <input
          ref="phoneInput"
          type="tel"
          maxlength="11"
          placeholder="010-1234-5678"
          v-model="phone_Num_View"
          @focus="onInputFocus($event, 'phone')"
        />
        <a
          href="javascript:void(0)"
          class="clear"
          @click="onClear($event, 'phone')"
        ></a>
      </div>
      <div class="kiosk-input birth">
        <label for="" class="hide"></label>
        <input
          ref="birthInput"
          type="number"
          maxlength="11"
          :placeholder="`${$t('생년월일6자리')}`"
          v-model="value.birthday"
          @focus="onInputFocus($event, 'birth')"
        />
        <a
          href="javascript:void(0)"
          class="clear"
          @click="onClear($event, 'birth')"
        ></a>
      </div>
    </div>
    <!-- 키오스크 번호 -->
    <NumPad
      :phoneStatus="true"
      @keyBtn="keyBtn"
      @clearBtn="clearBtn"
      @removeBtn="removeBtn"
    />
  </div>
</template>

<script>
import NumPad from "@/components/v2/common/NumPad";
export default {
  name: "KioskInputPhoneBirth",
  components: {
    NumPad,
  },
  props: {
    value: Object,
    // value.phoneNum
    // value.birthday
  },
  data: () => {
    return {
      focusInput: null,
    };
  },
  computed: {
    phone_Num_View: function () {
      let returnValue = this.value.phoneNum.slice(0, 3);
      if (this.value.phoneNum.length > 3) returnValue += "-";
      returnValue += this.value.phoneNum.slice(3, 7);
      if (this.value.phoneNum.length > 7) returnValue += "-";
      returnValue += this.value.phoneNum.slice(7, 11);
      return returnValue;
    },
  },
  mounted() {
    this.$refs.phoneInput.focus();

    if(this.$route.name === "emmaNewPatient"){
      let height = document.getElementById('input-container');
      let padding = document.getElementById('emma-kiosk-input');
      height.classList.add('long-contents')
      padding.style.paddingTop = '80px';
      padding.style.marginTop = '40px';
    }
  },
  methods: {
    phoneNumValidation(str) {
      // 자리수 제한 (11)
      this.$refs.phoneInput.focus();
      return str.slice(0, 11);
    },
    birthdayValidation(str) {
      // 자리수 제한 (6)
      this.$refs.birthInput.focus();
      return str.slice(0, 6);
    },
    keyBtn(val) {
      if (this.focusInput === "phone") {
        const newPhoneValue = this.phoneNumValidation(
          this.value.phoneNum + val
        );
        this.$emit("input", {
          ...this.value,
          phoneNum: newPhoneValue,
        });
        newPhoneValue.length > 10 && this.$refs.birthInput.focus();
      } else {
        this.$emit("input", {
          ...this.value,
          birthday: this.birthdayValidation(this.value.birthday + val),
        });
      }
    },
    clearBtn() {
      if (this.focusInput === "phone") {
        this.$emit("input", {
          ...this.value,
          phoneNum: "",
        });
      } else {
        this.$emit("input", {
          ...this.value,
          birthday: "",
        });
      }
    },
    removeBtn() {
      if (this.focusInput === "phone") {
        this.$emit("input", {
          ...this.value,
          phoneNum: this.phoneNumValidation(this.value.phoneNum.slice(0, -1)),
        });
      } else {
        this.$emit("input", {
          ...this.value,
          birthday: this.birthdayValidation(this.value.birthday.slice(0, -1)),
        });
        this.$nextTick(() => {
          this.value.birthday === "" && this.$refs.phoneInput.focus();
        });
      }
    },
    onInputFocus(_e, type) {
      this.focusInput = type;
    },
    onClear(_e, type) {
      if (type === "phone") {
        this.$emit("input", {
          ...this.value,
          phoneNum: "",
        });
        this.$refs.phoneInput.focus();
      } else if (type === "birth") {
        this.$emit("input", {
          ...this.value,
          birthday: "",
        });
        this.$refs.birthInput.focus();
      }
    },
    isEmma(){
      if(this.$route.name === "emmaNewPatient"){
        return true
      }
      return false
    }
  },
};
</script>

<style>
.input-container{
  height: 750px;
  background: var(--background-green);
  margin: -24px -40px 10px -40px;
  padding: 10px 0 0 0;
  width: 112%;
  background-clip: content-box;
  border-radius: 0 0 20px 20px;
}
.long-contents.input-container {
  height: 950px;
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
}
.long-contents.input-container  .kiosk01 .kiosk-input input{
  font-size: 56px;
}
.kiosk01 .kiosk-input input {
  letter-spacing:12px;
  width: 100%;
  /* padding:0 100px; */
  font-size: 56px;
  font-family: "Roboto";
  font-weight: 700;
  color: #111;
  text-align: center;
  /* font-family:'Noto Sans KR'; */

}
.kiosk01 .phone-num::before {
  left: 15%;
  width: 69%;
  background-color: #509d9c;
  background: linear-gradient(to right, #509d9c 6.5%, #00000000 6.5% 8%, #509d9c 8% 14.5%, #00000000 14.5% 16%, #509d9c 16% 23%, #00000000 23% 30.5%, 
                              #509d9c 30.5% 36.5%, #00000000 36.5% 38.5%, #509d9c 38.5% 44.5%, #00000000 44.5% 46.5%, #509d9c 46.5% 53%, #00000000 53% 55%, #509d9c 55% 61%, #00000000 61% 68.5%, 
                              #509d9c 68.5% 75%, #00000000 75% 77%, #509d9c 77% 83%, #00000000 83% 85%, #509d9c 85% 91%, #00000000 91% 93%, #509d9c 93% 99.5%, #00000000 99.5% );
  border-radius: 0;
}
.kiosk01 .phone-num {
  margin-bottom: 30px;
  padding-top: 20px;
}
.kiosk01 .phone-num input {
  padding-left: 15.3%;
  text-align: left;
  caret-color: #509d9c;
}
.kiosk01 .phone-num input::placeholder {
  font-size: 56px;
}
.kiosk01 .birth {
  margin-bottom: 50px;
}
.kiosk01 .birth::before {
  left: 33%;
  width: 33%;
  background: linear-gradient(90deg, #509d9c 85%, #00000000 0);
  background-size: 44.5px 100%;
  border-radius: 0;
}
.kiosk01 .birth input {
  padding-left: 33%;
  text-align: left;
  caret-color: #509d9c;
}
.kiosk01 .birth input::placeholder {
  font-size: 39px;
  letter-spacing: 0;
}
.kiosk01 .birth input:lang(ar)::placeholder,
.kiosk01 .birth input:lang(ru)::placeholder,
.kiosk01 .birth input:lang(mn)::placeholder,
.kiosk01 .birth input:lang(en)::placeholder,
.kiosk01 .birth input:lang(vi)::placeholder
{
  font-size: 25px;
}
.kiosk01 .kiosk-input .clear {
  margin: 20px 20px 0 0;
}
.emma-phone-container{
  height: 1100px;
}
.emma-kiosk-input{
  padding-top: 80px;
}
</style>