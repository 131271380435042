<template>
  <!-- 본인확인 -->
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="modal-header">
        <h3>{{ $t("환자_확인") }}</h3>
        {{ $t("환자_정보를_확인해주세요") }}
      </div>
      <div class="modal-body">
        <div class="modal_conts">
          <p v-html="$t('_휴대전화_번호_', { '0': `${phoneNumFormat}` })"></p>
          <p v-html="$t('_생년월일_', { '0': `${birthFormat}` })"></p>
          <p
            v-html="$t('_응급실_내원_시간_', { '0': `${dateInfoFormat}` })"
          ></p>
        </div>
        <!-- 버튼 -->
        <div class="btn-area type1">
          <a href="javascript:void(0)" class="btn-default" @click="next">{{
            $t("다음")
          }}</a>
          <a
            href="javascript:void(0)"
            class="btn-default btn-white"
            @click="closePopup"
            >{{ $t("다시_입력") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SelectNewPatientPopup",
  props: {
    emmaUserInfo: Object,
  },
  data: () => {
    return {};
  },
  computed: {
    phoneNumFormat() {
      let returnValue = "";
      returnValue += this.emmaUserInfo.phoneNum.slice(0, 3);
      if (this.emmaUserInfo.phoneNum.length > 3) {
        returnValue += "-";
        returnValue += this.emmaUserInfo.phoneNum.slice(3, -4);
      }
      if (this.emmaUserInfo.phoneNum.length > 7) {
        returnValue += "-";
        returnValue += this.emmaUserInfo.phoneNum.slice(-4);
      }
      return returnValue;
    },
    birthFormat() {
      let tempBirth = "19".concat(this.emmaUserInfo.birth); // 생년월일을 6자리로 format하기 위해 임의의 값 추가
      return moment(tempBirth).format("YY-MM-DD");
    },
    dateInfoFormat() {
      return moment(this.emmaUserInfo.dateInfo).format("YYYY-MM-DD hh:mm");
    },
  },
  mounted() {},
  methods: {
    next: function () {
      this.$emit("okBtn", {
        refId: null,
        refBirth: this.emmaUserInfo.birth,
        refPhone: this.emmaUserInfo.phoneNum,
      });
    },
    closePopup: function () {
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped>
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(246, 246, 246, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header h3 {
  margin-bottom: 20px;
  font-family: "NEXONLv1GothicB";
  color: #004c4e;
}
.modal-header p {
  font-size: 32px;
}
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}

.list_name {
  text-align: left;
}
.list_name dl {
  padding: 60px 0;
  border-bottom: 1px solid #cccccc;
}
.list_name dt {
  font-size: 36px;
}
.list_name dd {
  padding: 10px 0 0 60px;
  font-size: 36px;
  line-height: 1.4;
}
.list_name input[type="radio"] {
  display: none;
}
.list_name input[type="radio"] + label {
  background: url(../../../assets/img/ico_radio.png) no-repeat 0px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.list_name input[type="radio"]:checked + label {
  background: url(../../../assets/img/ico_radiochecked.png) no-repeat 0px;
  display: inline-block;
}
.list_name input[type="radio"] + label span {
  padding: 0 0 0 60px;
  width: 100%;
  line-height: 1.4;
  font-size: 36px;
  display: inline-block;
}
.modal_conts {
  padding: 50px 0;
  text-align: left;
  font-size: 36px;
  line-height: 1.6;
}
</style>