<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("문진확인증_작성") }}</h1>
        <h2
          v-html="$t('병원등록번호_또는_휴대전화번호_생년월일을_입력해주세요')"
        ></h2>
      </div>      
      <video v-if="video" id="vdoPlayer" :class="{'small-video': localCheck()}" 
        poster="../../assets/img/video_poster.png" style="margin: auto;" loop autoplay muted>
        <source src="../../assets/video/video.webm" type="video/webm">
      </video>
      <img v-else
        src="../../assets/img/video_poster.png"
        alt="동영상을 찾지 못했습니다">
      <div class="kiosk-area emmaKiosk">
        <div class="tab_area" >
          <div class="item pid-tap" @click="isPidTap=true" :class="{ active: isPidTap }">
            {{ $t("병원등록번호") }}
          </div>
          <div @click="isPidTap=false"
            class="item num-tap"
            :class="{ active: !isPidTap }"
            v-html="$t('환자의_휴대전화번호_생년월일_병원등록번호찾기')"
          ></div>
        </div>
        <KioskInputPatientId
          v-if="isPidTap"
          v-model="patientId"
        />
        <KioskInputPhoneBirth v-else v-model="phoneBirth" />
        <!-- <Terms ref="terms" v-model="isTermChecked" /> -->
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goSelectPopup"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
    <SelectPatientPopup
      v-if="selectStatus && isPidTap"
      :userList="userList"
      :byPid="isPidTap"
      @okBtn="goNext"
      @closePopup="closeSelectPop"
    />
    <SelectPatientPopup2
      v-else-if="selectStatus && !isPidTap"
      :userList="userList"
      :byPid="isPidTap"
      @okBtn="goNext"
      @closePopup="closeSelectPop"
    />
    <InsertErrorPopup v-if="errPopView" @closePopup="closeErrorPop" />
  </div>
</template>

<script>
import Header from "../../components/v2/Header";
import KioskInputPatientId from "../../components/v2/insertId/KioskInputPatientId.vue";
import KioskInputPhoneBirth from "../../components/v2/insertId/KioskInputPhoneBirth.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";
import InsertErrorPopup from "../../components/v2/modal/InsertErrorPopup.vue";
import SelectPatientPopup from "../../components/v2/modal/SelectPatientPopup.vue";
import SelectPatientPopup2 from "../../components/v2/modal/SelectPatientPopup2.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
// import moment from "moment";

export default {
  name: "EmmaPatient",
  components: {
    Header,
    KioskInputPatientId,
    KioskInputPhoneBirth,
    // Terms,
    InsertErrorPopup,
    SelectPatientPopup,
    SelectPatientPopup2,
  },
  props: {
    barcodeNo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isPidTap: true,
      patientId: "",
      phoneBirth: {
        phoneNum: "",
        birthday: "",
      },
      isTermChecked: true,
      btnLock: false,
      errPopView: false, // 에러화면 노출 여부
      selectStatus: false, // 본인 확인 팝업 노출 여부
      userList: [], // 본인 확인 팝업 내 사용자 리스트
      patientInfo: null, // pid 정보 조회 결과 저장용
      video: true, // 비디오 정상 여부 확인용
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getKioskId", "getLanguage"]),
    canNext: function () {
      if (this.isPidTap) {
        // 병원등록번호로 인증
        return (
          // 병원등록번호 정상 여부 확인
          this.patientId !== null &&
          this.patientId !== "" &&
          this.patientId.length > 7 &&
          // 약관 동의 여부확인
          (this.getUserType === this.USER.NOK ||
            this.getUserType === this.USER.ENOK ||
            this.isTermChecked)
        );
      } else {
        // 생년월일로 인증
        return (
          // 핸드폰 번호 입력값 확인
          this.phoneBirth.phoneNum !== null &&
          this.phoneBirth.phoneNum !== "" &&
          this.phoneBirth.phoneNum.length > 9 &&
          /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
            this.phoneBirth.phoneNum
          ) &&
          // 생년월일 입력값 확인
          this.phoneBirth.birthday !== null &&
          this.phoneBirth.birthday !== "" &&
          this.phoneBirth.birthday.length === 6 &&
          // 약관 동의 여부 확인
          this.isTermChecked
        );
      }
    },
  },
  async mounted() {
    await this.InquiryUseCheck();
    if (this.barcodeNo !== null) this.patientId = this.barcodeNo;
    // socket 리스너 등록
    this.$socket.connect(this.getKioskId);
    this.$socket.on("barcode", (barcodeNo) => (this.patientId = barcodeNo));

    let videoElem = document.getElementById("vdoPlayer");
    try {
      videoElem.play();
    } catch (err) {
      console.log(err);
      this.video = false;
    }
  },
  beforeDestroy() {
    this.$socket.off("barcode");
    this.$socket.disconnect();
  },
  methods: {
    ...mapMutations(["setEmmaPatientInfo"]),
    ...mapActions(['InquiryUseCheck']),
    switchTap() {
      this.isPidTap = !this.isPidTap;
    },
    goSelectPopup() {
      if (!this.canNext || this.btnLock) return;
      if (this.isPidTap) this.apiCheckPatient();
      else this.apiIdentityPhonebirth();
    },
    // 환자 번호가 존재하는지 체크 후 팝업 노출 Method
    async apiCheckPatient() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/check-patient",
          {
            identityNo: this.patientId,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 300:
            // 존재하지 않는 PatientId
            this.$modal.error(
              `${this.$t(
                "병원등록번호가_확인되지_않습니다"
              )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
            );
            break;
          case 0:
            // 정상 조회 완료 (팝업노출)
            this.userList = [
              {
                patNum: response.data.result.patNo,
                ptName: response.data.result.patNm,
                birth: response.data.result.patBirth,
              },
            ];
            this.selectStatus = true;
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async apiIdentityPhonebirth() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/phone-birth",
          {
            phoneNum: this.phoneBirth.phoneNum,
            birth: this.phoneBirth.birthday,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 0:
          case 303:
            // 0 : 생년월일+전화번호로 조회된 환자 1명
            // 303 : 생년월일로 조회된 환자 여러명
            this.userList = response.data.result.map(
              ({ patNum, ptName, birth }) => ({ patNum, ptName, birth })
            );
            this.selectStatus = true;
            break;
          case 300:
            // 300 : 생년월일+전화번호로 조회된 환자없음
            this.$modal.error(
              `${this.$t(
                "병원등록번호가_확인되지_않습니다"
              )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
            );
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async goNext(patientId) {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        // case: EMMA, 구환
        const response = await this.$axios.post("/api/v1/identity/emma", {
          answerType: this.USER.EMMA,
          identityNo: patientId,
          newPtYn: "N",
        });
        // if (!response.data.success) throw new Error("error");

        // 마지막페이지에서 동반보호자 문진 재작성을 위한 정보 저장
        this.setEmmaPatientInfo({
          refId: patientId,
          refBirth: null,
          refPhone: null,
        });

        // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
        switch (response.data.resultCd) {
          case 201:
          case 200:
            // 201: 기완료(증상있음)
            // 200: 기완료(증상없음)
            // 응급의 경우 항상 재작성이기 때문에 200/201이 오는 케이스 없어야 함
            throw new Error("응급 케이스는 기작성 Case 없음");
          case 0:
            this.$router.push({
              name: "infoAgree", //inquiryView
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: "N",
                identityBirth: null,
                identityPhone: null,
                refPhone: null,
                refBirth: null,
                refId: null,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 ResultCode 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    closeErrorPop() {
      this.errPopView = false;
      this.selectStatus = false;
      this.userList = [];
      this.patientId = "";
      this.phoneBirth = {
        phoneNum: "",
        birthday: "",
      };
    },
    closeSelectPop() {
      this.selectStatus = false;
    },
    localCheck(){ // 다국어 길이별 CSS 적용을 위한 method
      if(this.getLanguage === "en" || this.getLanguage === "ru"|| this.getLanguage === "vi" || this.getLanguage === "ar" || this.getLanguage === "mn"){
        return true
      } 
      return false
    }
  },
  watch: {
    isPidTap: function () {
      this.patientId = "";
      this.phoneBirth = {
        phoneNum: "",
        birthday: "",
      };
    },
  },
};
</script>

<style scoped>
.kiosk h1:lang(en),
.kiosk h1:lang(vi),
.kiosk h1:lang(ar)
{font-size:60px}
.kiosk h1:lang(ru),
.kiosk h1:lang(mn)
{font-size:50px}
.kiosk h2:lang(ru),
.kiosk h2:lang(mn)
{font-size:30px}
.kiosk-area {
    height: auto;
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 30px;
}
.tab_area {
    margin: -20px -40px 0 -40px;
}
.tab_area .item {
  color: rgb(100, 100, 100);
  justify-content: center;
}
.tab_area  .pid-tap{
  background: var(--background-orange);
  border-radius: 20px 20px 0  0 ;
}
.tab_area  .num-tap{
  background: var(--background-green);
  border-radius: 20px 20px 0  0 ;
}


/* ============== */
video {
  width: 90%;
}
.kiost-top {
  padding-top: 40px;
}
.kiost-top h1 { 
    font-size: 70px;
    color: #004c4e;
    font-family: "NEXONLv1GothicB", sans-serif;
    word-break:keep-all;
    line-height: 1.2;
}
.kiost-top h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 1.4;
  word-break:keep-all;
}
.small-video {
  width: 80%;
}
img{
  width: 90%;
  margin: 0 5%;
}
</style>
<style>
/* 민감정보 하단 동의 삭제 이후 키오스크 키보드 세로로 늘림 */
/* .emmaKiosk .ptnok-input-container, .emmaKiosk .input-container{
  height: 720px!important;
}
.emmaKiosk .kiosk01 .kiosk-pt-nok ul {
    margin: 90px auto 0!important;
}
.emmaKiosk .kiosk01 .kiosk-pt-nok ul li{
    height: 80px!important;
    margin-bottom: 30px!important;
} */

.emmaKiosk #emma-kiosk-input{
  padding-top:20px
}
</style>