<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("출입_등록") }}</h1>
        <h2>{{ $t("본인의_휴대전화_번호를_입력해주세요") }}</h2>
      </div>
      <div class="kiosk-area">
        <KioskInputPhone v-model="phoneNum"  class="long_numpad" />
        <!-- 수집 동의 -->
        <!-- <Terms class="terms-area" ref="terms" v-model="isTermChecked" /> -->
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="openVisitListPop"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
    <SelectVisitListPopup
      v-if="visitListPopView"
      :meetingList="gstList"
      @closePopup="closeVisitListPop"
      @goNext="goNext"
      @goNextEtc="goNextEtc"
    />
    <InsertErrorPopup v-if="errPopView" @closePopup="closeErrorPop" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Header from "../../components/v2/Header";
import KioskInputPhone from "../../components/v2/insertId/KioskInputPhone.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";
import InsertErrorPopup from "../../components/v2/modal/InsertErrorPopup.vue";
import SelectVisitListPopup from "../../components/v2/modal/SelectVisitListPopup.vue";
import moment from "moment";

export default {
  name: "Visiter",
  components: {
    Header,
    KioskInputPhone,
    // Terms,
    InsertErrorPopup,
    SelectVisitListPopup,
  },
  props: {
    patientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      phoneNum: "",
      isTermChecked: true,
      btnLock: false,
      errPopView: false, // 에러화면 노출 여부
      visitListPopView: false, // 행사 선택 화면 노출 여부
      gstList: [], // 집합 행사 리스트
    };
  },
  computed: {
    ...mapState(["USER"]),
   
    canNext: function () {
      // 병원등록번호로 인증
      return (
        // 핸드폰 번호 입력값 확인
        this.phoneNum !== null &&
        this.phoneNum !== "" &&
        this.phoneNum.length > 9 &&
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
          this.phoneNum
        ) &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async mounted() {
    await this.getGstList();
  },
  methods: {
    ...mapMutations(["setUserType"]),

    async getGstList() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v2/identity/gst-list", {
          openYn: "Y", // 현장문진은 초대코드가 있는 집합행사만 서비스 제공함
        });
        // if (!response.data.success) throw new Error("Error");
        this.gstList = response.data.result;
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async goNextEtc() {
      this.closeVisitListPop();

      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;
      try {
      this.$modal.loading(true);
        const response = await this.$axios.post("/api/check/v3/vcheck", {
          identityNo: this.phoneNum,
          answerType: this.USER.TMP,
          visitDate: moment().format("YYYYMMDD"),
        });
        // if (!response.data.success) throw new Error("Error");

        if(response.data.result.answerType == "WGST") {
          this.setUserType(this.USER.WGST);
        }

        switch (response.data.resultCd) {
          case 301:
            // 방문일에 예약한 환자가 없습니다.
            this.$modal.error(
              `${this.$t(
                "방문_신청_내용이_확인되지_않습니다"
              )}<br /><br />${this.$t("방문담당부서에_문의해_주세요")}`
            );
            break;
          case 201:
            // 증상있음 출입 금지.
            this.$router.push({
              name: "forbiden",
              params: { passCheck: response.data.result.passCheck },
            });
            break;
          case 200:
            //다른 타입의 기문진이 있을경우 방문신청이 확인되지 않는 다는 내용으로 넘긴다.
            if(response.data.result.answerType === 'NOK') {
                 this.$modal.error(
                  `${this.$t(
                    "방문_신청_내용이_확인되지_않습니다"
                  )}<br /><br />${this.$t("방문담당부서에_문의해_주세요")}`
                );
                break;
            }    

            // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.push({
              name: "passWithPrint",
              params: { passCheck: response.data.result.passCheck },
            });
            break;
          case 0:
           this.$router.push({
              name: "infoAgree", //inquiryView
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: "N",
                identityBirth: null,
                identityPhone: null,
                refPhone: null,
                refBirth: null,
                refId: null,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    goNext(meetingItem) {
      // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      this.$router.push({
        name: "code",
        params: {
          meetingItem: meetingItem,
          phoneNum: this.phoneNum,
          privacyCode:null,
          sensitiveCode:null,
        },
      });
    },
    closeErrorPop() {
      this.errPopView = false;
      this.phoneNum = "";
    },
    closeVisitListPop() {
      this.visitListPopView = false;
    },
    openVisitListPop() {
      if (!this.canNext || this.btnLock) return;
      this.visitListPopView = true;
    },

  },
};
</script>

<style scoped>
.kiosk-area {
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 120px;
}
</style>