import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

const WebSocket = {
  install(Vue) {
    const WebsocketEventBus = new Vue();
    const serverURL = "/ws/kiosk-ws";
    let _socket = null;
    let stompClient = null;
    let subscribeClient = null;

    const socket = {
      connect(kioskId) {
        _socket = new SockJS(serverURL);
        stompClient = new Stomp.over(_socket, { debug: false });
        stompClient.connect(
          {},
          () => {
            subscribeClient = stompClient.subscribe(
              `/ws/sub/kiosk/${kioskId}`,
              (res) => {
                const _res = JSON.parse(res.body);
                // barcode 정보가 PatientId 형태인지 확인
                if (
                  _res.barcodeNo !== null &&
                  _res.barcodeNo !== "" &&
                  _res.barcodeNo.length >= 8
                ) {
                  const slicedBarcode = _res.barcodeNo.slice(0, 8);
                  /^[0-9]+$/.test(slicedBarcode) &&
                    WebsocketEventBus.$emit("barcode", slicedBarcode);
                }
              }
            );
          },
          (error) => {
            // 소켓 연결 실패
            console.log("Socket Connect Fail");
            console.log(error);
          }
        );
      },
      disconnect() {
        subscribeClient?.unsubscribe({});
        stompClient?.disconnect();
      },
      on: WebsocketEventBus.$on.bind(WebsocketEventBus),
      off: WebsocketEventBus.$off.bind(WebsocketEventBus),
    };

    Vue.prototype.$socket = socket;
  },
};

export default WebSocket;
