<template>
  <div class="wrap">
    <!-- 메인 -->
    <section class="kiosk main">
      <!-- 상단 버튼 -->
      <Header />

      <!-- kiosk header -->
      <div class="kiosk-top">
        <h1
          v-html="$t('저희_병원에서_진료나_검사를_받으신_적이_있으신가요')"
        ></h1>
      </div>
      <!-- 버튼 영역 -->
      <div class="btn_yes" @click="goPatient">
        <h3>{{ $t("네") }}</h3>
        <div class="yes_go">{{ $t("병원등록번호로_작성") }}</div>
      </div>
      <div class="btn_no" @click="goNewPatient">
        <h3>{{ $t("아니오") }}</h3>
        <div class="no_go">{{ $t("휴대전화번호로_작성") }}</div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../../components/v2/Header";
export default {
  name: "EmmaPatientInter",
  components: {
    Header,
  },
  methods: {
    goNewPatient() {
      this.$router.push({ name: "emmaNewPatient" });
    },
    goPatient() {
      this.$router.push({ name: "emmaPatient" });
    },
  },
};
</script>

<style scoped>
/* ----- 메인 ----- */
:lang(ar) .btn_yes{
  padding-right:120px;
}
:lang(ar) .yes_go{
  right:auto;
  left:6%;
}
:lang(ar) .btn_no{
  padding-right:120px;
}
:lang(ar) .btn_no .no_go{
  right:auto;
  left:6%;
}
:lang(ru) .yes_go{
  text-align: right;
  line-height:1.2;
}
:lang(ru) .kiosk h1{font-size:60px;}

.kiosk {
  max-width: 850px;
  width: 100%;
  height: 1920px;
  margin: auto;
  padding: 58px 0 55px;
  position: relative;
}
.kiosk-top {
  margin-top: 200px;
}

.kiosk-top h1 {
  font-family: "NEXONLv1GothicB";
  font-size: 64px;
  color: #004c4e;
  line-height: 1.2;
}


.btn_yes {
  position: absolute;
  top: 700px;
  left: 10px;
  width: 820px;
  height: 450px;
  margin: 0;
  padding: 150px 0px 88px 120px;
  border-radius: 20px;
  box-shadow: 0 3px 60px 0 rgb(168 168 168 / 66%);
  color: #fff;
  background: #006769;
}
.btn_no {
  position: absolute;
  top: 1200px;
  left: 10px;
  width: 820px;
  height: 370px;
  margin: 0;
  padding: 130px 153px 103px 120px;
  border-radius: 20px;
  box-shadow: 0 10px 40px 0 rgb(135 135 135 / 25%);
  color: #006769;
  background: #ffffff;
}
.btn_yes h3 {
  font-size: 130px;
  font-family: "NEXONLv1GothicB";
}
.btn_no h3 {
  font-size: 100px;
  font-family: "NEXONLv1GothicB";
}

.yes_go {
  position: absolute;
  right: 6%;
  bottom: 10%;
  padding-right: 90px;
  height: 70px;
  line-height: 70px;
  font-size: 30px;
  font-family: "NEXONLv1GothicL";
  background: url(../../assets/img/btn_yes_go.png) no-repeat right center;
  display: inline-block;
}
.btn_no .no_go {
  position: absolute;
  right: 6%;
  bottom: 10%;
  padding-right: 90px;
  height: 70px;
  line-height: 70px;
  font-size: 30px;
  font-family: "NEXONLv1GothicL";
  background: url(../../assets/img/btn_no_go.png) no-repeat right center;
  display: inline-block;
}
</style>