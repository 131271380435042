<template>
  <div class="modal">
    <!--개인정보 수집-->
    <div class="bg">
      <div class="btn js-close-modal" @click="closeModal">{{ $t("닫기") }}</div>
      <div class="header">{{ modalTitle }}</div>
      <div class="body" v-html="modalText" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsPopup",
  props: {
    modalTitle: String,
    modalText: String,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.modal {
  background-color: rgba(17, 17, 17, 0.8);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  top: 0;
  position: fixed;
  left: 0;
  text-align: left;
  vertical-align: bottom;
  font-size: 36px;
}
.modal .bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  background: #fff;
  border-radius: 60px 60px 0 0;
  
}
.modal .header {
  max-width: 70%;
  padding: 80px 60px 60px 60px;
  font: 600 38px "NEXONLv1GothicB";
  position: relative;
  display: inline-block;
}
.modal .body {
  padding: 0 60px 60px 60px;
  font-size: 34px;
  height: calc(100% - 250px);
  overflow-y: scroll;
  line-height: 1.6;
}
.modal .js-close-modal {
  position: absolute;
  right: 60px;
  top: 50px;
  padding: 0px 20px 20px 60px;
  height: 60px;
  line-height: 60px;
  background: url(../../../assets/img/ico_close.png) no-repeat 20px #004c4e;
  border-radius: 60px;
  display: inline-block;
  color: #fff;
}
</style>