<template>
  <!-- 방문일 -->
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="modal-header">
        <h3>{{ $t("마지막_방문일을_선택하세요") }}</h3>
        <div class="txt_addr">
          {{ datePickerPopupInfo.datePickerPopupText }}
        </div>
      </div>
      <div class="modal-body">
        <div class="datapicker">
          <date-picker
            style="zoom: 2.6"
            ref="datePicker"
            v-model="selectDate"
            valueType="format"
            :inline="true"
            :disabled-date="disabledRange"
            :defaultValue="defaultDate"
            :lang="getLanguage"
          />
          <!-- <date-picker v-model="date" :config="options" style="width: 600px" /> -->
        </div>
      </div>
      <!-- 버튼 -->
      <div class="btn-area type2">
        <a
          href="javascript:void(0)"
          class="btn-default btn-white"
          @click="onClosePopup"
        >
          {{ $t("취소") }}
        </a>
        <a
          href="javascript:void(0)"
          :class="{ disabled: !selectDate }"
          class="btn-default"
          @click="onClick"
          >{{ $t("다음") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import ko from "date-format-parse/lib/locale/ko";
import "vue2-datepicker/locale/ko";
import "vue2-datepicker/locale/en";
import "../../../assets/css/datepicker.scss";
import { mapGetters } from "vuex";

DatePicker.locale("ko", {
  formatLocale: ko,
  yearFormat: "YYYY년",
  monthFormat: "MM월",
  monthBeforeYear: false,
});

export default {
  name: "DatePickerPopup",
  components: {
    DatePicker,
  },
  props: {
    datePickerPopupText: String,
    datePickerPopupInfo: Object,
  },
  data() {
    return {
      selectDate: null,
      defaultDate: null,
    };
  },
  computed: {
    ...mapGetters(["getLanguage"]),
  },
  mounted() {
    this.selectDate = this.datePickerPopupInfo.placeDate;
    const boundaryDates = this.datePickerPopupInfo.boundaryDateSplit.split("|");
    this.defaultDate = moment(boundaryDates[boundaryDates.length - 1]).toDate();
  },
  methods: {
    onClosePopup() {
      this.$emit("closeDatePickerPopup", this.datePickerPopupInfo.placeDate);
    },
    onClick() {
      if (!this.selectDate) return;
      this.$emit("closeDatePickerPopup", this.selectDate);
    },
    disabledRange(date) {
      const target = moment(date).format("YYYY-MM-DD");
      return !this.datePickerPopupInfo.boundaryDateSplit
        .split("|")
        .includes(target);
    },
  },
};
</script>

<style scoped>
.datapicker {
  display: block;
  width: 100%;
  height: 780px;
}

/* ----- modal ----- */
.modal_height80 {
  width: 100%;
  height: 72%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header .txt_addr {
  margin-top: 30px;
  font-size: 36px;
}
/* .modal-prop {
    /* margin:0 -90px 60px -90px; 
} */
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop .title {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #333333;
}
.modal-prop .title.active {
  margin-top: 20px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border-radius: 10px;
  color: #004c4e;
  background: #f6f6f6;
}
.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}
</style>