<template>
  <!-- modal 방문목적 (방문객)-->
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="modal-header">{{ $t("방문하신_목적을_선택하세요") }}</div>
      <div class="modal-body">
        <div class="modal-prop">
          <div
            class="title"
            :class="{ active: titleSelectFlag }"
            @click="onClickSelect($event, 'meeting')"
          >
            {{ $t("집합_행사") }}
          </div>
          <div
            v-if="titleSelectFlag && filterMeetingList.length > 0"
            class="visit_list"
            style="overflow-y: auto; height:500px"
          >
            <li
              v-for="item in filterMeetingList"
              :class="{
                active:
                  selectedMeeting &&
                  selectedMeeting.meetingId === item.meetingId,
              }"
              ref="el_li"
              :key="item.meetingId"
              @click="onSelectItem($event, item)"
            >
              {{ item.meetingName }}
              <div class="ico_info" @click="infoIconPopup(item)" />
            </li>
          </div>
          <div v-else-if="titleSelectFlag">
            <li>{{ $t("금일_예정된_집합_행사가_없습니다") }}</li>
          </div>
          <div
            v-if="titleSelectFlag !== true"
            class="title_etc"
            :class="{ active: titleSelectFlag === false }"
            @click="onClickSelect($event, 'etc')"
          >
            {{ $t("기타_방문") }}
          </div>
        </div>

        <!-- 버튼 -->
        <div class="btn-area type2">
          <a
            href="javascript:void(0)"
            class="btn-default btn-white"
            @click="closePopup"
            >{{ titleSelectFlag === true ? $t("이전") : $t("취소") }}</a
          >
          <a
            href="javascript:void(0)"
            :class="{ disabled: !canNext }"
            :disabled="!canNext"
            class="btn-default"
            @click="goNext"
            >{{ $t("다음") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SelectVisitListPopup",
  props: {
    meetingList: Array,
  },
  data() {
    return {
      selectedMeeting: null,
      titleSelectFlag: null, // default null, 집합행사 선택 시 true, 기타방문 선택 시 false
      // isSelectFlag: Boolean,
    };
  },
  computed: {
    canNext() {
      // 기타방문을 선택했거나, 집합행사 중 하나를 골랐거나
      return this.selectedMeeting !== null || this.titleSelectFlag === false;
    },
    filterMeetingList() {
      // return [];
      if (!this.meetingList) return [];
      return this.meetingList
        .filter(
          (meet) =>
            moment(meet.startDate) < moment() && moment() < moment(meet.endDate)
        ) // 행사 노출 시간이 금일인지 확인
        .filter((meet) => meet.openYn) // 초대코드가 있는 행사
        // .filter((meet) => meet.inviteCode.length === 4); // 확인가능한 4자리 초대코드가 등록된 행사
    },
  },
  methods: {
    infoIconPopup(item) {
      this.$modal.infoWordBreak(this.$t("행사명"), item.meetingName, true);
    },
    updateInfoIcon() {
      // info 아이콘 추가
      this.$nextTick(() => {
        this.$refs.el_li.forEach((element) => {
          if (this.isTruncated(element)) element.classList.add("long_name");
        });
      });
    },
    isTruncated(el) {
      return el.scrollWidth > el.clientWidth;
    },
    goNext() {
      if (!this.canNext) return;
      if (this.titleSelectFlag) {
        // 집합행사 선택
        this.$emit("goNext", this.selectedMeeting);
      } else {
        // 기타방문 선택
        this.$emit("goNextEtc");
      }
    },
    onClickSelect(_e, type) {
      this.titleSelectFlag = type === "meeting";
      if (
        type === "meeting" &&
        !this.selectedMeeting &&
        this.filterMeetingList?.length > 0
      ) {
        this.selectedMeeting = this.filterMeetingList[0];
        this.updateInfoIcon();
      }
    },
    onSelectItem(_e, item) {
      this.selectedMeeting = item;
      this.updateInfoIcon();
    },
    closePopup() {
      if (this.titleSelectFlag === true) {
        this.titleSelectFlag = null;
        this.selectedMeeting = null;
      } else {
        this.$emit("closePopup");
      }
    },
  },
};
</script>

<style scoped>
/* ----- modal ----- */
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(246, 246, 246, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop .title_etc,
.modal-prop .title {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #cccccc;
  border-radius: 10px;
  color: #333333;
}
.modal-prop .title.active {
  margin-top: 20px;
  padding: 10px 10px 10px 0;
  font-size: 36px;
  text-align: left;
  border-radius: 10px;
  line-height: 1.4;
  color: #004c4e;
  border: none;
}
.modal-prop .title_etc.active {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #004c4e;
  color: #004c4e;
  font-weight: bold;
  border-radius: 10px;
}
.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}

.modal-prop .visit_list li.active {
  border: 1px solid #004c4e;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  display: block;
  padding: 0 0;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}
.visit_list li {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  position: relative;
  border-radius: 10px;
  margin-bottom:20px;
}
.visit_list li .ico_info {
  display: none;
}
.visit_list li.long_name {
  padding-right: 80px;
  
}
.visit_list li.long_name .ico_info {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -20px;
  display: block;
  width: 40px;
  height: 40px;
  background: url(../../../assets/img/ico_info.png) 95% no-repeat;
}
.modal-body .modal_conts{
  word-break: normal;
}
</style>