import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../view/Home.vue";
import InsertId from "../view/InsertID/InsertId.vue";
import Patient from "../view/InsertID/Patient.vue";
import Phone from "../view/InsertID/Phone.vue";
import Visiter from "../view/InsertID/Visiter.vue";
import Code from "../view/InsertID/Code.vue";
import Employee from "../view/InsertID/Employee.vue";
import InfoAgree from "../view/InquiryView/InfoAgree.vue"
import Inquiry from "../view/InquiryView/Inquiry.vue";
import HomeMateInquiry from "../view/InquiryView/HomeMateInquiry.vue";
import Forbiden from "../view/infoView/Forbiden.vue";
import PassWithoutPrint from "../view/infoView/PassWithoutPrint.vue";
import PassWithPrint from "../view/infoView/PassWithPrint.vue";
import PrintComplete from "../view/infoView/PrintComplete.vue";


// 응급용키오스크 화면
import EmmaHome from "../view/EmmaHome.vue";
import EmmaPatientInter from "../view/Emma/EmmaPatientInter.vue";
import EmmaPatient from "../view/Emma/EmmaPatient.vue";
import EmmaNewPatient from "../view/Emma/EmmaNewPatient.vue";
import EmmaPhone from "../view/Emma/EmmaPhone.vue";
import EmmaPass from "../view/Emma/EmmaPass.vue";
import EmmaAnnounce from "../view/Emma/EmmaAnnounce.vue"

// 미리보기
import Preview from "../view/Preview.vue";


Vue.use(VueRouter);

export const router = new VueRouter({
  // mode: 'history',
  routes: [
    // 일반 키오스크 홈화면
    {
      name: "home",
      path: "/kiosk/:kioskId",
      component: Home,
    },
    // 고유번호 입력
    {
      path: "/insertId",
      name: "insertId",
      component: InsertId,
      children: [
        {
          path: "patient",
          name: "patient",
          component: Patient,
          props: true,
        },
        {
          path: "guardian",
          name: "guardian",
          component: Patient,
        },
        {
          path: "phone",
          name: "phone",
          component: Phone,
          props: true,
        },
        {
          path: "visiter",
          name: "visiter",
          component: Visiter,
        },
        {
          path: "icuGuest",
          name: "icuGuest",
          component: Patient,
        },
        {
          path: "code",
          name: "code",
          component: Code,
          props: true,
        },
        {
          path: "employee",
          name: "employee",
          component: Employee,
        },
      ],
    },
    // 문진 작성
    {
      path: "/inquiry_view",
      name: "inquiryView",
      component: Inquiry,
      props: true,
    },
    {
      name: "infoAgree",
      path: "/info-agree",
      component: InfoAgree,
      props: true,
    }, 
    // 동거인 문진 작성
    {
      path: "/homeMateInquiry",
      name: "homeMateInquiry",
      component: HomeMateInquiry,
      props: true,
    },
    // 출입금지
    {
      path: "/forbiden",
      name: "forbiden",
      component: Forbiden,
      props: true,
    },
    // 내부직원 용 완료 페이지
    {
      path: "/passWithoutPrint",
      name: "passWithoutPrint",
      component: PassWithoutPrint,
    },
    {
      name: "passWithPrint",
      path: "/pass",
      component: PassWithPrint,
      props: true,
    },
    {
      name: "printComplete",
      path: "/printComplete",
      component: PrintComplete,
      props: true,
    },

    // 응급용 키오스크 화면

    // 홈화면
    {
      name: "emmahome",
      path: "/emmakiosk/:kioskId",
      component: EmmaHome,
    },
    {
      name: "emmaPatientInter",
      path: "/ept-inter",
      component: EmmaPatientInter,
    },
    {
      name: "emmaPatient",
      path: "/emma-patient",
      component: EmmaPatient,
      props: true,
    },
    {
      name: "emmaNewPatient",
      path: "/emma-new-patient",
      component: EmmaNewPatient,
    },
    {
      name: "emmaPhone",
      path: "/emma-phone",
      component: EmmaPhone,
      props: true,
    },
    {
      name: "emmaPass",
      path: "/emma-pass",
      component: EmmaPass,
      props: true,
    },

    {
      name: "emmaAnnounce",
      path: "/emma-announce",
      component: EmmaAnnounce,
      props: true,
    }, 
    // 미리보기 진입용 화면
    {
      name: "preview",
      path: "/preview/:refreshIdx",
      component: Preview,
    },
  ],
});
