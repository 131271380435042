import moment from "moment";
import i18n from "../i18n";

const state = {
  kioskId: "",
  kioskType: null,
  isEmmaKiosk: false,
  visitHomeTimestamp: "",
};

const getters = {
  getKioskId: (state) => state.kioskId,
  getKioskType: (state) => state.kioskType,
  getIsEmmaKiosk: (state) => state.isEmmaKiosk,
  getVisitHomeTimestamp: (state) => state.visitHomeTimestamp,
  isDummyKiosk: (state) =>
    state.kioskId === "preview" || state.kioskId === "dummy",
};

const mutations = {
  setKioskId(state, payload) {
    state.kioskId = payload;
  },
  setEmmaKiosk(state, payload) {
    state.isEmmaKiosk = payload;
  },
  setVisitHomeTimestamp(state) {
    state.visitHomeTimestamp = moment().format();
  },
  setKioskType(state, payload) {
    state.kioskType = payload;
  },
};

const actions = {
  async setKioskInfo({ commit }, kioskId) {
    try {
      // eslint-disable-next-line no-underscore-dangle
      commit("setKioskId", kioskId);
      if (kioskId === "preview" || kioskId === "dummy") return;
      const response = await this._vm.$request.api_v1_kiosk_type({ kioskId });
      switch (response.data.resultCd) {
        case 102: // 조회 결과 없음
          this._vm.$modal.error(i18n.t("키오스크_정보_확인에_실패하였습니다"));
          commit("setKioskType", null);
          break;
        case 0: // 정상
          commit("setKioskType", response.data.result.kioskType);
          break;
        default:
          break;
      }
    } catch (err) {
      this._vm.$modal.error();
    }
  },
};

export const kioskInfo = {
  state: {
    ...state,
  },
  getters,
  mutations,
  actions,
};
