<template>
  <div></div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Preview",
  data() {
    return {
      inquiryType: null,
      categoryList: [],
    };
  },
  computed: {
    ...mapState(["USER", "InqType"]),
    ...mapGetters(["getUserType", "getLanguage"]),
    refreshIdx() {
      return this.$route.params.refreshIdx;
    },
  },
  mounted() {
    this.setKioskId("preview");
    this.getCategoryInfoFromAdmin();
  },
  methods: {
    ...mapMutations(["setUserType", "setLanguage", "setKioskId"]),
    getCategoryInfoFromAdmin() {
      // 미리보기 팝업 시 호출주체로부터 데이터 입력받음
      const eventListenerFtn = async (e) => {
        if (e.data.method === "needCategoryData") {
          this.$modal.loading(false);
          this.inquiryType = e.data.inquiryType;
          this.$i18n.locale = e.data.language;
          this.setLanguage(this.$i18n);
          const placeList = await this.getPlaceHistoryInfo();
          e.data.categoryList.forEach((item) => {
            if (item.categoryType === this.InqType.VisitHistory) {
              // 방문력은 최신데이터로 업데이트
              item.placeList = placeList;
            }
          });
          this.categoryList = e.data.categoryList;
          e.data.inquiryType === this.USER.EMP &&
            this.setUserType(this.USER.EMP); // EMP 의 경우 동거문진 표시를 위한 setUserType 필요
          window.removeEventListener("message", eventListenerFtn);

          // 문진작성으로 이동
          this.$router.replace({
            name: "inquiryView",
            params: {
              identityNo: this.identityNo,
              inquiryType: this.inquiryType,
              preViewCategoryList: this.categoryList,
            },
          });
        }
      };
      window.addEventListener("message", eventListenerFtn);
      this.$modal.loading(true);
      window.opener.postMessage(
        { method: "needCategoryData", refreshIdx: this.refreshIdx },
        "*"
      );
    },
    async getPlaceHistoryInfo() {
      try {
        const response = await this.$request.api_v1_inquiry({
          answerType: this.inquiryType,
          language: this.getLanguage,
        });
        return response.data.result.categoryList.filter(
          (item) => item.categoryType === this.InqType.VisitHistory
        )[0].placeList;
      } catch {
        this.$modal.error();
      }
    },
  },
  watch: {
    refreshIdx: function () {
      this.$router.go();
    },
  },
};
</script>
