<template>
  <div class="wrap">
    <section class="kiosk kiosk01">
      <Header />
      <div class="kiost-top">
        <h1>{{ $t("약관_동의") }}</h1>
        <h2 v-if="getInquiryYn" v-html="$t('병원은_중증질환_고난이도_감염관리의_전문성_강화_및_환자_증상_모니터링을_위해_이용자의_개인정보,_민감정보를_수집·이용합니다')"></h2>
        <h2 v-else v-html="$t('병원은_중증질환_고난이도_감염관리의_전문성_강화_및_환자_증상_모니터링을_위해_이용자의_개인정보를_수집·이용합니다.')"></h2>
      </div>
      <div id="kiosk-area" class="kiosk-area agree-area">
        <AgreeTerms ref="terms" @input="checkNext" />
        <h2 v-if="getInquiryYn"
        v-html="$t('개인정보,_민감정보_수집·이용에_대한_동의를_거부할_권리가_있으며,_동의를_거부할_경우_병원_출입이_불가할_수_있습니다')">
        </h2>

        <div class="btn-area" :class="{ disabled: !canNext }" @click="goNext">
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>

    </section>
  </div>

</template>
<script>
  import Header from "../../components/v2/Header";
  import AgreeTerms from "../../components/v2/insertId/AgreeTerms.vue";
  import {
    mapGetters,
    mapState,
    mapActions
  } from "vuex";

  export default {

    name: 'infoAgree',
    components: {
      Header,
      AgreeTerms,
    },

    computed: {
      ...mapState(["USER"]),
      ...mapGetters(["getUserType", "getKioskId", 'getLanguage', 'getKioskType', 'getInquiryYn']),
    },
    data() {
      return {
        canNext: false,
        inquiryType: null,
        identityNo: null,
        visitDate:null,
        vistCnt: this.$route.params.vistCnt,
        params: null,
      }
    },
    beforeRouteEnter (to, from, next) {
      if(to.name == 'infoAgree' && from.name=='inquiryView') {
        next(vm => {
          vm.params = from.params
        });
      } else {
        return next()
      }
    },
    beforeRouteLeave(to, from, next){
      let params = from.params.identityNo ? from.params : this.params
      if (to.name === 'phone' && from.name === 'infoAgree' && !to.params.identityNo) {
        return this.$router.push({ name: to.name, params: params }); 
      } else next()
    },
    async mounted(){
      await this.InquiryUseCheck({
        inqType: this.$route.params.inquiryType || this.params.inquiryType, 
        ptno: this.$route.params.identityNo || this.params.identityNo, 
        vd: this.$route.params.visitDate ||this.params.visitDate,
      });
      // this.inquiryType = response.data.result.masterId;
    },
    methods: {
      ...mapActions(["InquiryUseCheck"]),
      checkNext(status) {
        this.canNext = status
      },
      async goNext() {
        const {
          privacyCode,
          sensitiveCode
        } = this.$refs.terms.getTermsCode();
        const params = this.$route.params.identityNo? this.$route.params : this.params
        params.privacyCode = privacyCode
        params.sensitiveCode = sensitiveCode

        if(this.getInquiryYn) {
          this.useInquiry(params);
        } else {
          await this.noInquiry(params);
        }

      
      },
      useInquiry(params) { // 문진 사용
        this.$router.push({
          name: "inquiryView",
          params: params,
          vistCnt: this.vistCnt,
          visitReason: this.$route.params.visitReason || null,
        });
      },
      async noInquiry(params) { // 문진 미사용
        console.log('문진미사용')
        try {
          this.$modal.loading(true);

          const response = await this.$axios.post('/api/pre/v1/no-answer', {
            refId:params.refId,
            identityNo: params.identityNo,
            deviceType: 'K',
            deviceId: this.getKioskId,
            answerType: params.answerType,
            visitDate: params.visitDate,
            completePlace: this.getLanguage === 'ko' || this.getLanguage === 'en',
            inqType: params.inquiryType,
            privacyCode: params.privacyCode,
            sensitiveCode: null,
            partnerEmpId: null,
            pid: null,
            kioskType: this.getKioskType,
            conn: null,
            vistCnt: this.vistCnt,
            visitReason: this.$route.params.visitReason || null,
          });

          switch (response.data.resultCd) {
            case 300:
            case 201:
              this.$router.replace({
                name: 'forbiden',
                query: {
                  pass: response.data.result.passCheck,
                },
              });
              break;

            case 0:
              // 출입증 발급
              this.$router.replace({
                name: 'passWithPrint',
                params: {
                  passCheck: response.data.result.passCheck
                },
              });
              break;
            default:
              throw new Error('정의되지 않은 resultCd 오류');
          }

        } catch (error) {
          console.log(error);
          this.$router.replace({
            name: 'forbiden',
            params: {
              titleText: this.$t('유효하지_않은_접근입니다'),
            },
          });
        } finally {
          this.$modal.loading(false);
        }
      },
    },
  };
</script>

<style scoped>
  .kiosk-area.agree-area {
    height: auto;
    background: none;
    box-shadow: none;
    overflow: hidden;
    position: static;
    padding-top: 70px;
  }

  .agree-area h2 {
    font-size: 36px;
    line-height: 1.4;
    word-break: keep-all;
    margin-top: 70px;
  }

  .disabled {
    pointer-events: none
  }
</style>