var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('section',{staticClass:"kiosk kiosk03",staticStyle:{"display":"block"}},[_vm._m(0),_c('div',{staticClass:"kiost-top"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('문진표를_재작성하시겠습니까'))}}),_c('h2',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            '문진표_재작성을_원하시는_경우_' +
              '아래_재작성_버튼을_눌러주세요_' +
              '저장된_문진_기록을_삭제하고_' +
              '문진표를_새로_작성하게_됩니다'
          )
        )}})]),_c('div',{staticClass:"kiosk_conts_bottom",domProps:{"innerHTML":_vm._s(
        _vm.$t(
          '문진표를_허위로_작성할_경우_보건_관련_법률_형법_등에_의해_처벌_' +
            '또는_과태료_1천만원_처분_대상이_될_수_있습니다'
        )
      )}}),_c('div',{staticClass:"btn-area footer"},[_c('a',{staticClass:"btn-default btn-white",attrs:{"href":"javascript:void(0)"},domProps:{"innerHTML":_vm._s(_vm.$t('취소'))},on:{"click":_vm.closePopup}}),_c('a',{staticClass:"btn-default",attrs:{"href":"javascript:void(0)"},domProps:{"innerHTML":_vm._s(_vm.$t('재작성'))},on:{"click":_vm.okBtn}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-top-area"},[_c('div'),_c('div')])
}]

export { render, staticRenderFns }