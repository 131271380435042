<template>
  <div v-if="isShow" class="modal" style="display: block">
    <div class="modal-pop">
      <div class="error">
        <img src="@/assets/img/ico_q.png" width="200px" />
        <div class="msg" v-html="errorMessage"></div>
        <!-- <div class="info_msg">
          환자 정보를 다시 확인해주세요.<br />
          환자가 먼저 문진을 완료한 이후에만<br />
          보호자가 문진을 진행할 수 있습니다.
        </div> -->
      </div>
      <!-- 버튼 -->
      <div class="btn-area type1">
        <a href="javascript:void(0);" class="btn-default" @click="close">{{
          $t("확인")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      errorMessage: "",
    };
  },
  methods: {
    close() {
      this.isShow = false;
    },
    show(message) {
      this.errorMessage =
        message || this.$t("유효하지_않은_접근입니다");
      this.isShow = true;
    },
  },
  beforeMount() {
    this.$modal.on("error", ({ message }) => this.show(message));
    this.$modal.on("hide", this.close);
  },
  beforeDestroy() {
    this.$modal.off("error");
    this.$modal.off("hide");
  },
};
</script>

<style scoped>
.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  font-size: 46px;
  text-align: left;
}
.modal_conts {
  padding: 50px 0;
  text-align: left;
  font-size: 36px;
  line-height: 1.6;
}

.modal-header h3 {
  margin-bottom: 20px;
  font-family: "NEXONLv1GothicB";
  color: #004c4e;
}
.modal-header p {
  font-size: 32px;
}
.error .msg {
  padding: 30px 0;
  font-size: 42px;
  line-height: 1.4;
}
.error .info_msg {
  padding: 40px 0;
  font-size: 38px;
  line-height: 1.3;
}
:lang(ja) .error .msg, :lang(ja) .error .info_msg  { 
  word-break: break-all;
}
</style>
