<template>
  <div class="wrap">
    <!-- 발급 완료 -->
    <section class="kiosk kiosk03" style="display: block">
      <!-- 상단 버튼 -->
      <Header ref="header" :activeBackBtn="false" />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1 v-html="$t('출입등록이_완료되었습니다')"></h1>
        <h2>{{ $t("출입증_발급_방법을_선택하세요") }}</h2>
      </div>
      <div class="btn-area">
        <a
          href="javascript:void(0)"
          class="btn_print_p"
          :class="{ disabled: !printReady }"
          @click="onPrint"
          >{{ $t("종이출입증_출력") }}</a
        >
        <a
          href="javascript:void(0)"
          class="btn_print_m"
          :class="{ disabled: !mobilePushReady }"
          @click="onMobile"
        >
          {{ $t("휴대전화로_전송") }}
          <div class="small">{{ phoneNumMasking }}</div></a
        >
      </div>
      <div class="btn-area footer" v-if="reInquiryYn">
        <a
          href="javascript:void(0)"
          class="btn-default btn-white"
          :class="{ disabled: !retryReady }"
          @click="retryInquiry"
        >
          {{ $t("문진_재작성") }}</a
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header.vue";
import moment from 'moment';
export default {
  name: "PassWithPrint",
  components: {
    Header,
  },
  props: {
    passCheck: String,
    refId: String,
  },
  data() {
    return {
      btnLock: false,
      answerType: null,
      identityNo: null,
      phoneNum: null,
      pubPassTime: null,
      printState: false,
      reprintState: false,
      oneTimePassCheck: true,
      reInquiryYn: false,
    };
  },
  computed: {
    ...mapState(["USER", "InqType"]),
    ...mapGetters(["getKioskId", "isDummyKiosk","getLanguage", "getInquiryYn"]),
    printReady() {
      return (
        !this.btnLock && this.getKioskId && this.passCheck && this.printState 
      );
    },
    mobilePushReady() {
      return (
        !this.btnLock &&
        this.answerType &&
        this.identityNo &&
        this.pubPassTime &&
        this.phoneNum
      );
    },
    retryReady() {
      return !this.btnLock && this.passCheck;
    },
    phoneNumMasking() {
      if (!this.phoneNum || this.phoneNum.length < 10) return "";
      return `(${this.phoneNum.slice(0, 3)}-${Array(
        this.phoneNum.length - 6
      ).join("*")}-${this.phoneNum.slice(-4)})`;
    },
  },
  async mounted() {
    await this.getPassCheckInfo();
    await this.checkPrinterStatus(false);
    // await this.checkreprintStatus(false);
  },
  methods: {
    async onPrint() {
      if (!this.printReady) {
        if (!this.printState) await this.checkPrinterStatus();
        return;
      }
      
      await this.checkreprintStatus();
      
      if(!this.reprintState){
        return;
      }

      if(this.answerType == 'ICUGST'){
        !await this.checkOneTimePassState()
        if (!this.oneTimePassCheck){
          console.log("ICUGST expired")
          return;
        }
      }

      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/site/v1/print", {
          kioskId: this.getKioskId,
          passCheck: this.passCheck,
          refId: this.refId,
          language: this.getLanguage
        });
        // if (!response.data.success) throw new Error("error");

        switch (response.data.resultCd) {
          case 0:
            // 종이 출력 완료 화면으로 이동
            this.$router.push({
              name: "printComplete",
              params: {
                answerType: this.answerType,
                identityNo: this.identityNo,
                pubPassTime: this.pubPassTime,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async onMobile() {
      if (!this.mobilePushReady) return;
      
      if(this.answerType == 'ICUGST'){
        !await this.checkOneTimePassState()
        if (!this.oneTimePassCheck){
          console.log("ICUGST expired")
          return;
        }
      }

      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/site/v1/send-pass-alim", {
          kioskId: this.getKioskId,
          passCheck: this.passCheck,
          answerType: this.answerType,
          phoneNum: this.phoneNum,
          refId: this.refId,
        });
        // if (!response.data.success) throw new Error("error");

        switch (response.data.resultCd) {
          case 0:
            // 출력 완료 화면으로 이동
            this.$router.push({
              name: "printComplete",
              params: {
                answerType: this.answerType,
                identityNo: this.identityNo,
                pubPassTime: this.pubPassTime,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async getPassCheckInfo() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/pass-check", {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 201:
            // 입장 불가 passCheck
            throw new Error("입장 불가 passCheck");
          case 0:
            // 정상
            this.answerType = response.data.result.answerType;
            this.identityNo = response.data.result.identityNo;
            this.pubPassTime = response.data.result.pubPassTime;
            if (response.data.result.answerType === this.USER.PT) {
              this.phoneNum = await this.apiCheckPatient(
                response.data.result.identityNo
              );
            } else {
              this.phoneNum = response.data.result.identityNo;
            }
            this.checkReInqiryYn();
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async checkPrinterStatus(withPopup = true) {
      if (this.isDummyKiosk) return;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get("/api/printer-state", {
          params: { deviceId: this.getKioskId },
        });
        if (response.data && response.data.printState !== undefined) {
          // -1: 끊김, 0: 정상, 1: 용지걸림, 2: 용지부족, 3: 용지없음
          this.printState =
            response.data.printState === 0 || response.data.printState === 2;

          switch (response.data.printState) {
            case -1:
              withPopup && this.showPrintStatePopup(this.$t("연결끊김"));
              break;
            case 1:
              withPopup && this.showPrintStatePopup(this.$t("용지걸림"));
              break;
            // case 2:
            //   withPopup && this.showPrintStatePopup(this.$t("용지부족"));
            //   break;
            case 3:
              withPopup && this.showPrintStatePopup(this.$t("용지없음"));
              break;
            default:
              break;
          }
        } else {
          throw new Error("error");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async checkreprintStatus(withPopup = true) {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get("/api/print/v1/pass-log", {
          params: { 
            identityNo: this.identityNo,
            visitDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            passType: "K"
          },
        });

        switch (response.data.resultCd) {
          case 0:
            this.reprintState = true;
            break;
          case 309:
            withPopup && this.showRePrintPopup();
            break;
          default:
            break;
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async checkOneTimePassState(withPopup = true) {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get("/api/print/v1/oneTime-passCheck", {
          params: { 
            passCheck: this.passCheck,
          },
        });

        switch (response.data.resultCd) {
          case 0:
            break;
          case 309:
            this.oneTimePassCheck = false;
            withPopup && this.showRePrintPopup();
            break;
          default:
            this.oneTimePassCheck = false;
            throw new Error("정의되지 않은 resultCd 오류");
            break;
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    showPrintStatePopup(info) {
      this.$modal.error(
        `${this.$t("종이출입증_출력이_불가능합니다")}<br />(${info})`
      );
    },
    showRePrintPopup() {
      this.$modal.error(
        `${this.$t('종이출입증_발급이_완료되어_재출력이_불가합니다')}`
      );
      this.goHome();
    },
    goHome() {
      // window.location.href = `${window.location.origin}/#/kiosk/${this.getKioskId}`;
      if (this.getKioskId === "preview") return;
      if (this.getIsEmmaKiosk) {
        this.$router.push({
          name: "emmahome",
          params: {
            kioskId: this.getKioskId,
          },
        });
      } else {
        this.$router.push({
          name: "home",
          params: {
            kioskId: this.getKioskId,
          },
        });
      }
    },
    async apiCheckPatient(identityNo) {
      const response = await this.$axios.post(
        "/api/v1/identity/check-patient",
        { identityNo }
      );
      // if (!response.data.success) throw new Error("error");
      switch (response.data.resultCd) {
        case 300:
          // 존재하지 않는 identityNo
          throw new Error("error");
        case 0:
          // 정상 조회 완료
          return response.data.result.mbphno;
        default:
          throw new Error("정의되지 않은 resultCd 오류");
      }
    },

    async checkReInqiryYn() {
      if (this.answerType == 'ICUGST') {
        this.reInquiryYn = false;
      } else {
        this.reInquiryYn = this.getInquiryYn ? true:false;
      }
    },

    async retryInquiry() {
      if (!this.retryReady) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/re-inquiry", {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error("error");

        switch (response.data.resultCd) {
          case 305:
            // 만료된 문진 데이터
            throw new Error("만료된 문진 데이터 오류");
          case 309:
            // throw new Error("원내 출입 중에는 재문진이 불가능합니다. 원외로 이동 후 재문진을 시작해 주세요.");
            this.$modal.info(
              this.$t("알림"),
              this.$t(
                "원내_출입_중에는_재문진이_불가능합니다_원외로_이동_후_재문진을_시작해_주세요"
              ).replace(/<br \/>/g, "")
            );
            break;
          case 0:
            // 문진작성으로 이동
            this.$router.push({
              name: "infoAgree",
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: response.data.result.privacyCode,
                sensitiveCode: response.data.result.sensitiveCode,
                refId: this.refId,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
  },
};
</script>

<style scoped>
/* ----- 출입 등록3 / 발급 완료 ----- */
.kiosk03 .btn-top-area {
  justify-content: flex-end;
}

.kiosk03 .kiost-top {
  padding-top: 190px;
  text-align: center;
}

.kiosk03 .kiost-top h1 {
  font-size: 100px;
  line-height: 1.3;
}

.kiosk03 .kiost-top h2 {
  font-size: 50px;
}

.kiosk03 .kiosk-area {
  width: 630px;
  margin: 90px auto 0;
  padding: 90px 0;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  border-radius: 25px;
}

.kiosk03 .kiosk-end {
  padding-top: 145px;
  text-align: center;
}
.kiosk03 .kiosk-end img {
  margin-bottom: 50px;
}
.kiosk03 .kiosk-end p {
  font-size: 40px;
  line-height: 1.4;
}

.kiost-staff {
  padding: 300px 0;
  font-family: "NEXONLv1GothicB";
  font-size: 70px;
  line-height: 1.4;
  color: #004c4e;
  text-align: center;
}

.kiosk03 .notice {
  padding-top: 75px;
  font-size: 40px;
  text-align: center;
  color: #006769;
}

.kiosk03 .btn-area {
  display: flex;
  margin-top: 60px;
}
.kiosk03 .btn-area a {
  font-size: 70px;
  line-height: 100px;
  flex: 1;
  margin: 0 10px;
}
.kiosk03 .btn-area.type1 {
  width: 100%;
}
.kiosk03 .btn-area .btn-blue {
  height: 300px;
}
.kiosk03 .btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.kiosk03 .btn-area .btn_print_p {
  height: auto;
  min-height: 480px;
  background: none;
  background-size: 70%;
  display: block;
  border: 3px solid #006769;
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background-color: #fff;
  color: #006769;
  text-align: center;
  font-size: 72px;
  font-family: "NEXONLv1GothicB";
}

.kiosk03 .btn-area .btn_print_m {
  height: auto;
  min-height: 480px;
  background: none;
  background-size: 70%;
  display: grid;
  padding: 30px;
  align-items: center;
  border-radius: 60px;
  background-color: #004c4e;
  color: #fff;
  text-align: center;
  font-family: "NEXONLv1GothicB", sans-serif;
  align-content: center;
  font-size: 72px;
}

.kiosk03 .btn-area .btn_print_m .small {
  padding-top: 10px;
  font-size: 32px;
  clear: both;
  display: block;
}

.btn-blue {
  border: 3px solid #00a2ff;
  background-color: #fff;
  color: #00a2ff;
}

.pass_card {
  margin: 0 auto 150px auto;
  padding: 100px 60px;
  width: 80%;
  border-radius: 20px;
  background: #ffffff;
}
.pass_card h3 {
  font-size: 70px;
  color: #004c4e;
  font-family: "NEXONLv1GothicB";
  margin-bottom: 50px;
}
.pass_card .h_num {
  margin-bottom: 30px;
}
</style>