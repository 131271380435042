<template>
  <div class="wrap">
    <!-- 발급 완료 -->
    <section class="kiosk kiosk03" style="display: block">
      <!-- 상단 버튼 -->
      <Header ref="header" :activeBackBtn="false" />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1 v-html="$t('출입등록이_완료되었습니다')"></h1>
        <p class="first"
          v-html="
            $t('10초_후에_처음화면으로_되돌아갑니다', { '0': `${countDown}` })
          "
        ></p>
        <p
          v-html="$t('감사합니다')" 
         ></p>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../../components/v2/Header.vue";
export default {
  name: "PassWithoutPrint",
  components: {
    Header,
  },
  data() {
    return {
      countDown: 10,
      setIntervalId: null,
    };
  },
  mounted() {
    this.setIntervalId = setInterval(() => {
      if (this.countDown > 0) {
        this.countDown--;
      } else {
        this.$refs.header.goHome();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.setIntervalId);
  },
};
</script>

<style scoped>
/* ----- 출입 등록3 / 발급 완료 ----- */
.kiosk03 .btn-top-area {
  justify-content: flex-end;
}

.kiosk03 .kiost-top {
  padding-top: 190px;
  text-align: center;
}

.kiosk03 .kiost-top h1 {
  font-size: 100px;
  line-height: 1.3;
}

.kiosk03 .kiost-top h2 {
  font-size: 50px;
}

.kiosk03 .kiosk-area {
  width: 630px;
  margin: 90px auto 0;
  padding: 90px 0;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  border-radius: 25px;
}

.kiosk03 .kiosk-end img {
  margin-bottom: 50px;
}
.kiosk03 .kiosk-end p {
  font-size: 40px;
  line-height: 1.4;
}

.kiost-staff {
  padding: 300px 0;
  font-family: "NEXONLv1GothicB";
  font-size: 70px;
  line-height: 1.4;
  color: #004c4e;
  text-align: center;
}

.kiosk03 .notice {
  padding-top: 75px;
  font-size: 40px;
  text-align: center;
  color: #006769;
}

.kiosk03 .btn-area {
  display: flex;
  margin-top: 60px;
}
.kiosk03 .btn-area a {
  font-size: 46px;
  flex: 1;
  margin: 0 10px;
}
.kiosk03 .btn-area.type1 {
  width: 100%;
}
.kiosk03 .btn-area .btn-blue {
  height: 300px;
}
.kiosk03 .btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.kiosk03 .btn-area .btn_print_p {
  height: 230px;
  background: url(../../assets/img/ico_print_p.png) no-repeat center 20%;
  background-size: 70%;
  display: block;
  border: 3px solid #006769;
  display: flex;
  padding-top: 200px;
  align-items: center;
  justify-content: center;
  height: 420px;
  border-radius: 60px;
  background-color: #fff;
  color: #006769;
}

.kiosk03 .btn-area .btn_print_m {
  height: 230px;
  background: url(../../assets/img/ico_print_m.png) no-repeat center 20%;
  background-size: 70%;
  display: block;
  padding-top: 300px;
  align-items: center;
  justify-content: center;
  height: 420px;
  border-radius: 60px;
  background-color: #004c4e;
  color: #fff;
  font-family: "NEXONLv1GothicB";
  text-align: center;
}

.kiosk03 .btn-area .btn_print_m .small {
  padding-top: 10px;
  font-size: 32px;
  clear: both;
  display: block;
}

.btn-blue {
  border: 3px solid #00a2ff;
  background-color: #fff;
  color: #00a2ff;
}

.pass_card {
  margin: 0 auto 150px auto;
  padding: 100px 60px;
  width: 80%;
  border-radius: 20px;
  background: #ffffff;
}
.pass_card h3 {
  font-size: 70px;
  color: #004c4e;
  font-family: "NEXONLv1GothicB";
  margin-bottom: 50px;
}
.pass_card .h_num {
  margin-bottom: 30px;
}
</style>