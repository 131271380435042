<template>
  <div>
    <div v-if="ready" class="btn-privacy">
      <li>
        <span class="privacy-text">{{ $t("개인정보_수집_동의") }}</span>
        <input v-model="privacyCheck" type="checkbox" id="pp_5" name="pp_5"/>
        <label for="pp_5"
          ><b>{{ $t("예") }}</b></label
        >
        <a
          href="javascript:void(0)"
          @click="showModal($t('개인정보_수집_및_이용'), privacy)"
          style="max-width:100px"
          >{{ $t("보기") }}</a
        >
      </li>
      <li  v-show="this.getValidInquiryCnt > 0">
        <span v-if="this.getUserType!==this.USER.PT" class="privacy-text">{{ $t("민감정보_수집_및_이용") }}</span>
        <span v-else class="privacy-text" v-html="$t('민감정보_수집_동의_입원_예약환자에_한함')"></span>
        <input v-model="sensitiveCheck" type="checkbox" id="pp_6" name="pp_6" />
        <label for="pp_6"
          ><b>{{ $t("예") }}</b></label
        >
        <a
          href="javascript:void(0)"
          @click="showModal($t('민감정보_수집_및_이용'), sensitive)"
          style="max-width:100px"
          >{{ $t("보기") }}</a
        >
      </li>
    </div>
    <div v-else class="btn-privacy" />
    <TermsPopup
      v-if="modalFlag"
      :modalTitle="modalTitle"
      :modalText="modalText"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TermsPopup from "../../../components/v2/modal/TermsPopup";
export default {
  name: "Terms",
  components: {
    TermsPopup,
  },
  props: {
    isChecked: Boolean,
  },
  data() {
    return {
      ready: false,
      privacy: null,
      sensitive: null,
      privacyCode: null,
      sensitiveCode: null,
      modalFlag: false,
      modalTitle: "",
      modalText: "",
      privacyCheck: false,
      sensitiveCheck: false,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType",'getLanguage','getKioskId', 'getValidInquiryCnt']),
  },
  mounted() {
    this.getAgreeTerm();
  },
  methods: {
    ...mapActions(["actionGoHome"]),
    async getAgreeTerm() {
      if (!this.getUserType){
        this.actionGoHome();
        return;
      }
      try {
        this.$modal.loading(true);
        let answerType = this.getUserType;
        if (answerType === this.USER.EMMA) answerType = this.USER.PT; // 응급환자는 환자의 약관을 사용
        if (answerType === this.USER.ENOK) answerType = this.USER.NOK; // 응급보호자는 보호자의 약관을 사용
        // if (answerType === this.USER.RNOK) answerType = this.USER.NOK; // 상주보호자는 보호자의 약관을 사용
        if (answerType === this.USER.TMP) answerType = this.USER.GST; // 기타방문자는는 게스트 약관을 사용
        // if (answerType === this.USER.PEMP) answerType = this.USER.EMP; // 협력업체(사번없는직원)은 내부직원 약관을 사용
        // if (answerType === this.USER.RPT) answerType = this.USER.PT; // 재원환자는 환자의 약관 사용

        const params = {
          answerType,
          language: this.getLanguage,
        };
        const response = await this.$axios.get("/api/v1/term", { params });
        this.ready = true;
        if('ko' === this.getLanguage) {
          this.privacy = response.data.result.privacy.privacyContents;
          this.sensitive = response.data.result.sensitive.privacyContents; 
        } else {
          this.privacy = response.data.result.privacy.privacyContentsEng;
          this.sensitive = response.data.result.sensitive.privacyContentsEng;
        } 
        this.privacyCode = response.data.result.privacy.privacyCode;
        this.sensitiveCode = response.data.result.sensitive.privacyCode;
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    closeModal() {
      this.modalFlag = false;
    },
    showModal(modalTitle, modalText) {
      this.modalTitle = modalTitle;
      this.modalText = modalText;
      this.modalFlag = true;
    },
    getTermsCode() {
      if (this.sensitiveCheck) {
        return {
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
        };
      } else {
        //문진 삭제에 따른 민감정보 수집 및 이용 내역 처리방법 변경
        return {
          privacyCode: this.privacyCode,
          sensitiveCode: null,
        };
      }
    },
    getSensitiveChecked() {
      return {
        sensitiveCheck: this.sensitiveCheck
      };
    },
  },
  watch: {
    getLanguage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAgreeTerm();
      }
    },
    getUserType: function () {
      this.getAgreeTerm();
    },
    privacyCheck: function (newV) {
      //민감정보 활성화 여부에 따라 값을 다르게 지정.
      if(this.getValidInquiryCnt > 0) {
        if(this.getUserType===this.USER.PT){
          this.$emit('input', newV);
        } else {
          this.$emit('input', newV && this.sensitiveCheck);
        }
      } else {
        this.$emit('input', newV);
      }
      
    },
    sensitiveCheck: function (newV) {
      if(this.getUserType===this.USER.EMMA||this.getUserType===this.USER.ENOK){
        this.$emit("input", this.privacyCheck && newV);
      } else {
        this.$emit("input", this.privacyCheck);
      }
      //민감정보 활성화 여부에 따라 값을 다르게 지정.
      if(this.getValidInquiryCnt > 0) {
        console.log('유저 유형 : ',this.getUserType);
        if(this.getUserType===this.USER.PT){
          this.$emit('input', this.privacyCheck);
        } else {
          this.$emit("input", this.privacyCheck && newV);
        }
      } else {
        this.$emit('input', this.privacyCheck);
      }
    },
  },
};
</script>

<style scoped>
.privacy-text{
  max-width: 400px;
  font-size: 24px;
  display: inline-block; 
  position: relative;
  margin-right: 220px;
}
</style>