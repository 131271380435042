<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk" style="display: block">
      <!-- 상단 버튼 -->
      <Header :depth="inquiryIndex" @depthDecrease="inquiryIndexDecrease" />
      <!-- kiosk header -->
      <div class="kiost-top" style="height: 430px; overflow: hidden">
        <h1>{{ questionContents }}</h1>
        <h2>{{ categoryDesc }}</h2>
      </div>
      <div class="slider-area-wrapper">
        <div class="slider-area" :style="shift_y">
          <div
            v-for="(item, index) in categoryList"
            :key="item.categoryId"
            style="height: 1060px"
          >
            <Normal
              v-if="item.categoryType === InqType.Normal || item.categoryType === InqType.OverseasVisitHistory || item.categoryType === InqType.HospitalVisitHistory"
              :contents="item"
              :isViewing="index === inquiryIndex"
              :isFirstInquiry="index === 0"
              :ref="`inquiry_${index}`"
              @leftBtnText="setLeftBtnText"
              @rightBtnText="setRightBtnText"
              @goBack="inquiryIndexDecrease"
              @goNext="goNext"
              @setShow="setShow"
              @oneButton="oneButton"
              @btnDisable="setBtnDisable"
            />
            <NormalImg
              v-else-if="item.categoryType === InqType.NormalImg"
              :contents="item"
              :isViewing="index === inquiryIndex"
              :ref="`inquiry_${index}`"
              @leftBtnText="setLeftBtnText"
              @rightBtnText="setRightBtnText"
              @goNext="goNext"
              @setShow="setShow"
              @oneButton="oneButton"
              @btnDisable="setBtnDisable"
            />
            <VisitHistory
              v-else-if="item.categoryType === InqType.VisitHistory"
              :contents="item"
              :isViewing="index === inquiryIndex"
              :ref="`inquiry_${index}`"
              @leftBtnText="setLeftBtnText"
              @rightBtnText="setRightBtnText"
              @goNext="goNext"
              @updatePlaceData="updatePlaceData"
              @showDatePickerPopup="showDatePickerPopup"
              @oneButton="oneButton"
              @btnDisable="setBtnDisable"
            />
            <MultiChoice
              v-else-if="item.categoryType === InqType.MultiChoice"
              :contents="item"
              :isViewing="index === inquiryIndex"
              :isFirstInquiry="index === 0"
              :ref="`inquiry_${index}`"
              @leftBtnText="setLeftBtnText"
              @rightBtnText="setRightBtnText"
              @goNext="goNext"
              @goBack="inquiryIndexDecrease"
              @oneButton="oneButton"
              @btnDisable="setBtnDisable"
              @setShow="setShow"
            />
          </div>
        </div>
      </div>
      <!-- 버튼 -->
      <div
        class="btn-area type2 footer"
        :style="{ justifyContent: oneBtnFlag ? 'center' : 'space-between' }"
      >
        <a
          v-if="!oneBtnFlag"
          href="javascript:void(0)"
          class="btn-default btn-white"
          @click="onClickLeftBtn"
          >{{ leftBtnText }}</a
        >
        <a
          href="javascript:void(0)"
          class="btn-default"
          :class="{ disabled: btnDisable }"
          @click="onClickRightBtn"
          :style="{ width: oneBtnFlag ? '100%' : '320px' }"
        >
          {{ rightBtnText }}</a
        >
      </div>
    </section>
    <InquiryWarningPopup v-if="initPopup" @closePopup="closeErrorPop" />
    <DatePickerPopup
      v-if="datePickerPopup"
      @closeDatePickerPopup="closeDatePickerPopup"
      :datePickerPopupInfo="datePickerPopupInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header";
import InquiryWarningPopup from "../../components/v2/modal/InquiryWarningPopup.vue";
import DatePickerPopup from "../../components/v2/modal/DatePickerPopup.vue";
import Normal from "../../components/v2/inquiryType/Normal.vue";
import NormalImg from "../../components/v2/inquiryType/NormalImg.vue";
import VisitHistory from "../../components/v2/inquiryType/VisitHistory.vue";
import MultiChoice from "../../components/v2/inquiryType/MultiChoice.vue";
import lodash from "lodash";

export default {
  name: "Inquiry",
  components: {
    Header,
    InquiryWarningPopup,
    Normal,
    VisitHistory,
    DatePickerPopup,
    MultiChoice,
    NormalImg,
  },
  props: {
    // 고유번호 확인 결과에서 받은 값 (필수)
    identityNo: String,
    inquiryType: String,
    passCheck: String, // 안쓰이는값
    answerType: String,
    visitDate: String,
    // 약관동의 버전 Code 정보 (필수)
    privacyCode: String,
    sensitiveCode: String,
    // 보호자가 문진 작성 시 필요한 값
    refId: String,
    //동행 보호자 제한 수
    vistCnt: String,

    // 응급환자/보호자 용 키오스크에서 사용하는 값
    newPtYn: String,
    identityBirth: String,
    identityPhone: String,
    refPhone: String,
    refBirth: String,

    // 미리보기로 진입시 사용되는 값
    preViewCategoryList: Array,
  },
  data() {
    return {
      initPopup: true,
      datePickerPopup: false,
      datePickerPopupInfo: {},
      detailId: "",
      originCategoryList: [],
      inquiryIndex: 0,
      leftBtnText: "",
      rightBtnText: "",
      oneBtnFlag: false,
      btnLock: false,
      btnDisable: false,
      visitHistoryTemplete: null,
      homemateWrap: false,
    };
  },
  computed: {
    ...mapState(["USER", "InqType"]),
    ...mapGetters(["getUserType", "getLanguage", "getKioskId", "getKioskType"]),
    shift_y() {
      return `transform: translateY(${-1 * (this.inquiryIndex * 1150 + 60)}px)`;
    },
    categoryList() {
      // Inquiry 타입 별 노출 조건 Filtering
      return this.originCategoryList.filter((item) => {
        if (item.categoryType === this.InqType.VisitHistory) {
          // 방문력은 한국어/영어 선택시에만 노출
          return this.getLanguage === "ko" || this.getLanguage === "en";
        } else {
          return (
            // 동거인 관련 카테고리
            item.categoryType !== this.InqType.Housemate &&
            item.categoryType !== this.InqType.HousemateRelation &&
            item.categoryType !== this.InqType.HousemateDetail &&
            // 키오스크에서 제공 안하는 카테고리
            item.categoryType !== this.InqType.Etc &&
            item.categoryType !== this.InqType.ShortAnswer &&
            item.categoryType !== this.InqType.Editor &&        
            
            item.show === true
          );
        }
      });
    },
    questionContents() {
      // if(item.categoryType === InqType.VisitHistory)
      if (
        this.categoryList.length > 0 &&
        this.inquiryIndex < this.categoryList.length
      ) {
        // 방문력의 경우 questionContents 이 비정상인 경우 '방문력'을 title로 사용
        const langQuestion = this.categoryList[this.inquiryIndex]?.langQuestion;
        const questionContents =
          this.categoryList[this.inquiryIndex]?.questionContents;

        if (langQuestion && langQuestion !== "") return langQuestion;
        else if (questionContents && questionContents !== "")
          return questionContents;
        else return this.$t("방문력");
      }
      return "";
    },
    categoryDesc() {
      if (
        this.categoryList.length > 0 &&
        this.inquiryIndex < this.categoryList.length
      ) {
        const langCategoryDesc =
          this.categoryList[this.inquiryIndex]?.langCategoryDesc;
        const categoryDesc = this.categoryList[this.inquiryIndex]?.categoryDesc;
        if (langCategoryDesc && langCategoryDesc !== "")
          return langCategoryDesc;
        else if (categoryDesc && categoryDesc !== "") return categoryDesc;
      }
      return "";
    },
  },
  mounted() {
    if (this.identityNo !== undefined) this.getInquiryInfo();
    else if (this.getKioskId === "preview") {
      this.getCategoryInfoFromAdmin();
    }
  },
  methods: {
    getCategoryInfoFromAdmin() {
      this.$modal.loading(true);
      this.originCategoryList = this.preViewCategoryList;
      this.visitHistoryTemplete = lodash.cloneDeep(
        this.originCategoryList.filter(
          (item) => item.categoryType === this.InqType.VisitHistory
        )
      );
      this.homemateTrue(this.originCategoryList);      
      this.$modal.loading(false);
    },
    async getInquiryInfo() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get("/api/v1/inquiry", {
          params: {
            answerType: this.inquiryType, // 필드명 주의 (inquiryType을 answerType에 넣어야함)
            language: this.getLanguage,
          },
        });
        // if (!response.data.success) throw new Error("error");
        this.detailId = response.data.result.detailId;
        this.originCategoryList = response.data.result.categoryList;
        this.visitHistoryTemplete = lodash.cloneDeep(
          this.originCategoryList.filter(
            (item) => item.categoryType === this.InqType.VisitHistory
          )
        );
        this.homemateTrue(this.originCategoryList);
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async saveInquiryInfo() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        let response = null;
        if (
          this.getUserType === this.USER.EMMA ||
          this.getUserType === this.USER.ENOK
        ) {
          response = await this.$axios.post("/api/site/v1/emma/answer", {
            answerType: this.answerType,
            inqType: this.inquiryType,
            detailId: this.detailId,
            deviceType: "K",
            deviceId: this.getKioskId,
            privacyCode: this.privacyCode,
            sensitiveCode: this.sensitiveCode,
            identityNo: this.identityNo,
            categoryList: this.originCategoryList,
            newPtYn: this.newPtYn,
            identityBirth: this.identityBirth,
            identityPhone: this.identityPhone,
            refPhone: this.refPhone,
            refBirth: this.refBirth,
            refId: this.refId,
            kioskType: this.getKioskType,
            completePlace:
              this.getLanguage === "ko" || this.getLanguage === "en",
          });
        } else {
          response = await this.$axios.post("/api/site/v1/answer", {
            identityNo: this.identityNo,
            inqType: this.inquiryType,
            answerType: this.answerType,
            deviceId: this.getKioskId,
            detailId: this.detailId,
            completePlace:
              this.getLanguage === "ko" || this.getLanguage === "en",
            categoryList: this.originCategoryList,
            deviceType: "K",
            privacyCode: this.privacyCode,
            sensitiveCode: this.sensitiveCode,
            refId: this.refId,
            vistCnt: this.vistCnt,
            visitReason: this.$route.params.visitReason || null,
          });
        }
        console.log(response.data.resultCd);
        // if (!response.data.success) throw new Error("error");
        if(response.data.resultCd === 311) {
          this.$modal.info(
              this.$t("문진을_다시_시도해_주세요"),
              this.$t(
                "현재_시스템_접속이_원활하지_않습니다._잠시_후_다시_시도해_주세요"
              ).replace(/<br \/>/g, "")
            );
            return;
        }

        // 응급환자/보호자 의 경우 결과와 상관없이 emmaPass로 이동
        if (
          this.getUserType === this.USER.EMMA ||
          this.getUserType === this.USER.ENOK
        ) {
          this.$router.push({
            name: "emmaPass",
            params: {
              passCheck: response.data.result?.passCheck,
            },
          });
        } else if (this.getUserType === this.USER.EMP) {
          // 내부 직원 인 경우 결과와 상관없이 passWithoutPrint로 이동
          this.$router.push({ name: "passWithoutPrint" });
        } else {
          switch (response.data.resultCd) {
            case 300:
            case 201:
              // 300 - 문진 대상자가 아닙니다.
              // 201 - 증상이 있어 출입이 제한됩니다.
              this.$router.push({
                name: "forbiden",
                params: {
                  passCheck: response.data.result.passCheck,
                  refId: this.refId,
                },
              });
              break;
            case 0:
              // 0 - 출입증 발급
              this.$router.push({
                name: "passWithPrint",
                params: {
                  passCheck: response.data.result.passCheck,
                  refId: this.refId,
                },
              });
              break;
            default:
              throw new Error("정의되지 않은 resultCd 오류");
          }
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    onClickLeftBtn() {
      this.$refs[`inquiry_${this.inquiryIndex}`][0].leftBtnAction();
    },
    onClickRightBtn() {
      if (this.btnDisable) return;
      this.$refs[`inquiry_${this.inquiryIndex}`][0].rightBtnAction();
    },
    setLeftBtnText(text) {
      this.leftBtnText = text;
    },
    setRightBtnText(text) {
      this.rightBtnText = text;
    },
    updatePlaceData(placeData) {
      this.$refs[`inquiry_${this.inquiryIndex}`][0].contents.placeList =
        placeData;
    },
    goNext() {
      if (this.inquiryIndex < this.categoryList.length - 1) this.inquiryIndex++;
      else if (this.homemateWrap == true) {
        const categoryListPayload = lodash.cloneDeep(this.originCategoryList);
        // 내부직원은 동거인 정보 수집도 해야 함
        this.$router.replace({
          name: "homeMateInquiry",
          params: {
            identityNo: this.identityNo,
            inqType: this.inquiryType,
            answerType: this.answerType,
            detailId: this.detailId,
            categoryList: categoryListPayload,
            deviceType: "K",
            privacyCode: this.privacyCode,
            sensitiveCode: this.sensitiveCode,
            visitHistoryTemplete: this.visitHistoryTemplete,
          },
        });
      } else if (this.identityNo === undefined) {
        this.$modal.info(this.$t("알림"), "마지막 문항입니다.");
      } else {
        //get -> 응급실인데 24시간 켜져있으면 => push else saveInquiryInfo
         if (
          this.getUserType === this.USER.EMMA
             // || this.getUserType === this.USER.ENOK 응급실에서 보호자의 체류시간 동의는 받지 않기로 결정 (23.09.07)
        ) { 
          this.$axios.get('/api/site/emma/residence').then (res => {
          if(res.data.success) {
            if( res.data.result.useYn =="Y" ) {
               this.$router.push({
              name: 'emmaAnnounce',
              params: {
              answerType: this.answerType,
              inqType: this.inquiryType,
              detailId: this.detailId,
              deviceType: "K",
              deviceId: this.getKioskId,
              privacyCode: this.privacyCode,
              sensitiveCode: this.sensitiveCode,
              identityNo: this.identityNo,
              categoryList: this.originCategoryList,
              newPtYn: this.newPtYn,
              identityBirth: this.identityBirth,
              identityPhone: this.identityPhone,
              refPhone: this.refPhone,
              refBirth: this.refBirth,
              refId: this.refId,
              kioskType: this.getKioskType,
              completePlace:
                this.getLanguage === "ko" || this.getLanguage === "en",
              residenceCode: res.data.result.residenceInfo.code,
              title : res.data.result.residenceInfo.title,
              titleEn: res.data.result.residenceInfo.titleEn,
              contents: res.data.result.residenceInfo.contents,
              contentsEn: res.data.result.residenceInfo.contentsEn,
              }
            })
          
            } else {
             this.saveInquiryInfo();
          }
            
          }
          })

        } else { 
            this.saveInquiryInfo();
        }
      }
    },
    closeErrorPop() {
      this.initPopup = false;
    },
    showDatePickerPopup({ datePickerPopupText, boundaryDateSplit, placeDate }) {
      this.datePickerPopupInfo = {
        datePickerPopupText,
        boundaryDateSplit,
        placeDate,
      };
      this.datePickerPopup = true;
    },
    closeDatePickerPopup(selectDate) {
      this.datePickerPopup = false;
      this.$refs[`inquiry_${this.inquiryIndex}`][0].onSelectDate(selectDate);
    },
    oneButton(set) {
      if (set) {
        this.oneBtnFlag = true;
      } else {
        this.oneBtnFlag = false;
      }
    },
    setBtnDisable(disable) {
      this.btnDisable = disable;
    },
    setShow({ targetCategoryId, isShow }) {
      for (const item of this.originCategoryList) {
        if (item.categoryId === targetCategoryId) {
          item.show = isShow;
        }
      }
    },
    inquiryIndexDecrease() {
      if (this.inquiryIndex > 0){ 
        let orgnIndx = this.categoryList[this.inquiryIndex].categoryOrder;

        if(this.originCategoryList[orgnIndx].categoryType === "M"){
            this.originCategoryList[orgnIndx].show = false;
            this.originCategoryList[orgnIndx].answerId = null;
        }
        this.inquiryIndex--;
      }
    },

    //동거가족 문항 on/off
    homemateTrue: function (categoryList){ 
          categoryList.forEach((item) => { 
            if((this.getUserType == 'EMP')){
              if ((item.categoryType == 'FD') || (item.categoryType =='FS') || (item.categoryType =='FT') ) { 
                
                this.homemateWrap = true; 
              }    
            }
          }); 
        console.log("homewrap:"+ this.homemateWrap) 
    },     
  },
};
</script>

<style scoped>
.wrap{
  white-space: pre-wrap;
}
.slider-area-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  top: 550px;
  height: 1160px;
  overflow: hidden;
  /* margin-top: 90px; */
  padding: 30px 115px;
}
.slider-area {
  transition: all 0.3s ease-in-out;
}
/*라디오버튼 숨김*/
.num_box.patient {
  margin: -20px -40px 50px -40px;
  padding: 14px;
  background: #f6f6f6;
  border-radius: 60px;
  border-bottom: none !important;
}
.num_box.patient .patient_cont {
  display: none;
}
.num_box.patient input[type="radio"] {
  display: none;
}
.num_box.patient input[type="num"] {
  margin-top: 60px;
  border-bottom: 6px solid #cccccc !important;
}

.num_box.patient input[type="num"]:focus {
  color: #333333;
  border-bottom: 6px solid #509d9c !important;
}
.num_box.patient label {
  display: inline-block;
  margin: 0;
  padding: 0 40px;
  width: auto;
  height: 90px;
  line-height: 90px;
  font-weight: 600;
  text-align: center;
  color: #999;
  font-size: 36px;
}

.num_box.patient label:hover {
  color: #2e9cdf;
  cursor: pointer;
}

/*input 클릭시, label 스타일*/
.num_box.patient input:checked + label {
  color: #fff;
  background: #f68b1f;
  border-radius: 50px;
}

#num_id:checked ~ #num_id_cont,
#num_pati:checked ~ #num_pati_cont {
  display: block;
}

.btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.check_yes .checked {
  background: #eafffb;
}
.check_yes .checked .checkbox_img,
.check_yes .img_symptoms .checked .checkbox_img {
  background: url("../../assets/img/ico_checked.png");
}
.check_yes .img_symptoms .checkbox_img {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../assets/img/ico_uncheck.png");
}

.radio_img {
  position: absolute;
  right: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../assets/img/ico_radio.png");
}
.radiochk_img {
  position: absolute;
  right: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../assets/img/ico_radiochecked.png");
}

.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 50px;
}

.kiosk-txt table {
  width: 100%;
  font-size: 36px;
}
.kiosk-txt th {
  padding: 40px 0 0 40px;
  width: 22%;
  vertical-align: top;
}

.kiosk-txt .label_new {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #00a2ff;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}
.kiosk-txt .label_important {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #f68b1f;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}

.kiosk-txt .pic_date {
  position: absolute;
  right: 5%;
  top: 56%;
  padding-left: 40px;
  color: #00a2ff;
  font-size: 34px;
  background: url(../../assets/img/ico_calander.png) no-repeat left;
}
.datapicker {
  display: block;
  width: 100%;
  height: 450px;
}
.kiost-top h1 {
  font-size: 70px;
}
:lang(en) .kiost-top h1,
:lang(ru) .kiost-top h1,
:lang(vi) .kiost-top h1,
:lang(mn) .kiost-top h1 {
  font-size: 59px;
}
</style>