<template>
  <!-- 알림 -->
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="modal-body">
        <div class="noti_txt">
          <!-- <img src="@/assets/img/ico_q.png" width="200px" /> -->
          <h3>{{ $t("알림") }}</h3>
          <p
            v-html="
              $t(
                '본_문진표를_허위로_작성할_경우_' +
                  '보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다'
              )
            "
          />
        </div>
        <!-- 버튼 -->
        <div class="btn-area">
          <a
            href="javascript:void(0)"
            class="btn-default"
            @click="closePopup"
            >{{ $t("확인") }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- 알림 -->
</template>

<script>
export default {
  name: "InquiryWarningPopup",
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped>
.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 60px;
  font-family: "NEXONLv1GothicB", sans-serif;
  color: #004c4e;
}

/* ----- modal ----- */
.modal_height80 {
  width: 100%;
  height: 72%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header .txt_addr {
  margin-top: 30px;
  font-size: 36px;
}
/* .modal-prop {
    /* margin:0 -90px 60px -90px; 
} */
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}
</style>