<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk" style="display: block">
      <!-- 상단 버튼 -->
      <Header :depth="inquiryIndex + 1" @depthDecrease="inquiryIndexDecrease" />
      <!-- 동거인 문진 목록 화면 -->
      <div v-if="famListTargerIdx === null">
        <div class="kiost-top" style="height: 440px; overflow: hidden">
          <h1>{{ $t("동거가족_문진") }}</h1>
          <h2
            v-html="
              $t(
                '동거가족이_코로나19_연관성_혹은_코로나19_관련_장소_방문력이_있는_경우_아래_문진을_추가하세요'
              )
            "
          ></h2>
        </div>

        <div class="kiosk-area full">
          <div class="btn-area type1">
            <template v-for="(item, index) in famList">
              <div
                v-if="item !== null"
                class="btn-default btn-blue family_ok"
                :key="item.categoryId"
              >
                {{ $t("동거가족1의_문진", { "0": `${index + 1}` }) }}
                <a
                  href="javascript:void(0)"
                  class="btn_del"
                  @click="deleteFam($event, index)"
                  >{{ $t("삭제") }}</a
                >
              </div>
              <div
                v-else
                :key="index"
                class="btn-default btn-white"
                @click="addFam($event, index)"
              >
                {{ $t("동거가족_문진_추가") }}
              </div>
            </template>
          </div>
        </div>
        <!-- 버튼 -->
        <div
          class="btn-area type1 footer"
          :class="{ disabled: btnLock }"
          @click="onSubmit"
        >
          <a href="javascript:void(0)" class="btn-default">{{ $t("제출") }}</a>
        </div>
      </div>

      <!-- 동거인 문진 정보 입력 화면 -->
      <div v-else>
        <div class="kiost-top" style="height: 443px; overflow: hidden">
          <h1 v-html="questionContents" />
          <h2 v-html="categoryDesc" />
        </div>
        <div class="slider-area-wrapper">
          <div class="slider-area" :style="shift_y">
            <div
              v-for="(item, index) in filteredFamList(famListTargerIdx)"
              :key="item.categoryId"
              style="height: 1060px"
            >
              <VisitHistory
                v-if="item.categoryType === InqType.VisitHistory"
                :contents="item"
                :isViewing="index === inquiryIndex"
                :ref="`inquiry_${index}`"
                @leftBtnText="setLeftBtnText"
                @rightBtnText="setRightBtnText"
                @goNext="goNext"
                @setShow="setShowVH"
                @updatePlaceData="updatePlaceData"
                @showDatePickerPopup="showDatePickerPopup"
                @oneButton="oneButton"
                @btnDisable="setBtnDisable"
              />
              <Normal
                v-else-if="item.categoryType === InqType.HousemateRelation"
                :contents="item"
                :isViewing="index === inquiryIndex"
                :ref="`inquiry_${index}`"
                @leftBtnText="setLeftBtnText"
                @rightBtnText="setRightBtnText"
                @setShow="setShowHR"
                @oneButton="oneButton"
                @btnDisable="setBtnDisable"
                @goNext="goNext"
              />
              <MultiChoice
                v-else-if="item.categoryType === InqType.Housemate"
                :contents="item"
                :isViewing="index === inquiryIndex"
                :isFirstInquiry="index === 0"
                :ref="`inquiry_${index}`"
                @leftBtnText="setLeftBtnText"
                @rightBtnText="setRightBtnText"
                @goNext="goNext"
                @goBack="inquiryIndexDecrease"
                @oneButton="oneButton"
                @btnDisable="setBtnDisable"
              />
            </div>
          </div>
        </div>
        <!-- 버튼 -->
        <div
          class="btn-area type2 footer"
          :style="{ justifyContent: oneBtnFlag ? 'center' : 'space-between' }"
        >
          <a
            v-if="!oneBtnFlag"
            href="javascript:void(0)"
            class="btn-default btn-white"
            @click="onClickLeftBtn"
            >{{ leftBtnText }}</a
          >
          <a
            href="javascript:void(0)"
            class="btn-default"
            :class="{ disabled: btnDisable }"
            @click="onClickRightBtn"
            :style="{ width: oneBtnFlag ? '100%' : '320px' }"
          >
            {{ rightBtnText }}</a
          >
        </div>
      </div>
    </section>
    <DatePickerPopup
      v-if="datePickerPopup"
      @closeDatePickerPopup="closeDatePickerPopup"
      :datePickerPopupInfo="datePickerPopupInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header";
import VisitHistory from "../../components/v2/inquiryType/VisitHistory.vue";
import Normal from "../../components/v2/inquiryType/Normal.vue";
import MultiChoice from "../../components/v2/inquiryType/MultiChoice.vue";
import DatePickerPopup from "../../components/v2/modal/DatePickerPopup.vue";
import lodash from "lodash";

export default {
  name: "HomeMateInquiry",
  components: {
    Header,
    VisitHistory,
    DatePickerPopup,
    Normal,
    MultiChoice,
  },
  props: {
    identityNo: String,
    inqType: String,
    answerType: String,
    detailId: String,
    categoryList: Array,
    deviceType: String,
    privacyCode: String,
    sensitiveCode: String,
    visitHistoryTemplete: Array,
  },
  data() {
    return {
      famList: [],
      famListTargerIdx: null, // null이면 리스트화면 숫자면 문진작성 화면
      datePickerPopup: false,
      datePickerPopupInfo: {},
      maxFam: 2, // 최대 2명의 동거인 등록 가능
      inquiryIndex: 0,
      leftBtnText: "",
      rightBtnText: "",
      oneBtnFlag: false,
      btnLock: false,
      btnDisable: false,
      isCheckHousemateRelation: false,
      isCheckvisitHistory: false,
    };
  },
  computed: {
    ...mapState(["USER", "InqType"]),
    ...mapGetters(["getUserType", "getLanguage", "getKioskId", "getKioskType"]),
    shift_y() {
      return `transform: translateY(${-1 * (this.inquiryIndex * 1150 + 60)}px)`;
    },
    filteredFamList() {
      return (idx) =>
        this.famList[idx].filter((item) => {
          if (item.categoryType === this.InqType.Housemate) {
            // 증상 문진은 방문력이나 역학적연관성이 체크되있을때만 노출함
            return this.isCheckvisitHistory || this.isCheckHousemateRelation;
          } else if (item.categoryType === this.InqType.VisitHistory) {
            return this.getLanguage === "ko" || this.getLanguage === "en";
          }  else if (item.categoryType === this.InqType.HousemateDetail) {
            return false;
          }
          return true;
        });
    },
    questionContents() {
      if (
        this.famList.length > 0 &&
        this.famList[this.famListTargerIdx].length > 0 &&
        this.inquiryIndex < this.famList[this.famListTargerIdx].length
      ) {
        // 방문력의 경우 questionContents 입력값이 없음 (항상 Null)
        if (
          this.famList[this.famListTargerIdx][this.inquiryIndex]
            ?.categoryType === this.InqType.VisitHistory
        )
          return `${this.$t("동거가족1의_문진", {
            0: `${this.famListTargerIdx + 1}`,
          })}<br><span class="small" style="font-size: 60px;"> - ${this.$t(
            "장소_방문력"
          )}</span>`;
        else {
          const langQuestion =
            this.famList[this.famListTargerIdx][this.inquiryIndex]
              ?.langQuestion;
          const questionContents =
            this.famList[this.famListTargerIdx][this.inquiryIndex]
              ?.questionContents;
          if (langQuestion && langQuestion !== "") return langQuestion;
          else if (questionContents && questionContents !== "")
            return questionContents;
        }
      }
      return "";
    },
    categoryDesc() {
      if (
        this.famList.length > 0 &&
        this.famList[this.famListTargerIdx].length > 0 &&
        this.inquiryIndex < this.famList[this.famListTargerIdx].length
      ) {
        const langCategoryDesc =
          this.famList[this.famListTargerIdx][this.inquiryIndex]
            ?.langCategoryDesc;
        const categoryDesc =
          this.famList[this.famListTargerIdx][this.inquiryIndex]?.categoryDesc;

        if (langCategoryDesc && langCategoryDesc !== "")
          return langCategoryDesc;
        else if (categoryDesc && categoryDesc !== "") return categoryDesc;
      }
      return "";
    },
  },
  mounted() {
    this.famList.push(null);
  },
  methods: {
    deleteFam(_e, index) {
      this.famList[index] = null;

      let lastDataIdx = null;
      for (let i = 0; i < this.famList.length; i++) {
        if (this.famList[i] !== null) lastDataIdx = i;
      }
      if (lastDataIdx === null) {
        this.famList = [null];
      } else if (lastDataIdx === this.famList.length - 1) {
        this.famList = lodash.cloneDeep(this.famList);
      } else {
        this.famList = lodash.cloneDeep(this.famList.slice(0, lastDataIdx + 1));
        this.famList.push(null);
      }
    },
    addFam(_e, index) {
      this.$set(this.famList, index, this.makeNewFam());
      if (index === this.famList.length - 1 && index < this.maxFam - 1) {
        this.famList.push(null);
      }
      this.famListTargerIdx = index;
    },
    makeNewFam() {
      const newFam = [];
      const visitHistory = lodash.cloneDeep(this.visitHistoryTemplete);
      const Housemate = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.Housemate
        )
      );
      const HousemateRelation = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.HousemateRelation
        )
      );
       const HousemateDetail = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.HousemateDetail
        )
      );
      // 1. 역학적 연관성
      if (HousemateRelation.length > 0) {
        HousemateRelation[0].targetCategoryId = Housemate[0].categoryId;
        newFam.push(HousemateRelation[0]);
      }
       // 2. 역학적 연관성 상세 주관식
      if (HousemateDetail.length > 0) {
        newFam.push(HousemateDetail[0]);
      }
      // 2. 방문력
      if (visitHistory.length > 0) {
        visitHistory[0].targetCategoryId = Housemate[0].categoryId;
        newFam.push(visitHistory[0]);
      }
      // 3. 증상
      if (Housemate.length > 0) {
        newFam.push(Housemate[0]);
      }
      return newFam;
    },
    async onSubmit() {
      if (this.btnLock) return;
      if (this.getKioskId === "preview") {
        this.$modal.info(this.$t("알림"), "마지막 문항입니다.");
        return;
      }
      this.btnLock = true;

      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/site/v1/answer", {
          identityNo: this.identityNo,
          inqType: this.inqType,
          answerType: this.answerType,
          deviceId: this.getKioskId,
          detailId: this.detailId,
          completePlace: this.getLanguage === "ko" || this.getLanguage === "en",
          categoryList: this.categoryList,
          deviceType: this.deviceType,
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
          refId: null,
          fam1: this.famList.length > 0 ? this.famList[0] : null,
          fam2: this.famList.length > 1 ? this.famList[1] : null,
          fam3: this.famList.length > 2 ? this.famList[2] : null,
          defaultFam: this.makeNewFam(),
          kioskType: this.getKioskType,
        });
        // if (!response.data.success) throw new Error("error");
         if(response.data.resultCd === 311) {
          this.$modal.info(
              this.$t("문진을_다시_시도해_주세요"),
              this.$t(
                "현재_시스템_접속이_원활하지_않습니다._잠시_후_다시_시도해_주세요"
              ).replace(/<br \/>/g, "")
            );
            return;
        }

        switch (response.data.resultCd) {
          case 300:
          case 201:
            // 300 - 문진 대상자가 아닙니다.
            // 201 - 증상이 있어 출입이 제한됩니다.
            this.$router.push({
              name: "forbiden",
              params: { passCheck: response.data.result.passCheck },
            });
            break;
          case 0:
            // 내부 직원 인 경우 이동 페이지
            if (this.getUserType === this.USER.EMP) {
              this.$router.push({ name: "passWithoutPrint" });
            } else {
              // 0 - 출입증 발급
              this.$router.push({
                name: "passWithPrint",
                params: { passCheck: response.data.result.passCheck },
              });
            }
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    onClickLeftBtn() {
      this.$refs[`inquiry_${this.inquiryIndex}`][0].leftBtnAction();
    },
    onClickRightBtn() {
      if (this.btnDisable) return;
      this.$refs[`inquiry_${this.inquiryIndex}`][0].rightBtnAction();
    },
    setLeftBtnText(text) {
      this.leftBtnText = text;
    },
    setRightBtnText(text) {
      this.rightBtnText = text;
    },
    updatePlaceData(placeData) {
      this.$refs[`inquiry_${this.inquiryIndex}`][0].contents.placeList =
        placeData;
    },
    goNext() {
      if (
        this.inquiryIndex <
        this.filteredFamList(this.famListTargerIdx).length - 1
      )
        this.inquiryIndex++;
      else {
        this.famListTargerIdx = null;
      }
    },
    showDatePickerPopup({ datePickerPopupText, boundaryDateSplit, placeDate }) {
      this.datePickerPopupInfo = {
        datePickerPopupText,
        boundaryDateSplit,
        placeDate,
      };
      this.datePickerPopup = true;
    },
    closeDatePickerPopup(selectDate) {
      this.datePickerPopup = false;
      this.$refs[`inquiry_${this.inquiryIndex}`][0].onSelectDate(selectDate);
    },
    oneButton(set) {
      if (set) {
        this.oneBtnFlag = true;
      } else {
        this.oneBtnFlag = false;
      }
    },
    setBtnDisable(disable) {
      this.btnDisable = disable;
    },
    setShowVH({ isShow }) {
      this.isCheckvisitHistory = isShow;
    },
    setShowHR({ isShow }) {
      this.isCheckHousemateRelation = isShow;
    },
    inquiryIndexDecrease() {
      if (this.inquiryIndex > 0) this.inquiryIndex--;
      else if (this.famListTargerIdx !== null) {
        this.deleteFam(null, this.famListTargerIdx);
        this.famListTargerIdx = null;
      } else this.$router.go(-1);
    },
  },
  watch: {
    famListTargerIdx: function () {
      this.inquiryIndex = 0;
      this.isCheckvisitHistory = false;
      this.isCheckHousemateRelation = false;
    },
  },
};
</script>

<style scoped>
.kiosk-area {
  position: relative;
  bottom: 0;
} /* 별도 style */
.kiosk-area .btn-area {
  position: relative;
  width: 100%;
}
.slider-area-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  top: 550px;
  height: 1160px;
  overflow: hidden;
  /* margin-top: 90px; */
  padding: 30px 115px;
}
.slider-area {
  transition: all 0.3s ease-in-out;
}
/*라디오버튼 숨김*/
.num_box.patient {
  margin: -20px -40px 50px -40px;
  padding: 14px;
  background: #f6f6f6;
  border-radius: 60px;
  border-bottom: none !important;
}
.num_box.patient .patient_cont {
  display: none;
}
.num_box.patient input[type="radio"] {
  display: none;
}
.num_box.patient input[type="num"] {
  margin-top: 60px;
  border-bottom: 6px solid #cccccc !important;
}

.num_box.patient label {
  display: inline-block;
  margin: 0;
  padding: 0 40px;
  width: auto;
  height: 90px;
  line-height: 90px;
  font-weight: 600;
  text-align: center;
  color: #999;
  font-size: 36px;
}

.num_box.patient label:hover {
  color: #2e9cdf;
  cursor: pointer;
}

/*input 클릭시, label 스타일*/
.num_box.patient input:checked + label {
  color: #fff;
  background: #f68b1f;
  border-radius: 50px;
}

#num_id:checked ~ #num_id_cont,
#num_pati:checked ~ #num_pati_cont {
  display: block;
}

.kiosk-area .btn-area {
  padding: 80px 40px;
}
.kiost-top .small {
  font-size: 60px;
}

.btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.kiosk-txt {
  padding: 0px;
}
.kiosk-txt .txt_c {
  text-align: center;
}
.kiosk-txt ul {
  padding-top: 0px;
}

.kiosk-txt ul li {
  padding-left: 25px;
  padding: 30px 100px 30px 65px;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: -1px;
  position: relative;
  border-bottom: 1px solid #cccccc;
}
.kiosk-txt ul li:last-child {
  border-bottom: none;
}
.kiosk-txt.checkbox_check ul li {
  padding: 0;
}

.kiosk-txt h4 {
  margin-top: 40px;
  font-size: 36px;
  padding: 30px 60px;
  background: #eaeaea;
  font-weight: bold;
}
.kiosk-txt .small {
  font-size: 28px !important;
  line-height: 1.2;
}

.kiosk-txt ul li p {
  padding-bottom: 50px;
  font-size: 30px;
}

.kiosk-txt .img_symptoms dl {
  float: left;
  padding: 35px 0px 35px 100px;
  width: 50%;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.kiosk-txt .img_symptoms dt {
  font-size: 36px;
  padding-bottom: 20px;
  text-align: left;
}
.kiosk-txt .img_symptoms img {
  width: 71%;
}

.check_no .checkbox_img {
  display: none;
}
.check_yes .checkbox_img {
  display: block;
}
.check_yes .checkbox_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../assets/img/ico_uncheck.png");
}
.check_yes .checked {
  background: #eafffb;
}
.check_yes .checked .checkbox_img,
.check_yes .img_symptoms .checked .checkbox_img {
  background: url("../../assets/img/ico_checked.png");
}
.check_yes .img_symptoms .checkbox_img {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../assets/img/ico_uncheck.png");
}

.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 50px;
}

.kiosk-txt table {
  width: 100%;
  font-size: 36px;
}
.kiosk-txt th {
  padding: 40px 0 0 40px;
  width: 22%;
  vertical-align: top;
}

.kiosk-txt .label_new {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #00a2ff;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}
.kiosk-txt .label_important {
  width: auto !important;
  padding: 0 10px !important;
  font-size: 30px !important;
  background: #f68b1f;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}

.kiosk-txt .pic_date {
  position: absolute;
  right: 5%;
  top: 56%;
  padding-left: 40px;
  color: #00a2ff;
  font-size: 34px;
  background: url(../../assets/img/ico_calander.png) no-repeat left;
}
.datapicker {
  display: block;
  width: 100%;
  height: 450px;
}
.kiosk-area.full {
  height: 1100px;
}
.btn-blue {
  border: 3px solid #00a2ff;
  background-color: #fff;
  color: #00a2ff;
}

/* ----- modal ----- */
.modal_height80 {
  width: 100%;
  height: 72%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header .txt_addr {
  margin-top: 30px;
  font-size: 36px;
}
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop .title {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #333333;
}
.modal-prop .title.active {
  margin-top: 20px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border-radius: 10px;
  color: #004c4e;
  background: #f6f6f6;
}
.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}

.family_ok {
  position: relative;
  margin: 30px 0;
  padding-left: 150px;
  padding-right: 180px;
  text-align: left;
  background: url("../../assets/img/ico_family_chk.png") no-repeat 10% 50%;
}

.family_ok .btn_del {
  position: absolute;
  top: 20%;
  right: 20px;
  padding: 20px 30px;
  font-size: 30px;
  border-radius: 50px;
  background: #999999;
  color: #ffffff;
}

.btn-area.type1:last-child .btn-white {
  margin: 30px 10px;
}
:lang(en) .btn-default,
:lang(ru) .btn-default,
:lang(vi) .btn-default,
:lang(mn) .btn-default {
  font-size: 34px !important;
}
:lang(en) .kiost-top h1,
:lang(ru) .kiost-top h1,
:lang(vi) .kiost-top h1,
:lang(mn) .kiost-top h1 {
  font-size: 59px;
}

:lang(en) .kiost-top h2,
:lang(ru) .kiost-top h2,
:lang(vi) .kiost-top h2,
:lang(mn) .kiost-top h2 {
  font-size: 30px;
}
</style>