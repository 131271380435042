<template>
  <div class="code-container">
    <!-- 키오스크 input -->
    <div class="kiosk-input">
      <label for="" class="hide"></label>
      <input
        ref="kioskInput"
        type="tel"
        maxlength="4"
        placeholder="1234"
        v-model="value"
      />
      <a href="javascript:void(0)" class="clear" @click="onClear"></a>
    </div>
    <!-- 키오스크 번호 -->
    <NumPad
      class="kiosk-num guide"
      @keyBtn="keyBtn"
      @clearBtn="clearBtn"
      @removeBtn="removeBtn"
    />
  </div>
</template>

<script>
import NumPad from "@/components/v2/common/NumPad";

export default {
  name: "KioskInputCode",
  components: {
    NumPad,
  },
  props: {
    value: String,
  },
  methods: {
    codeValidation(str) {
      // 자리수 제한 (4)
      this.$refs.kioskInput.focus();
      return str.slice(0, 4);
    },
    keyBtn(val) {
      this.$emit("input", this.codeValidation(this.value + val));
    },
    clearBtn() {
      this.$emit("input", "");
    },
    removeBtn() {
      this.$emit("input", this.codeValidation(this.value.slice(0, -1)));
    },
    onClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped>
.code-container {
  background: var(--background-green);
  height: 940px;
  padding-top: 40px;
  border-radius: 10px;
}
.kiosk01 .kiosk-input::before {
  left: 36%;
  width: 27%;
  border-radius: 0;
  background-color: #509d9c;
  background: linear-gradient(90deg, #509d9c 78%, #00000000 0);
  background-size: 49.5px 100%;
}
.kiosk01 .kiosk-input input {
  letter-spacing: 12px;
  margin-top: 40px;
  font-family: "Roboto";
  padding-left: 36.5%;
  text-align: left;
  caret-color: #509d9c;
}
.kiosk01 .kiosk-input input::placeholder {
  letter-spacing: 12px;
  font-size: 62px;
}
  .kiosk01 .kiosk-input .clear {
    margin: 40px 20px 0 0;
  }
</style>