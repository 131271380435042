<template>
  <div class='ptnok-input-container'>
    <!-- 키오스크 input -->
    <div class="kiosk-input ptnok-kiosk-input">
      <label for="" class="hide"></label>
      <input
        ref="ptnokKioskInput"
        type="tel"
        maxlength="11"
        placeholder="12345678"
        v-model="value"
      />
      <a href="javascript:void(0)" class="clear" @click="clearBtn"></a>
    </div>
    <!-- 키오스크 번호 -->
    <NumPad class="num-pad" @keyBtn="keyBtn" @clearBtn="clearBtn" @removeBtn="removeBtn" />
  </div>
</template>

<script>
import NumPad from "@/components/v2/common/NumPad";
export default {
  name: "KioskInputPatientId",
  components: {
    NumPad,
  },
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    patientValidation(str) {
      // 자리수 제한 (8)
      this.$refs.ptnokKioskInput.focus();
      return str.slice(0, 8);
    },
    keyBtn(val) {
      this.$emit("input", this.patientValidation(this.value + val));
    },
    clearBtn() {
      this.$emit("input", "");
    },
    removeBtn() {
      this.$emit("input", this.patientValidation(this.value.slice(0, -1)));
    },
  },
};
</script>

<style scoped>

.kiosk01 .kiosk-input {
  margin-top: 0;
  margin-bottom: 50px;
  position: relative;
}
.kiosk01 .kiosk-input::before {
  content: "";
  height: 6px;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  border-radius: 6.5px;
  background-color: #509d9c;
}

.kiosk01 .kiosk-input input {
  width: 100%;
  /* padding:0 100px; */
  font-size: 62px;
  font-family: "NEXONLv1GothicB";
  font-weight: 700;
  color: #111;
  text-align: center;
}

.kiosk01 .kiosk-input input::placeholder {
  color: #111;
}

.kiosk01 .kiosk-input .clear {
  display: block;
  width: 53px;
  height: 53px;
  position: absolute;
  top: 8px;
  right: 8px;
  background: 0 0 no-repeat url(../../../assets/img/clear.jpg);
}

/* ------------------------------------- */
.ptnok-input-container{
  height: 750px;
  background: var(--background-orange);
  margin: -24px -40px 10px -40px;
  padding: 10px 0 0 0;
  width: 112%;
  background-clip: content-box;
  border-radius: 0 0 20px 20px;
}
.ptnok-kiosk-input{
  margin-top:10px
}

.kiosk01 .ptnok-kiosk-input::before {
  content: "";
  font-size: 62px;
  height: 6px;
  position: absolute;
  bottom: -10px;
  left: 25%;
  width: 50%;
  border-radius: 0;
  background-color: #509d9c;
  background: linear-gradient(90deg, #509d9c 75%, #00000000 0);
  background-size: 50px 100%;
  border-radius: 0;
}
.kiosk01 .ptnok-kiosk-input input {
  letter-spacing:12px;
  width: 100%;
  font-size: 66px;
  font-weight: 700;
  color: #111;
  text-align: left;
  margin-top: 40px;
  font-family: 'Roboto';
  padding-left: 25%;
  caret-color: #509d9c;
}
.kiosk01 .ptnok-kiosk-input input::placeholder {
  color: #111;
  letter-spacing:12px;
  font-size: 66px;
}
.kiosk01 .ptnok-kiosk-input .clear {
  margin: 40px 40px 0 0;
}
.num-pad  {
  margin-top: 70px;
}
</style>