<template>
  <div>
    <!-- 발급 완료 -->
    <section class="kiosk kiosk03" style="display: block">
      <!-- 상단 버튼 -->
      <Header ref="header" :activeBackBtn="false" />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1 v-html="$t(errMassage)"></h1>
        <h2
          v-html="$t(subErrMassage)"
        ></h2>
        <p class="first"
          v-html="
            $t('10초_후에_처음화면으로_되돌아갑니다', { '0': `${countDown}` })
          "
        ></p>
      </div>
      <div class="btn-area footer" v-if="getInquiryYn">
        <a
          href="javascript:void(0)"
          class="btn-default btn-white"
          :class="{ disabled: !retryReady }"
          @click="openPopup"
          >{{ $t("문진_재작성") }}</a
        >
      </div>
    </section>
    <CheckReInquiryPopup
      v-if="retryInquiryPopup"
      @closePopup="closePopup"
      @okBtn="okBtn"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../../components/v2/Header.vue";
import CheckReInquiryPopup from "../../components/v2/modal/CheckReInquiryPopup.vue";
export default {
  name: "Forbiden",
  components: {
    Header,
    CheckReInquiryPopup,
  },
  props: {
    passCheck: String,
    refId: String,
    inqCheckErrorMsg: String
  },
  data() {
    return {
      countDown: 30,
      answerType: null,
      identityNo: null,
      phoneNum: null,
      pubPassTime: null,
      btnLock: false,
      setIntervalId: null,
      retryInquiryPopup: false,
      errMassage: '귀하의_출입증이_발급되지_않았습니다',
      subErrMassage: '병동_및_진료_구역_출입이_꼭_필요하신_경우_안내데스크로_문의해_주시기_바랍니다',
    };
  },
  mounted() {
    this.setIntervalId = setInterval(() => {
      if (this.countDown > 0) {
        !this.retryInquiryPopup && this.countDown--;
      } else {
        this.$refs.header.goHome();
      }
    }, 1000);
    this.getDetailText();
  },
  beforeDestroy() {
    clearInterval(this.setIntervalId);
  },
  computed: {
    ...mapGetters(['getInquiryYn']),
    retryReady() {
      return !this.btnLock && this.passCheck;
    },
  },
  methods: {
    getDetailText(){
      if(this.inqCheckErrorMsg){
        this.errMassage = this.inqCheckErrorMsg;
        this.subErrMassage = '문진을_다시_시도해_주세요';
      }
    },
    onClick() {
      this.$refs.header.goHome();
    },
    async retryInquiry() {
      if (!this.retryReady) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/re-inquiry", {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error("error");

        switch (response.data.resultCd) {
          case 305:
            // 만료된 문진 데이터
            throw new Error("만료된 문진 데이터 오류");
          case 309:
            this.$modal.info(
              this.$t("알림"),
              this.$t(
                "원내_출입_중에는_재문진이_불가능합니다_원외로_이동_후_재문진을_시작해_주세요"
              ).replace(/<br \/>/g, "")
            );
            break;
          case 0:
            // 문진작성으로 이동
            this.$router.push({
              name: "infoAgree",
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: response.data.result.privacyCode,
                sensitiveCode: response.data.result.sensitiveCode,
                refId: this.refId,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    openPopup() {
      this.retryInquiryPopup = true;
    },
    closePopup() {
      this.retryInquiryPopup = false;
      this.countDown = 30;
    },
    okBtn() {
      this.retryInquiry();
    },
  },
};
</script>

<style scoped>
/* ----- 출입 등록3 / 발급 완료 ----- */
.kiosk03 .btn-top-area {
  justify-content: flex-end;
}

.kiosk03 .kiost-top {
  padding-top: 190px;
  text-align: center;
}

.kiosk03 .kiost-top h1 {
  font-size: 100px;
  line-height: 1.3;
}

.kiosk03 .kiost-top h2 {
  font-size: 50px;
}

.kiosk03 .kiosk-area {
  width: 630px;
  margin: 90px auto 0;
  padding: 90px 0;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  border-radius: 25px;
}

.kiosk03 .kiosk-end {
  padding-top: 145px;
  text-align: center;
}
.kiosk03 .kiosk-end img {
  margin-bottom: 50px;
}
.kiosk03 .kiosk-end p {
  font-size: 40px;
  line-height: 1.4;
}

.kiost-staff {
  padding: 300px 0;
  font-family: "NEXONLv1GothicB";
  font-size: 70px;
  line-height: 1.4;
  color: #004c4e;
  text-align: center;
}

.kiosk03 .notice {
  padding-top: 75px;
  font-size: 40px;
  text-align: center;
  color: #006769;
}

.kiosk03 .btn-area {
  display: flex;
  margin-top: 60px;
}
.kiosk03 .btn-area a {
  font-size: 46px;
  flex: 1;
  margin: 0 10px;
}
.kiosk03 .btn-area.type1 {
  width: 100%;
}
.kiosk03 .btn-area .btn-blue {
  height: 300px;
}
.kiosk03 .btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.kiosk03 .btn-area .btn_print_p {
  height: 230px;
  background: url(../../assets/img/ico_print_p.png) no-repeat center 20%;
  background-size: 70%;
  display: block;
  border: 3px solid #006769;
  display: flex;
  padding-top: 200px;
  align-items: center;
  justify-content: center;
  height: 420px;
  border-radius: 60px;
  background-color: #fff;
  color: #006769;
}

.kiosk03 .btn-area .btn_print_m {
  height: 230px;
  background: url(../../assets/img/ico_print_m.png) no-repeat center 20%;
  background-size: 70%;
  display: block;
  padding-top: 300px;
  align-items: center;
  justify-content: center;
  height: 420px;
  border-radius: 60px;
  background-color: #004c4e;
  color: #fff;
  font-family: "NEXONLv1GothicB";
  text-align: center;
}

.kiosk03 .btn-area .btn_print_m .small {
  padding-top: 10px;
  font-size: 32px;
  clear: both;
  display: block;
}

.btn-blue {
  border: 3px solid #00a2ff;
  background-color: #fff;
  color: #00a2ff;
}

.pass_card {
  margin: 0 auto 150px auto;
  padding: 100px 60px;
  width: 80%;
  border-radius: 20px;
  background: #ffffff;
}
.pass_card h3 {
  font-size: 70px;
  color: #004c4e;
  font-family: "NEXONLv1GothicB";
  margin-bottom: 50px;
}
.pass_card .h_num {
  margin-bottom: 30px;
}
</style>