
<template>

<!-- 생년월일 6자리 입력했을 경우 팝업 -->

  <!-- 본인확인 -->
  <div class="modal" style="display: block">
    <div class="modal-pop">
      <div class="modal-header">
        <h3 v-if="userList.length === 1">
          {{ isPT ? $t("본인_확인") : $t("환자_확인") }}
        </h3>
        {{ userList.length > 1 ? $t("이름을_선택해주세요") : "" }}
        <p v-if="userList.length === 1">
          {{
            isPT
              ? $t("본인이_맞는지_확인해주세요")
              : $t("환자_정보를_확인해주세요")
          }}
        </p>
      </div>
      <div class="modal-body">
        <div class="list_name">
          <dl v-for="(item, index) in userList" :key="item.patNum">
            <dt v-if="userList.length === 1">{{ maskingName(item.ptName) }}</dt>
            <dt v-else @click="selectItem($event, item)">
              <input
                type="radio"
                :id="`pp_${index}`"
                name="names"
                @change="selectItem($event, item)"
                :checked="selectedPatNum === item.patNum"
              />
              <label :for="`pp_${index}`"
                ><span>{{ maskingName(item.ptName) }}</span></label
              >
            </dt>
            <dd>
              {{ $t("생년월일_", { "0": `${maskingBirth(item.birth)}` }) }}
              <br />
              {{
                $t("병원등록번호_", {
                  "0": `${maskingPatientId(item.patNum)}`,
                })
              }}
            </dd>
          </dl>
        </div>

        <!-- 버튼 -->
        <div class="btn-area type1">
          <a href="javascript:void(0)" class="btn-default" @click="next">{{
            $t("다음")
          }}</a>
          <a
            href="javascript:void(0)"
            class="btn-default btn-white"
            @click="closePopup"
            >{{ $t("다시_입력") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SelectPatientPopup",
  props: {
    userList: Array,
    byPid: Boolean, // true면 환자번호로 검색한 결과, false면 생년월일로 검색한 결과 (마스킹이 달라짐)
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "isRTL"]),
    isPT() {
      return (
        this.getUserType === this.USER.PT || this.getUserType === this.USER.EMMA
      );
    },
  },
  data: () => {
    return { selectedPatNum: null };
  },
  mounted() {
    this.selectedPatNum = this.userList[0]?.patNum;
  },
  methods: {
    maskingPatientId(str) {
      if (!this.byPid) {
        return this.isRTL ? `${str.slice(4)}****` : `****${str.slice(4)}`; // 처음 4자리 *
      }
      return str;
    },
    maskingName(str) {
      if (this.byPid || (!this.byPid && this.userList.length < 2)) {
        return this.isRTL ? `*${str.slice(0, -1)}` : `${str.slice(0, -1)}*`; // 마지막한자리 *
      }
      return str;
    },
    maskingBirth(str) {
      if (!str) return "";
      let tempStr = "19".concat(str); // 생년월일을 6자리로 format하기 위해 임의의 값 추가
      return moment(tempStr).format("YY-MM-DD");
    },
    next: function () {
      this.$emit("okBtn", this.selectedPatNum);
    },
    closePopup: function () {
      this.$emit("closePopup");
    },
    selectItem(_e, item) {
      this.selectedPatNum = item.patNum;
    },
  },
};
</script>

<style scoped>
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(246, 246, 246, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header h3 {
  margin-bottom: 20px;
  font-family: "NEXONLv1GothicB";
  color: #004c4e;
}
.modal-header p {
  font-size: 32px;
}
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop .title {
  margin-top: 30px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #333333;
}
.modal-prop .title.active {
  margin-top: 20px;
  padding: 40px;
  font-size: 40px;
  text-align: left;
  border-radius: 10px;
  color: #004c4e;
  background: #f6f6f6;
}
.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}

.list_name {
  text-align: left;
}
.list_name dl {
  padding: 60px 0;
  border-bottom: 1px solid #cccccc;
}
.list_name dt {
  font-size: 36px;
}
.list_name dd {
  padding: 10px 0 0 60px;
  font-size: 36px;
  line-height: 1.4;
}
.list_name input[type="radio"] {
  display: none;
}
.list_name input[type="radio"] + label {
  background: url(../../../assets/img/ico_radio.png) no-repeat 0px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.list_name input[type="radio"]:checked + label {
  background: url(../../../assets/img/ico_radiochecked.png) no-repeat 0px;
  display: inline-block;
}
.list_name input[type="radio"] + label span {
  padding: 0 0 0 60px;
  width: 100%;
  line-height: 1.4;
  font-size: 36px;
  display: inline-block;
}
</style>