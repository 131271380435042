var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"kiosk kiosk03",staticStyle:{"display":"block"}},[_c('Header',{ref:"header",attrs:{"activeBackBtn":false}}),_c('div',{staticClass:"kiost-top"},[_c('h1',[_vm._v(_vm._s(_vm.getUserType && `${_vm.getUserTypeText}`))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.$t("문진확인증이_발급되었습니다"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("직원에게_문진확인증을_제출해주세요"))+" ")])]),(_vm.getUserType === _vm.USER.EMMA)?_c('div',{staticClass:"btn-area"},[_c('a',{staticClass:"btn-default",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.goPhone}},[_vm._v(_vm._s(_vm.$t("동반보호자_문진시행"))+" ")])]):_vm._e(),_c('div',{staticClass:"kiosk-end"},[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          '안전한_병원_생활을_위해_병동_및_진료_' +
            '구역_내_1인_이상의_동반_보호자_출입을_제한하고_있습니다'
        )
      )}}),_c('p',{staticStyle:{"margin-top":"60px"},domProps:{"innerHTML":_vm._s(
        _vm.$t('10초_후에_처음화면으로_되돌아갑니다', { '0': `${_vm.countDown}` })
      )}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('감사합니다'))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }