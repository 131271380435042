<template>
  <div>
    <router-view class="info-area" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>