<template>
  <div class="kiosk-area">
    <div class="scroll" style="max-height: 1060px">
      <!-- 증상 -->
      <div class="kiosk-txt">
        <ul>
          <li
            v-for="(item, index) in contents.inquiryList"
            :key="item.inquiryId"
            @click="onClick($event, index)"
          >
            {{ item.langContents || item.inquiryContents }}
            <div
              :class="[
                selectedInqId !== item.inquiryId ? 'radio_img' : 'radiochk_img',
              ]"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiChoice",
  props: {
    contents: Object,
    isViewing: Boolean,
    isFirstInquiry: Boolean,
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    selectedInqId() {
      return this.selectedItem?.inquiryId;
    },
    targetCategoryIdInfo() {
      if (this.selectedItem === null) return null;
      return this.selectedItem.targetCategoryId;
    },
  },
  mounted() {
    this.isViewing && this.updateBtnText(); // 첫번째 컨텐츠인 경우 mounted 와 동시에 버튼정보 emit
    this.isViewing && this.isFirstInquiry && this.$emit("oneButton", true);
    this.isViewing && this.$emit("btnDisable", true);
  },
  beforeDestroy() {
    // show : false 로 변경 시 연계된 질문 show 정보 초기화
    for (let i = 0; i < this.contents.inquiryList.length; i++) {
      const item = this.contents.inquiryList[i];
      if (item.targetCategoryId !== null) {
        this.$emit("setShow", {
          targetCategoryId: item.targetCategoryId,
          isShow: false,
        });
      }
    }
  },
  methods: {
    onClick(_e, index) {
      this.selectedItem = this.contents.inquiryList[index];
    },
    // ================================= common =================================
    leftBtnAction() {
      this.$emit("goBack");
    },
    rightBtnAction() {
      this.emitGoNext();
    },
    updateBtnText() {
      this.emitLeftBtnText();
      this.emitRightBtnText();
    },
    emitLeftBtnText() {
      this.$emit("leftBtnText", this.$t("취소"));
    },
    emitRightBtnText() {
      this.$emit("rightBtnText", this.$t("제출"));
    },
    emitGoNext() {
      this.$emit("goNext");
      this.$emit("oneButton", false);
    },
  },
  watch: {
    isViewing: function (newV) {
      if (newV === true) {
        this.isFirstInquiry && this.$emit("oneButton", true);
        this.$emit("btnDisable", this.selectedItem === null);
        this.updateBtnText();
      }
    },
    selectedItem: function (newV) {
      if (newV) {
        this.$emit("btnDisable", false);
        this.contents.answerId = newV.inquiryMappingId;
      }
    },
    targetCategoryIdInfo: function (newV, oldV) {
      //show
      this.$emit("setShow", {
        targetCategoryId: newV,
        isShow: true,
      });
      // hide
      this.$emit("setShow", {
        targetCategoryId: oldV,
        isShow: false,
      });
    },
  },
};
</script>

<style scoped>
.kiosk-area {
  margin: 90px 0;
  height: auto;
  position: relative;
  bottom: 0;
} /* 별도 style */
/*라디오버튼 숨김*/
.num_box.patient {
  margin: -20px -40px 50px -40px;
  padding: 14px;
  background: #f6f6f6;
  border-radius: 60px;
  border-bottom: none !important;
}
.num_box.patient .patient_cont {
  display: none;
}
.num_box.patient input[type="radio"] {
  display: none;
}
.num_box.patient input[type="num"] {
  margin-top: 60px;
  border-bottom: 6px solid #cccccc !important;
}

.num_box.patient input[type="num"]:focus {
  color: #333333;
  border-bottom: 6px solid #509d9c !important;
}
.num_box.patient label {
  display: inline-block;
  margin: 0;
  padding: 0 40px;
  width: auto;
  height: 90px;
  line-height: 90px;
  font-weight: 600;
  text-align: center;
  color: #999;
  font-size: 36px;
}

.num_box.patient label:hover {
  color: #2e9cdf;
  cursor: pointer;
}

/*input 클릭시, label 스타일*/
.num_box.patient input:checked + label {
  color: #fff;
  background: #f68b1f;
  border-radius: 50px;
}

#num_id:checked ~ #num_id_cont,
#num_pati:checked ~ #num_pati_cont {
  display: block;
}

.btn-area.footer {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.kiosk-txt {
  padding: 0px;
}
.kiosk-txt .txt_c {
  text-align: center;
}
.kiosk-txt ul {
  padding-top: 0px;
}

.kiosk-txt ul li {
  padding-left: 25px;
  padding: 30px 100px 30px 65px;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: -1px;
  position: relative;
  border-bottom: 1px solid #cccccc;
}
.kiosk-txt ul li:last-child {
  border-bottom: none;
}
.kiosk-txt.checkbox_check ul li {
  padding: 0;
}

.kiosk-txt h4 {
  margin-top: 40px;
  font-size: 36px;
  padding: 30px 60px;
  background: #eaeaea;
  font-weight: bold;
}
.kiosk-txt .small {
  font-size: 28px !important;
  line-height: 1.2;
}

.kiosk-txt ul li p {
  padding-bottom: 50px;
  font-size: 30px;
}

.kiosk-txt .img_symptoms dl {
  float: left;
  padding: 35px 0px 35px 100px;
  width: 50%;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.kiosk-txt .img_symptoms dt {
  font-size: 36px;
  padding-bottom: 20px;
  text-align: left;
}
.kiosk-txt .img_symptoms img {
  width: 71%;
}

.check_no .checkbox_img {
  display: none;
}
.check_yes .checkbox_img {
  display: block;
}
.check_yes .checkbox_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}
.check_yes .checked {
  background: #eafffb;
}
.check_yes .checked .checkbox_img,
.check_yes .img_symptoms .checked .checkbox_img {
  background: url("../../../assets/img/ico_checked.png");
}
.check_yes .img_symptoms .checkbox_img {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_uncheck.png");
}

.radio_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top:-20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radio.png");

}
.radiochk_img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top:-20px;
  width: 40px;
  height: 40px;
  background: url("../../../assets/img/ico_radiochecked.png");
}

.noti_txt {
  margin-bottom: 80px;
  font-size: 40px;
  line-height: 1.4;
}
.noti_txt h3 {
  padding-bottom: 30px;
  font-size: 50px;
}



/* ----- modal ----- */
.modal_height80 {
  width: 100%;
  height: 72%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.modal-pop {
  position: absolute;
  width: 850px;
  padding: 86px 90px 100px;
  border-radius: 38px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-body .txt {
  padding: 50px 0 80px;
  font-size: 36px;
  letter-spacing: -1px;
  color: #666;
}

.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  padding-bottom: 40px;
  font-size: 46px;
  text-align: left;
}
.modal-header .txt_addr {
  margin-top: 30px;
  font-size: 36px;
}
/* .modal-prop {
    /* margin:0 -90px 60px -90px; 
} */
.modal-prop .checkbox_check input[type="checkbox"] + label {
  padding: 25px 20px !important;
}

.modal-prop ul {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border: 1px solid #eee;
}
.modal-prop ul span {
  font-size: 36px;
}
.modal-prop {
  margin-bottom: 50px;
}
.modal-prop li {
  padding: 30px 40px;
  font-size: 36px;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
}
.modal-prop .visit_list li.active {
  border-bottom: 1px solid #ffffff;
  color: #004c4e;
  font-weight: bold;
}
.modal-prop li.active a {
  color: #004c4e;
  font-weight: bold;
}
.modal-prop .visit_list {
  background: #f6f6f6;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
}

.modal-body .btn-area.type1 {
  margin: 0 -40px -40px -40px;
}
</style>