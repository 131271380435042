<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01">
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("출입증발급") }}</h1>
        <h2
          v-if="getUserType === USER.NOK || getUserType === USER.ENOK"
          v-html="
            $t('환자_출입증_진료_카드_진료영수증의_바코드를_화면_아래에_대주세요')
          "
        ></h2>
        <h2
          v-else
          v-html="$t('환자_출입증_진료_카드_진료영수증의_바코드를_화면_아래에_대주세요')"
        ></h2>
      </div>
      <video v-if="video" id="vdoPlayer" :class="{'small-video': localCheck()}" 
        poster="../../assets/img/video_poster.png" style="margin: auto;" loop autoplay muted>
        <source src="../../assets/video/video.webm" type="video/webm">
      </video>
      <img v-else
        src="../../assets/img/video_poster.png"
        alt="동영상을 찾지 못했습니다">
      <div class="kiosk-area">
        <div class="tab_area" >
          <div class="item pid-tap" :class="{ active: isPidTap }" @click="isPidTap = true">
            {{ $t("병원등록번호") }}
          </div>
          <div @click="isPidTap = false"
            class="item num-tap"
            :class="{ active: !isPidTap }"
            v-html="$t('환자의_휴대전화번호_생년월일_병원등록번호찾기')"
          ></div>
        </div>
        <KioskInputPatientId v-if="isPidTap" v-model="patientId" />
        <KioskInputPhoneBirth v-else v-model="phoneBirth" />
        <!-- <Terms
          v-if="getUserType !== USER.NOK && getUserType !== USER.ENOK"
          ref="terms"
          v-model="isTermChecked"
        /> -->
        <div class="btn-privacy" />
        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goSelectPopup"
        >
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
    <SelectPatientPopup
      v-if="selectStatus && isPidTap"
      :userList="userList"
      :byPid="isPidTap"
      @okBtn="goNext"
      @closePopup="closeSelectPop"
    />
    <SelectPatientPopup2
      v-else-if="selectStatus && !isPidTap"
      :userList="userList"
      :byPid="isPidTap"
      @okBtn="goNext"
      @closePopup="closeSelectPop"
    />
    <InsertErrorPopup v-if="errPopView" @closePopup="closeErrorPop" />
  </div>
</template>

<script>
import Header from "../../components/v2/Header";
import KioskInputPatientId from "../../components/v2/insertId/KioskInputPatientId.vue";
import KioskInputPhoneBirth from "../../components/v2/insertId/KioskInputPhoneBirth.vue";
// import Terms from "../../components/v2/insertId/Terms.vue";
import InsertErrorPopup from "../../components/v2/modal/InsertErrorPopup.vue";
import SelectPatientPopup from "../../components/v2/modal/SelectPatientPopup.vue";
import SelectPatientPopup2 from "../../components/v2/modal/SelectPatientPopup2.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Patient",
  components: {
    Header,
    KioskInputPatientId,
    KioskInputPhoneBirth,
    // Terms,
    InsertErrorPopup,
    SelectPatientPopup,
    SelectPatientPopup2
  },
  props: {
    barcodeNo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isPidTap: true,
      patientId: "",
      phoneBirth: {
        phoneNum: "",
        birthday: "",
      },
      isTermChecked: true,
      btnLock: false,
      errPopView: false, // 에러화면 노출 여부
      selectStatus: false, // 본인 확인 팝업 노출 여부
      userList: [], // 본인 확인 팝업 내 사용자 리스트
      patientInfo: null, // pid 정보 조회 결과 저장용
      video: true, // 비디오 정상 여부 확인용
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getKioskId", 'getLanguage']),
    canNext: function () {
      if (this.isPidTap) {
        // 병원등록번호로 인증
        return (
          // 병원등록번호 정상 여부 확인
          this.patientId !== null &&
          this.patientId !== "" &&
          this.patientId.length > 7 &&
          // 약관 동의 여부확인
          (this.getUserType === this.USER.NOK ||
            this.getUserType === this.USER.ENOK ||
            this.isTermChecked)
        );
      } else {
        // 생년월일로 인증
        return (
          // 핸드폰 번호 입력값 확인
          this.phoneBirth.phoneNum !== null &&
          this.phoneBirth.phoneNum !== "" &&
          this.phoneBirth.phoneNum.length > 9 &&
          /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
            this.phoneBirth.phoneNum
          ) &&
          // 생년월일 입력값 확인
          this.phoneBirth.birthday !== null &&
          this.phoneBirth.birthday !== "" &&
          this.phoneBirth.birthday.length === 6 &&
          // 약관 동의 여부 확인
          (this.getUserType === this.USER.NOK ||
            this.getUserType === this.USER.ENOK ||
            this.isTermChecked)
        );
      }
    },
  },
  mounted() {
    if (this.barcodeNo !== null) this.patientId = this.barcodeNo;
    // socket 리스너 등록
    this.$socket.connect(this.getKioskId);
    this.$socket.on("barcode", (barcodeNo) => (this.patientId = barcodeNo));

    let videoElem = document.getElementById("vdoPlayer");
    try {
      videoElem.play();
    } catch (err) {
      console.log(err);
      this.video = false;
    }
  },
  beforeDestroy() {
    this.$socket.off("barcode");
    this.$socket.disconnect();
  },
  methods: {
    ...mapActions(["InquiryUseCheck"]),
    switchTap() {
      this.isPidTap = !this.isPidTap;
    },
    goSelectPopup() {
      if (!this.canNext || this.btnLock) return;
      // if (this.isPidTap) this.apiIdentityPatient();
      if (this.isPidTap) this.apiCheckPatient();
      else this.apiIdentityPhonebirth();
    },
    // 환자 번호가 존재하는지 체크 후 팝업 노출 Method
    async apiCheckPatient() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/check-patient",
          {
            identityNo: this.patientId,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 300:
            // 존재하지 않는 PatientId
            this.$modal.error(
              `${this.$t(
                "병원등록번호가_확인되지_않습니다"
              )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
            );
            break;
          case 0:
            // 정상 조회 완료 (팝업노출)
            this.userList = [
              {
                patNum: response.data.result.patNo,
                ptName: response.data.result.patNm,
                birth: response.data.result.patBirth,
              },
            ];
            this.selectStatus = true;
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async apiIdentityPhonebirth() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          "/api/v1/identity/phone-birth",
          {
            phoneNum: this.phoneBirth.phoneNum,
            birth: this.phoneBirth.birthday,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 0:
          case 303:
            // 0 : 생년월일+전화번호로 조회된 환자 1명
            // 303 : 생년월일로 조회된 환자 여러명
            this.userList = response.data.result.map(
              ({ patNum, ptName, birth }) => ({
                patNum: patNum,
                ptName: ptName,
                birth: birth,
              })
            );
            this.selectStatus = true;
            break;
          case 300:
            // 300 : 생년월일+전화번호로 조회된 환자없음
            this.selectStatus = false;
            this.$modal.error(
              `${this.$t(
                "병원등록번호가_확인되지_않습니다"
              )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
            );
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async goNext(patientId) {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        let response = {};
        if (this.getUserType == this.USER.ICUGST ) {
          response = await this.$axios.post("/api/v1/identity/check-icu-patient" , {
            refId: patientId,
            answerType: this.getUserType,
            visitDate: moment().format("YYYYMMDD"),
          });
        } else {
          response =  await this.$axios.post("/api/v3/identity/patient", {
            identityNo: patientId,
            answerType: this.getUserType,
            visitDate: moment().format("YYYYMMDD"),
          });
        }


        // if (!response.data.success) throw new Error("error");

        switch (this.getUserType) {
          case this.USER.PT:
          case this.USER.EMMA:
            this.goInquiry(response);
            break;
          case this.USER.NOK:
          case this.USER.ENOK:
          case this.USER.ICUGST:
            this.goPhone(response, patientId);
            break;
          default:
            throw new Error("정의되지 않은 UserType 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async goInquiry(response) {
      // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      // const { sensitiveCheck } = this.$refs.terms.getSensitiveChecked();
      // const sensitiveCheck = true
      switch (response.data.resultCd) {
        case 301:
          // 방문일에 조회된 환자 없음 오류
          this.errPopView = true;
          break;
        case 201:
          // 증상있음 출입 금지.
          this.$router.push({
            name: "forbiden",
            params: { passCheck: response.data.result.passCheck },
          });
          break;
        case 200:
          // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
          this.$router.push({
            name: "passWithPrint",
            params: { passCheck: response.data.result.passCheck },
          });
          break;
        case 0:
            this.$router.push({
              name: "infoAgree", //inquiryView
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: null,
                sensitiveCode: null,
                newPtYn: "N",
                identityBirth: null,
                identityPhone: null,
                refPhone: null,
                refBirth: null,
                refId: null,
              },
            });
          
          break;
        default:
          throw new Error("정의되지 않은 resultCd 오류");
      }
    },
    goPhone(response, patNum) {
      switch (response.data.resultCd) {
        case 301:
          // 방문일에 조회된 환자 없음 오류
          this.errPopView = true;
          break;
        case 307: //허용 가능한 면회자 수를 초과  
          this.selectStatus = false;
          this.$modal.error(this.$t('허용_가능한_면회객_수를_초과하여_출입이_불가합니다'));
          break;
        case 311: //중환자실 재원 정보가 없습니다.
          this.selectStatus = false;
          this.$modal.error(
            `${this.$t(
              "환자_재원정보가_확인되지_않습니다"
            )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
          );
          break;
        

        case 201:
        case 200:
        case 0:
          // 201 - 환자가 증상있음
          // 200 - 환자가 문진 기완료
          this.$router.push({
            name: "phone",
            params: {
              patientId: patNum,
              vistCnt: response.data.result.vistCnt||null,
              visitReason : response.data.result.visitReason||null,
            },
          });
          break;
        default:
          throw new Error("정의되지 않은 resultCd 오류");
      }
    },
    closeErrorPop() {
      this.errPopView = false;
      this.selectStatus = false;
      this.userList = [];
      this.patientId = "";
      this.phoneBirth = {
        phoneNum: "",
        birthday: "",
      };
    },
    closeSelectPop() {
      this.selectStatus = false;
    },
     
    localCheck(){ // 다국어 길이별 CSS 적용을 위한 method
      if( this.getLanguage === "ru"|| this.getLanguage === "vi" || this.getLanguage === "ar" || this.getLanguage === "mn"){
        return true
      } 
      return false
    },
  },
  watch: {
    isPidTap: function () {
      this.patientId = "";
      this.phoneBirth = {
        phoneNum: "",
        birthday: "",
      };
    },
  },
};
</script>

<style scoped>
video {
  width: 90%;
}
img{
  width: 90%;
  margin: 0 5%;
}
.tab_area {
    margin: -20px -40px 0 -40px;
}
.tab_area .item {
  color: rgb(100, 100, 100);
  justify-content: center;
}
.tab_area  .pid-tap{
  background: var(--background-orange);
  border-radius: 20px 20px 0  0 ;
}
.tab_area  .num-tap{
  background: var(--background-green);
  border-radius: 20px 20px 0  0 ;
}
.kiosk-area {
    height: auto;
    background: #00000000;
    box-shadow: none;
    border-radius: 38px;
    overflow:hidden;
    position: static;
    padding-top: 30px;
}
.kiost-top {
  padding-top: 40px;
}
.kiost-top h1 { 
    text-align: left;
    font-size: 70px;
    color: #004c4e;
    font-family: "NEXONLv1GothicB", sans-serif;
    word-break:keep-all;
    line-height: 1.2;
}
.kiost-top h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 1.4;
  word-break:keep-all;
}
.small-video {
  width: 80%;
}
.kiosk h2:lang(en),
.kiosk h2:lang(vi),
.kiosk h2:lang(mn)
{font-size:33px}

</style>