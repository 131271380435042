<template>
  <!-- 발급 완료 -->
  <section class="kiosk kiosk03" style="display: block">
    <!-- 상단 버튼 -->
    <Header ref="header" :activeBackBtn="false" />
    <!-- kiosk header -->
    <div class="kiost-top">
      <h1>{{ getUserType && `${getUserTypeText}` }}</h1>
      <p class="notice">
        {{ $t("문진확인증이_발급되었습니다") }}<br />
        {{ $t("직원에게_문진확인증을_제출해주세요") }}
      </p>
    </div>
    <div v-if="getUserType === USER.EMMA" class="btn-area">
      <a href="javascript:void(0)" class="btn-default" @click="goPhone"
        >{{ $t("동반보호자_문진시행") }}
      </a>
    </div>
    <div class="kiosk-end">
      <p
        v-html="
          $t(
            '안전한_병원_생활을_위해_병동_및_진료_' +
              '구역_내_1인_이상의_동반_보호자_출입을_제한하고_있습니다'
          )
        "
      ></p>
      <p
        style="margin-top: 60px"
        v-html="
          $t('10초_후에_처음화면으로_되돌아갑니다', { '0': `${countDown}` })
        "
      />

      <p v-html="$t('감사합니다')" />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import Header from "../../components/v2/Header.vue";
export default {
  name: "EmmaPass",
  components: {
    Header,
  },
  props: {
    passCheck: String,
  },
  data() {
    return {
      countDown: 10,
      setIntervalId: null,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters([
      "getUserType",
      "getEmmaPatientInfo",
      "getKioskId",
      "isDummyKiosk",
      "getLanguage"
    ]),
    getUserTypeText() {
      switch (this.getUserType) {
        case this.USER.PT:
        case this.USER.EMMA:
          return this.$t("문진확인증for환자");
        case this.USER.NOK:
        case this.USER.ENOK:
          return this.$t("문진확인증for보호자");
        case this.USER.GST:
        case this.USER.TMP:
          return this.$t("문진확인증for방문객");
        default:
          return "";
      }
    },
  },
  async mounted() {
    await this.print(); // 출입증 출력
    this.setIntervalId = setInterval(() => {
      if (this.countDown > 0) {
        this.countDown--;
      } else {
        this.$refs.header.goHome();
      }
    }, 1000);
  },
  beforeDestroy() {
    this.setIntervalId && clearInterval(this.setIntervalId);
  },
  methods: {
    ...mapMutations(["setUserType"]),
    async print() {
      try {
        this.$modal.loading(true);
        const printStatus = await this.checkPrinterStatus();
        if (!this.isDummyKiosk && printStatus) {
          await this.$axios.post("/api/site/v1/print-emma", {
            kioskId: this.getKioskId,
            passCheck: this.passCheck,
            language: this.getLanguage
          });
          // if (!response.data.success) throw new Error("error");
          return true;
        } else if (!this.isDummyKiosk && !printStatus) {
          // dummy 페이지가 아닌데 프린터 불가능한 상태일 경우 홈화면으로 이동
          this.$refs.header.goHome();
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
        return false;
      } finally {
        this.$modal.loading(false);
      }
    },
    async checkPrinterStatus() {
      if (this.isDummyKiosk) return false;
      try {
        const response = await this.$axios.get("/api/printer-state", {
          params: { deviceId: this.getKioskId },
        });
        if (response.data && response.data.printState !== undefined) {
          // -1: 끊김, 0: 정상, 1: 용지걸림, 2: 용지부족, 3: 용지없음
          return (
            response.data.printState === 0 || response.data.printState === 2
          );
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    goPhone() {
      this.setUserType(this.USER.ENOK);
      this.$router.push({
        name: "emmaPhone",
        params: {
          refId: this.getEmmaPatientInfo.refId,
          refBirth: this.getEmmaPatientInfo.refBirth,
          refPhone: this.getEmmaPatientInfo.refPhone,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>