<template>
  <div class="wrap">
    <section class="kiosk kiosk01" style="display: block">
      <Header />
      <!-- kiosk header -->
      <div class="emma_kiosk">
        <h1 v-html="getLanguage=='ko' ? $route.params.title.replaceAll('\n','<br/>') :$route.params.titleEn.replaceAll('\n', '<br/>')">
        </h1>
        <blockquote v-html="getLanguage=='ko' ? $route.params.contents.replaceAll('\n', '<br/>') :$route.params.contentsEn.replaceAll('\n', '<br/>')">
        </blockquote>
        <button  @click="goNext">{{ $t('동의') }}</button>
<!--        <div class="img">-->
<!--          <img src="@/assets/img/emma_img_timer.png"/>-->
<!--        </div>-->
      </div>
    </section>
  </div>

</template>
<script>
  import Header from "../../components/v2/Header";
  import { mapGetters } from "vuex";
  export default {
    name: "emmaAnnounce",
    components: {
      Header
    },
    data() {
      return {
        sendParams: {},
        btnLock: false,
      }
    },
    computed:{
      ...mapGetters(["getLanguage"])
    },
    methods: {
      async goNext() {
        if (this.btnLock) {
          return;
        }
        this.btnLock = true;
        this.sendParams.startTime = new Date()
        const response = await this.$axios.post("/api/site/v1/emma/answer",
            this.sendParams
        ).finally(() => {
          this.btnLock = false;
        });
        this.$router.push({
          name: "emmaPass",
          params: {
            passCheck: response.data.result?.passCheck,
          },
        });
      }
    },
    mounted(){
      this.sendParams = this.$route.params
    }
  }
</script>
<style scoped>
.wrap {
  background-color: #f6f6f6;
}
.kiosk {
  max-width: 870px;
  width: 100%;
  margin: auto;
  padding: 58px 0 55px;
  position: relative;
}
.emma_kiosk{
  margin-top: 108px;
  height:calc(100vh - 58px - 55px - 60px - 108px);
  /* border:1px solid red; */
  //background: center 90% no-repeat;
  //background-image: url('../../assets/img/emma_img_timer.png') ;
  background-size: 60%;
}
.emma_kiosk .img {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 7em;
}
.emma_kiosk .img img {
  width: 60%;
}
.emma_kiosk h1 {
  font-family: 'NEXONLv1GothicB';
  font-size: 90px;
  color: #004c4e;
  line-height: 1.2;
  margin-left: 20px;
}
.emma_kiosk blockquote {
  //height:400px;
  margin-top: 170px;
  margin-bottom: 50px;
  font-size: 60px;
  letter-spacing: -1px;
  line-height: 1.4;
  font-weight: 600;
  margin-left: 20px;
}
.emma_kiosk button{
  border-radius: 60px;
  width:100%;
  height:130px;
  color:#ffffff;
  font-family: 'NEXONLv1GothicR';
  font-size:42px;
  background: #004c4e;
  display:flex;
  justify-content: center;
  align-items: center;
}

</style>