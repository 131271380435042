<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <!-- 상단 버튼 -->
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("출입_등록") }}</h1>
        <h2>{{ $t("사번을_입력해주세요") }}</h2>
      </div>
      <div class="kiosk-area">
        <KioskInputEmployeeId v-model="employeeId" />
        <!-- 수집 동의 -->
        <Terms ref="terms" v-model="isTermChecked" />

        <!-- 키오스크 버튼 영역 -->
        <div
          class="btn-area"
          :class="{ disabled: !canNext || btnLock }"
          @click="goNext"
        >

          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header";
import KioskInputEmployeeId from "../../components/v2/insertId/KioskInputEmployeeId.vue";
import Terms from "../../components/v2/insertId/Terms.vue";
import moment from "moment";

export default {
  name: "Employee",
  components: {
    Header,
    KioskInputEmployeeId,
    Terms,
  },
  data() {
    return {
      employeeId: "",
      isTermChecked: false,
      btnLock: false,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getKioskId", "getInquiryYn"]),
    canNext: function () {
      return (
        // 초대코드 검증
        this.employeeId !== null &&
        this.employeeId !== "" &&
        this.employeeId.length === 7 &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async mounted(){
    await this.inquiryUseCheck();
  },
  methods: {
    async goNext() {
      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post("/api/v1/identity/emp", {
          identityNo: this.employeeId,
          answerType: this.getUserType,
          visitDate: moment().format("YYYYMMDD"),
        });
        // if (!response.data.success) throw new Error("error");
        const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
        switch (response.data.resultCd) {
          case 201:
          case 200:
            // 201-증상있음 출입 금지.
            // 방문일에 해당하는 문진을 이미 완료했을 경우 완료 페이지로 이동 (내부직원은 프린트 불필요)
            this.$router.push({ name: "passWithoutPrint" });
            break;
          case 300:
            // 존재하지 않는 사번 오류
            this.$modal.error(
              this.$t("출입_권한이_없거나_입력하신_사번이_확인되지_않습니다")
            );
            return;
          case 0:
            // 문진작성으로 이동
            this.$router.push({
              name: "infoAgree",
              params: {
                identityNo: response.data.result.identityNo,
                inquiryType: response.data.result.inquiryType,
                passCheck: response.data.result.passCheck,
                answerType: response.data.result.answerType,
                visitDate: response.data.result.visitDate,
                privacyCode: privacyCode,
                sensitiveCode: sensitiveCode,
              },
            });
            break;
          default:
            throw new Error("정의되지 않은 resultCd 오류");
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async inquiryUseCheck(){  // 직원 문진 여부 체크
      if(!this.getInquiryYn){
        this.$modal.error("문진 시행 기간이 아닙니다");
        this.$router.push({
          name: "home",
          params: {
            kioskId: this.getKioskId,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
</style>