<template>
  <div>
    <div class="kiosk-agree-button" @click.self.prevent="checkClick('privacy')" >
      <input v-model="privacyCheck" type="checkbox" id="pp_5" name="pp_5" />
      <label for="pp_5">동의</label>
      <span class="privacy-text" @click.self.prevent="checkClick('privacy')" >
        {{ $t("개인정보_수집_동의") }}<span class="green-text">({{$t("필수")}})</span></span>
  
      <a href="javascript:void(0)"  @click="showModal($t('개인정보_수집_및_이용'), privacy)"
        style="max-width:100px">{{ $t("보기") }}</a>
    </div>
    <div  v-show="getInquiryYn"  class="kiosk-agree-button"  @click.self.prevent="checkClick('sensitive')">
      <input v-model="sensitiveCheck" type="checkbox" id="pp_6" name="pp_6" />
      <label for="pp_6">동의</label>
      <span class="privacy-text"  @click.self.prevent="checkClick('sensitive')">
        {{ $t("민감정보_수집_동의") }}<span class="green-text">({{$t("필수")}})</span></span>

      <a href="javascript:void(0)"  @click="showModal($t('민감정보_수집_및_이용'), sensitive)"
        style="max-width:100px">{{ $t("보기") }}</a>
    </div>

    <TermsPopup v-if="modalFlag" :modalTitle="modalTitle" :modalText="modalText" @closeModal="closeModal" />
  </div>

</template>
<script>
  import {
    mapActions,
    mapGetters,
    mapState
  } from "vuex";
  import TermsPopup from "../../../components/v2/modal/TermsPopup";
  export default {
    name: "AgreeTerms",
    components: {
      TermsPopup
    },
    data() {
      return {
        ready: false,
        privacy: null,
        sensitive: null,
        privacyCode: null,
        sensitiveCode: null,
        modalFlag: false,
        modalTitle: "",
        modalText: "",
        privacyCheck: false,
        sensitiveCheck: false,
      };
    },
    computed: {
      ...mapState(["USER"]),
      ...mapGetters(["getUserType", 'getLanguage', 'getKioskId', 'getValidInquiryCnt','getInquiryYn']),
    },
    mounted() {
      this.getAgreeTerm();
    },
    methods: {
      ...mapActions(["actionGoHome"]),
      async getAgreeTerm() {
        if (!this.getUserType) {
          this.actionGoHome();
          return;
        }
        try {
          this.$modal.loading(true);
          let answerType = this.getUserType;
          if (answerType === this.USER.EMMA) answerType = this.USER.PT; // 응급환자는 환자의 약관을 사용
          if (answerType === this.USER.ENOK) answerType = this.USER.NOK; // 응급보호자는 보호자의 약관을 사용
          // if (answerType === this.USER.RNOK) answerType = this.USER.NOK; // 상주보호자는 보호자의 약관을 사용
          if (answerType === this.USER.TMP) answerType = this.USER.GST; // 기타방문자는는 게스트 약관을 사용
          // if (answerType === this.USER.PEMP) answerType = this.USER.EMP; // 협력업체(사번없는직원)은 내부직원 약관을 사용
          // if (answerType === this.USER.RPT) answerType = this.USER.PT; // 재원환자는 환자의 약관 사용

          const params = {
            answerType,
            language: this.getLanguage,
          };
          const response = await this.$axios.get("/api/v1/term", {
            params
          });
          this.ready = true;
          if ('ko' === this.getLanguage) {
            this.privacy = response.data.result.privacy.privacyContents;
            this.sensitive = response.data.result.sensitive.privacyContents;
          } else {
            this.privacy = response.data.result.privacy.privacyContentsEng;
            this.sensitive = response.data.result.sensitive.privacyContentsEng;
          }
          this.privacyCode = response.data.result.privacy.privacyCode;
          this.sensitiveCode = response.data.result.sensitive.privacyCode;
        } catch (error) {
          this.$modal.error();
          console.log(error);
          this.$router.go(-1);
        } finally {
          this.$modal.loading(false);
        }
      },
      closeModal() {
        this.modalFlag = false;
      },
      showModal(modalTitle, modalText) {
        this.modalTitle = modalTitle;
        this.modalText = modalText;
        this.modalFlag = true;
      },
      getTermsCode() {
        if (this.getInquiryYn) {
          return {
            privacyCode: this.privacyCode,
            sensitiveCode: this.sensitiveCode,
          };
        } else {
          //문진 삭제에 따른 민감정보 수집 및 이용 내역 처리방법 변경
          return {
            privacyCode: this.privacyCode,
            sensitiveCode: null,
          };
        }
      },
      getSensitiveChecked() {
        return {
          sensitiveCheck: this.sensitiveCheck
        };
      },
      checkClick(type){
        if(type=='privacy'){ this.privacyCheck = !this.privacyCheck}
        if(type=='sensitive'){ this.sensitiveCheck = !this.sensitiveCheck}
      },
    },
    watch: {
      getLanguage: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAgreeTerm();
        }
      },
      getUserType: function () {
        this.getAgreeTerm();
      },
      privacyCheck: function (newV) {
        //민감정보 활성화 여부에 따라 값을 다르게 지정.
        if (this.getInquiryYn) {
          this.$emit('input', newV && this.sensitiveCheck);
        } else {
          this.$emit('input', newV);
        }
      },
      sensitiveCheck: function (newV) {
        //민감정보 활성화 여부에 따라 값을 다르게 지정.
        if (this.getInquiryYn) {
          this.$emit('input', this.privacyCheck && newV);
        }
      }
    },
  }
</script>\

<style scoped>
  .kiosk-agree-button{
    background: #fff;
    display: inline-flex;
    padding:40px;
    box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.09);
    border-radius: 38px;
    height: 12vh;
    margin-bottom:40px;
 
    width:100%;
    align-items: center;
    /* justify-content: space-between; */
  }

.kiosk-agree-button input[type="checkbox"] + label {
  min-width: 48px;
  min-height: 48px;
  background: url(../../../assets/img/ico_uncheck.png) no-repeat left;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-indent: -1000px;
  text-overflow: hidden;
}

.kiosk-agree-button input[type="checkbox"]:checked + label {
  background: url(../../../assets/img/ico_checked.png) no-repeat left;
  display: inline-block;
}

.kiosk-agree-button input[type="checkbox"] + label span {
  padding: 0 0 0 60px;
  width: 100%;
  line-height: 1.4;
  font-size: 36px;
  display: inline-block;
}

.kiosk-agree-button .privacy-text {
  font-size: 36px;
  line-height: 100%;
  padding-left:30px;
  cursor: pointer;
}
.kiosk-agree-button a {
  margin-left:auto;
  font-size:24px;
  text-decoration: underline;
  color:#4f4f4f
}
.green-text {
  color:#004c4e
}


</style>