<template>
  <div class="wrap">
    <!-- 출입 등록 -->
    <section class="kiosk kiosk01" style="display: block">
      <!-- 상단 버튼 -->
      <Header />
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1>{{ $t("출입_등록") }}</h1>
        <h2>{{ $t("초대코드_4자리를_입력해주세요") }}</h2>
      </div>
      <div class="kiosk-area">
        <KioskInputCode v-model="codeNum" />
        <!-- 키오스크 버튼 영역 -->
        <div class="btn-area" :class="{ disabled: !canNext }" @click="goNext">
          <!-- 버튼 -->
          <a href="javascript:void(0)" class="btn-default">{{ $t("다음") }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Header from "../../components/v2/Header";
import KioskInputCode from "../../components/v2/insertId/KioskInputCode.vue";
import moment from "moment";

export default {
  name: "Code",
  components: {
    Header,
    KioskInputCode,
  },
  props: {
    meetingItem: Object,
    privacyCode: String,
    sensitiveCode: String,
    phoneNum: String,
  },
  data() {
    return {
      codeNum: "",
      isInvited: false,
    };
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getUserType", "getKioskId", 'getInquiryYn', 'getLanguage', 'getKioskType']),

    canNext: function () {
      return (
        // 초대코드 검증
        this.codeNum !== null &&
        this.codeNum !== "" &&
        this.codeNum.length === 4
      );
    },
  },
  methods: {
    async goNext() {
      if (!this.canNext) return;
      // this.meetingItem.meetingId
      await this.checkInviteCode();
      if (this.isInvited) {
        try {
          this.$modal.loading(true);
          const response = await this.$axios.post(
            "/api/v1/identity/group-open",
            {
              answerType: this.getUserType,
              identityNo: this.phoneNum,
              meetingId: this.meetingItem.meetingId,
              visitDate: moment().format("YYYYMMDD"),
            }
          );
          // if (!response.data.success) throw new Error("error");

          switch (response.data.resultCd) {
            case 201:
              // 증상있음 출입 금지.
              this.$router.replace({
                name: "forbiden",
                params: { passCheck: response.data.result.passCheck },
              });
              break;
            case 200:
              // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
              this.$router.replace({
                name: "passWithPrint",
                params: { passCheck: response.data.result.passCheck },
              });
              break;
            case 0:
              if(this.getInquiryYn){  // 문진 사용중일 경우
                this.useInquiry(response.data.result);
              } else{  // 문진 미사용 경우
                this.noInquiry(response.data.result);
              }
              break;
            default:
              throw new Error("정의되지 않은 resultCd 오류");
          }
        } catch (error) {
          this.$modal.error();
          console.log(error);
        } finally {
          this.$modal.loading(false);
        }
      } else {
        // 코드가 매칭되지 않는 경우
        this.$modal.error(this.$t("초대코드가_일치하지_않습니다"));
      }
    },
    async checkInviteCode(){
      const response = await this.$axios.post(
          '/api/v1/identity/check-code',
          {
            meetingId: this.meetingItem.meetingId,
            inviteCode: this.codeNum
          }
      );
      if(response.data.result) {
        this.isInvited = true;
      }
    },
    useInquiry(res){  // 문진 사용
      // 문진작성으로 이동
      this.$router.replace({
        name: "infoAgree",
        params: {
          identityNo: res.identityNo,
          inquiryType: res.inquiryType,
          passCheck: res.passCheck,
          answerType: res.answerType,
          visitDate: res.visitDate,
          privacyCode: null,
          sensitiveCode: null,
        },
      });
    },
    async noInquiry(res){  // 문진 미사용
      try {
        this.$modal.loading(true);
        
        const response = await this.$axios.post('/api/pre/v1/no-answer', {
          identityNo: res.identityNo,
          deviceType: 'K',
          deviceId: this.getKioskId,
          answerType: res.answerType,
          visitDate: res.visitDate,
          completePlace: this.getLanguage === 'ko' || this.getLanguage === 'en',
          inqType: res.inquiryType,
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
          partnerEmpId: null,
          pid: null,
          kioskType: this.getKioskType,
          conn: null,
        });

        switch (response.data.resultCd) {
          case 300:
          case 201:
            this.$router.replace({
              name: 'forbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 0:
            // 출입증 발급
            this.$router.replace({
              name: 'passWithPrint',
              params: { passCheck: response.data.result.passCheck },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
        
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
      }
    },
  },
};
</script>

<style scoped>
.kiosk-area {
    background: none;
    box-shadow: none;
    overflow:hidden;
    position: static;
    padding-top: 120px;
}
</style>