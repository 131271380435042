<template>
  <div :style="{ zoom: zoomSize }" @click="goHomeTimer">
    <router-view></router-view>
    <AlertWithIcon />
    <AlertWithoutIcon />
    <LoadingSpinner />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AlertWithIcon from "./components/v2/commonModal/AlertWithIcon.vue";
import AlertWithoutIcon from "./components/v2/commonModal/AlertWithoutIcon.vue";
import LoadingSpinner from "./components/v2/commonModal/LoadingSpinner.vue";
export default {
  data: () => {
    return {
      routeIntervalIdx: null,
      routeIntervalTimer: 1000 * 60 * 3, //  3분
      // inquiryRouteIntervalTimer: 1000 * 60 * 3, //  3분
    };
  },
  components: {
    AlertWithIcon,
    AlertWithoutIcon,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters([
      "getKioskId",
      "getIsEmmaKiosk",
      "getKioskId",
      "isDummyKiosk",
    ]),
    zoomSize() {
      if (this.getKioskId === "dummy") return "0.5";
      if (this.getKioskId === "preview") return "0.4";
      return "1.0";
    },
  },
  mounted() {
    this.setVisitHomeTimestamp();
  },
  methods: {
    ...mapMutations(["setVisitHomeTimestamp"]),
    goHomeTimer() {
      this.routeIntervalIdx && clearTimeout(this.routeIntervalIdx);
      // dummy, preview 등으로 열린 페이지일때는 동작 안함
      if (this.isDummyKiosk) return;
      if (this.$route.name !== "home" && this.$route.name !== "emmahome") {
        const targetRouterName = this.getIsEmmaKiosk ? "emmahome" : "home";
        this.routeIntervalIdx = setTimeout(() => {
          this.$modal.hide();
          this.$router.push({
            name: targetRouterName,
            params: {
              kioskId: this.getKioskId,
            },
          });
        }, this.routeIntervalTimer);
      }
    },
  },
};
</script>

<style>
@import "./assets/css/reset.css";
@import "./assets/css/style.css";
body,
html {
  background: #f6f6f6;
}
</style>