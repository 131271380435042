<template>
  <!-- 상단 버튼 -->
  <div class="btn-top-area">
    <a
      v-if="activeBackBtn"
      href="javascript:void(0)"
      class="btn-prev"
      @click="goBack"
      ><i><img src="@/assets/img/ico_prev.png" alt="" /></i>
      {{ $t("이전으로") }}</a
    >
    <a href="javascript:void(0)" class="btn-home" @click="goHome"
      ><i><img src="@/assets/img/ico_home.png" alt="" /></i
      >{{ $t("처음으로") }}</a
    >
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    depth: {
      type: Number,
      default: 0,
    },
    activeBackBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["USER"]),
    ...mapGetters(["getKioskId", "getUserType", "getIsEmmaKiosk"]),
  },
  methods: {
    goHome() {
      // window.location.href = `${window.location.origin}/#/kiosk/${this.getKioskId}`;
      if (this.getKioskId === "preview") return;
      if (this.getIsEmmaKiosk) {
        this.$router.push({
          name: "emmahome",
          params: {
            kioskId: this.getKioskId,
          },
        });
      } else {
        this.$router.push({
          name: "home",
          params: {
            kioskId: this.getKioskId,
          },
        });
      }
    },
    goBack() {
      if (this.depth > 0) {
        this.$emit("depthDecrease");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
</style>