<template>
  <div v-if="isShow" class="modal" style="display: block">
    <div class="modal-pop">
      <div v-if="infoTitle.length > 0" class="modal-header">
        <h3 v-html="infoTitle" />
      </div>
      <div class="modal-body">
        <div class="modal_conts" v-if="infoMessage" :class="{wordBreak : isWordBreak}">
          <p v-html="infoMessage"></p>
        </div>
        <!-- 버튼 -->
        <div class="btn-area type1">
          <a href="javascript:void(0);" class="btn-default" @click="close">{{
            $t("확인")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      infoTitle: "",
      infoMessage: "",
      isWordBreak: false,
    };
  },
  methods: {
    close() {
      this.isShow = false;
      this.isWordBreak = false;
    },
    show(infoTitle, infoMessage) {
      this.infoTitle = infoTitle;
      this.infoMessage = infoMessage;
      this.isShow = true;
    },
    showWordBreak(infoTitle, infoMessage, isWordBreak) {
       this.infoTitle = infoTitle;
       this.infoMessage = infoMessage;
       this.isShow = true;
       this.isWordBreak = isWordBreak;
    }
  },
  beforeMount() {
    this.$modal.on("info", ({ title, message }) => this.show(title, message));
    this.$modal.on("infoWordBreak", ({ title, message, isWordBreak}) => this.showWordBreak(title, message, isWordBreak));
    this.$modal.on("hide", this.close);
  },
  beforeDestroy() {
    this.$modal.off("info");
    this.$modal.off("hide");
    this.$modal.off("infoWordBreak");
  },
};
</script>

<style scoped>
.modal-body .btn-area.type2 .btn-white {
  font-family: "NEXONLv1GothicR";
}

.modal-header {
  margin: -20px -20px 0 -20px;
  font-size: 46px;
  text-align: left;
}
.modal_conts {
  padding: 50px 0;
  text-align: left;
  font-size: 36px;
  line-height: 1.6;
}

.modal-header h3 {
  margin-bottom: 20px;
  font-family: "NEXONLv1GothicB";
  color: #004c4e;
}
.modal-header p {
  font-size: 32px;
}
.wordBreak {
  word-break: break-all;
}
</style>
