import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import axios from "axios";
import modal from './lib/modal';
import request from './lib/request';
import webSocket from './lib/webSocket'
import { router } from "./router/index.js";
import i18n from "./i18n";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(modal);
Vue.use(request);
Vue.use(Vuex);
Vue.use(webSocket);

new Vue({
  render: (h) => h(App),
  router,
  i18n,
  store,
}).$mount("#app");
