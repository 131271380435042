<template>
  <div class="wrap">
    <!-- 발급 완료 -->
    <section class="kiosk kiosk03" style="display: block">
      <!-- 상단 버튼 -->
      <div class="btn-top-area">
        <div></div>
        <div></div>
      </div>
      <!-- kiosk header -->
      <div class="kiost-top">
        <h1 v-html="$t('문진표를_재작성하시겠습니까')" />
        <h2
          v-html="
            $t(
              '문진표_재작성을_원하시는_경우_' +
                '아래_재작성_버튼을_눌러주세요_' +
                '저장된_문진_기록을_삭제하고_' +
                '문진표를_새로_작성하게_됩니다'
            )
          "
        />
      </div>
      <div
        class="kiosk_conts_bottom"
        v-html="
          $t(
            '문진표를_허위로_작성할_경우_보건_관련_법률_형법_등에_의해_처벌_' +
              '또는_과태료_1천만원_처분_대상이_될_수_있습니다'
          )
        "
      />
      <div class="btn-area footer">
        <a
          href="javascript:void(0)"
          class="btn-default btn-white"
          @click="closePopup"
          v-html="$t('취소')"
        />
        <a
          href="javascript:void(0)"
          class="btn-default"
          @click="okBtn"
          v-html="$t('재작성')"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CheckReInquiryPopup",
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    okBtn() {
      this.$emit("okBtn");
    },
  },
};
</script>

<style scoped>
.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>