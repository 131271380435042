<template>
  <!-- 키오스크 번호 -->
  <div class="kiosk-num" :class="{ bp_20: phoneStatus, 'kiosk-pt-nok': isVideo() }">
    <ul>
      <li><a href="javascript:void(0)" @click="clickKey">1</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">2</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">3</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">4</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">5</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">6</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">7</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">8</a></li>
      <li><a href="javascript:void(0)" @click="clickKey">9</a></li>
      <li v-if="phoneStatus" class="btn-first">
        <a href="javascript:void(0)" @click="clickKey">010</a>
      </li>
      <li v-else class="btn-none"></li>
      <li><a href="javascript:void(0)" @click="clickKey">0</a></li>
      <li class="btn-clear">
        <a href="javascript:void(0)" @click="removeBtn"
          ><img src="@/assets/img/btn_clear.png" alt=""
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NumpPad",
  props: {
    phoneStatus: {
      //전화번호 입력시에만 보여줄 010 버튼 노출 여부
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickKey(e) {
      this.$emit("keyBtn", e.target.innerText);
    },
    clearBtn: function () {
      this.$emit("clearBtn");
    },
    removeBtn: function () {
      this.$emit("removeBtn");
    },
    onKeyDown: function (e) {
      // esc Btn
      if (e.key === "Escape") {
        this.clearBtn();
        return;
      }
      // Backspace Btn
      if (e.key === "Backspace") {
        this.removeBtn();
        return;
      }
      // const matched = e.key.match(/([A-Za-z0-9])/i);
      const matched = e.key.match(/([0-9])/);
      if (matched && matched.length > 1) {
        this.$emit("keyBtn", e.key);
      }
    },
    isVideo(){
      if(this.$route.name === "patient" || this.$route.name === "guardian" || this.$route.name === "emmaPatient"){
        return true
      }
      return false
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown, false);
  },
};
</script>

<style scoped>

</style>